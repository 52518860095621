import React, {useCallback} from "react";
import {IActivityCard} from "./ActivityCard";
import {PhoneCallData} from "./activity_models";
import {Container, Divider, Link} from "bounce-ui/backoffice";
import {useUser} from "../../../../../../contexts";
import {downloadUserFiles} from "../../../../../../apis/requests/files";
import InputUtils from "../../../../../../helpers/InputUtils";
import {useDeleteFutureCommunication} from "../../../../../../apis/requests/backoffice/deleteFutureCommunication";
import {StyledText} from "./components/StyledText";
import {getPhoneNumber} from "./utils";
import {useAssignAccountId} from "./useAssignAccountId";

export const PhoneCallItem = ({activity, user}: IActivityCard) => {
  const {event_type} = activity as PhoneCallData;
  if (["PHONE_CALL_INCOMING", "PHONE_CALL_DIALED", "SCHEDULED_PHONE_CALL"].includes(event_type)) {
    return <AnsweredPhoneCallItem activity={activity} user={user} />;
  }
  return <UnansweredPhoneCallItem activity={activity} />;
};

const AnsweredPhoneCallItem = ({activity, user}: IActivityCard) => {
  const {
    account_id,
    event_type,
    call_summary,
    call_reason,
    recording_file_id,
    recording_file_name,
    recording_link,
    transcription_file_id,
    to_number,
    from_number,
    direction,
    id,
    interaction_id,
  } = activity as PhoneCallData;
  const {token} = useUser();
  const phone_number = getPhoneNumber({direction, to_number, from_number});
  const deleteFutureCommunication = useDeleteFutureCommunication({});
  const {handleAssignAccountId} = useAssignAccountId({user, interactionId: interaction_id});

  const handleCancelEvent = useCallback(() => {
    if (id && user?.id) {
      deleteFutureCommunication.mutateAsync({timedInteractionId: id, userId: user.id});
    }
  }, [id, user]);

  const openMediaPlayer = useCallback(
    (event: any) => {
      if (user) {
        downloadUserFiles({
          fileId: recording_file_id.toString(),
          isAgent: true,
          viewMode: true,
          fileName: recording_file_name,
        });
      }
    },
    [recording_file_id, token, user]
  );

  const handleDownloadFile = (fileId: string) => {
    downloadUserFiles({fileId, isAgent: true});
  };

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start", gap: "5px"}}>
        {id && <StyledText value={`ID: ${id}`} />}
        {phone_number && <StyledText value={phone_number} />}
        {call_reason && <StyledText value={`Reason: ${InputUtils.prettierSnakeCase(call_reason)}`} />}
        {call_summary && <StyledText value={`Call Summary: ${call_summary}`} />}
        <Container fullWidth sx={{alignItems: "end", gap: "5px"}}>
          <Container row>
            {transcription_file_id && (
              <Link onClick={() => handleDownloadFile(transcription_file_id)} sx={{cursor: "pointer"}}>
                <StyledText value={"Transcription"} bold link />
              </Link>
            )}
            {transcription_file_id && (recording_file_id || recording_link) && <StyledText value={" | "} bold link />}
            {recording_link && (
              <Link href={recording_link} target="_blank" rel="noreferrer" sx={{cursor: "pointer"}}>
                <StyledText value={"Recording"} bold link />
              </Link>
            )}
            {recording_file_id && (
              <Link target="_blank" rel="noreferrer" onClick={openMediaPlayer} sx={{cursor: "pointer"}}>
                <StyledText value={"Recording"} bold link />
              </Link>
            )}
          </Container>
        </Container>
        {event_type === "SCHEDULED_PHONE_CALL" && id && (
          <Container fullWidth sx={{alignItems: "end"}}>
            <Link onClick={handleCancelEvent} sx={{cursor: "pointer"}}>
              <StyledText value={"Cancel event"} bold link />
            </Link>
          </Container>
        )}
        {!account_id && ["PHONE_CALL_INCOMING", "PHONE_CALL_DIALED"].includes(event_type) && (
          <Container fullWidth sx={{alignItems: "end"}}>
            <Link onClick={handleAssignAccountId} sx={{cursor: "pointer"}}>
              <StyledText value={"Assign account ID"} bold link />
            </Link>
          </Container>
        )}
      </Container>
    </Container>
  );
};

const UnansweredPhoneCallItem = ({activity}: IActivityCard) => {
  const {from_number} = activity as PhoneCallData;
  if (!from_number) return null;

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start", gap: "5px"}}>
        <StyledText value={`From: ${from_number}`} />
      </Container>
    </Container>
  );
};
