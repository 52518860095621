import React, {useRef} from "react";
import Lottie from "lottie-react";
import {Container} from "../Container";

const LottiePay = ({animatedPayJson}: {animatedPayJson: any}) => {
  const lottieRef = useRef(null);
  return (
    <Container
      sx={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{width: "200px"}}>
        <Lottie animationData={animatedPayJson} loop={true} lottieRef={lottieRef} />
      </div>
    </Container>
  );
};

export {LottiePay};
