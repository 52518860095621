import NProgress from "nprogress";
import React, {useEffect, useMemo} from "react";
import {useTheme} from "@mui/material/styles";
import {Box, CircularProgress, GlobalStyles} from "@mui/material";
import {Colors} from "bounce-ui/consumer";

export function ProgressBarStyle() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        "#nprogress": {
          pointerEvents: "none",
          "& .bar": {
            top: 0,
            left: 0,
            height: 2,
            width: "100%",
            position: "fixed",
            zIndex: theme.zIndex.snackbar,
            backgroundColor: Colors.primary._800,
            boxShadow: `0 0 2px ${Colors.primary._800}`,
          },
          "& .peg": {
            right: 0,
            opacity: 1,
            width: 100,
            height: "100%",
            display: "block",
            position: "absolute",
            transform: "rotate(3deg) translate(0px, -4px)",
            boxShadow: `0 0 10px ${Colors.primary._800}, 0 0 5px ${Colors.primary._800}`,
          },
        },
      }}
    />
  );
}

function ProgressBar() {
  NProgress.configure({
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

export default function LoadingScreen({...other}) {
  return (
    <>
      <ProgressBar />
      <div style={{height: "100vh"}}>
        <Box
          sx={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      </div>
    </>
  );
}
