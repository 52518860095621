import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {ActivityData} from "../../../pages/backoffice/information-panel/tabs/user-timeline/activities/activity_models";

type Variables = {
  userId: string;
};

type Data = ActivityData[];
export const getUserTimeline = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>(`/user/timeline/${variables.userId}`);
  return result.data;
};

export const useGetUserTimeline = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(["user", variables.userId, "timeline"], getUserTimeline, variables, options);
};
