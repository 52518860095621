import {experimentalStyled as styled} from "@mui/material/styles";
import {Colors} from "..";
import {PrettySlider} from "../../../components/inputs/PrettySlider";

export const SliderStyled = styled(PrettySlider)<{issinglemark: string}>(({theme, issinglemark}) => ({
  ".MuiSlider-rail": {
    borderColor: Colors.grey._200,
    height: "5px",
  },
  ".MuiSlider-mark": {
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    backgroundColor: Colors.grey._200,
    display: issinglemark ? "none" : "block",
  },
  ".MuiSlider-markLabel": {
    fontWeight: 600,
    size: "16px",
    color: Colors.primary._800,
    left: issinglemark ? "50% !important" : "0%",
  },
  ".MuiSlider-thumb": {
    width: "17px",
    height: "17px",
    backgroundColor: Colors.primary._800,
    border: "none",
    left: issinglemark ? "50% !important" : "0%",
  },
}));
