import {Popover, PopoverProps} from "@mui/material";
import React from "react";
import {Colors} from "../../bounce-ui/colors";

export default function MenuPopover({children, sx, ...other}: PopoverProps) {
  return (
    // @ts-ignore
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: {
          overflow: "inherit",
          boxShadow: (theme) => theme.customShadows.z20,
          border: `solid 1px ${Colors.grey._200}`,
          width: 200,
          ...sx,
        },
      }}
      {...other}
    >
      {children}
    </Popover>
  );
}
