import React from "react";
import {IActivityCard} from "./ActivityCard";
import {DisputeData} from "./activity_models";
import {Container, Divider} from "bounce-ui/backoffice";
import {isEmpty} from "lodash";
import {StyledText} from "./components/StyledText";

export const DisputeItem = ({activity}: IActivityCard) => {
  const {dispute_additional_text, files_names} = activity as DisputeData;
  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start"}}>
        {dispute_additional_text && <StyledText value={`Additional Text: ${dispute_additional_text}`} />}
        {!isEmpty(files_names) && <StyledText value={`Attachments: ${files_names.join(",")}`} />}
      </Container>
    </Container>
  );
};
