import React, {useCallback} from "react";
import IconMenuItem from "../../../../components/common/IconMenuItem";
import {IconContact} from "../../../../bounce-ui/consumer";
import NiceModal from "@ebay/nice-modal-react";
import ContactMethodsDialog from "../../../../pages/account/user-contact-details/contact-methods/ContactMethods";
import {useAmplitude} from "../../../../contexts";
import {Pages} from "../../../../bi-events/enum";

interface IManageContactItem {
  userId: string;
  closeHandler: () => void;
}

const ManageContactItem = ({userId, closeHandler}: IManageContactItem) => {
  const {pageClickEvent} = useAmplitude();

  const handleContactMethods = useCallback((): void => {
    pageClickEvent({page: Pages.MENU, cta: "manage_contact"});
    closeHandler();
    NiceModal.show(ContactMethodsDialog, {});
  }, []);

  return <IconMenuItem label="Manage contact details" icon={<IconContact />} onClick={handleContactMethods} />;
};

export default ManageContactItem;
