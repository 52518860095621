import {useState} from "react";
import {useUser} from "../../../contexts";
import {size} from "lodash";

export const useStepper = (steps: string[]) => {
  const [activeStep, setActiveStep] = useState(0);
  const {isAgent} = useUser();
  const stepsLength = size(steps);

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else if (!isAgent) {
      window.history.back();
    }
  };

  const handleNext = () => {
    if (activeStep < stepsLength - 1) {
      setActiveStep(activeStep + 1);
      window.scrollTo({top: 0, behavior: "smooth"});
    }
  };

  const resetStepper = () => {
    setActiveStep(0);
  };

  return {activeStep, resetStepper, handleBack, handleNext};
};
