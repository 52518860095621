import {FunctionComponent} from "react";
import {Container, Text} from "bounce-ui/backoffice";

interface IArbitraryPoliceRules {
  policeBrokenRules: string[];
}
export const ArbitraryPoliceRules: FunctionComponent<IArbitraryPoliceRules> = ({policeBrokenRules}) => {
  return policeBrokenRules.length > 0 ? (
    <Container sx={{padding: "10px 0px", alignItems: "start"}}>
      <Text size={14}>The police failed on the following rules: </Text>
      <Container sx={{gap: "0px", alignItems: "start"}}>
        {policeBrokenRules.map((policeBrokenRule, index) => {
          return (
            <Text key={index} size={14} fontWeight={"bold"}>
              <li>{policeBrokenRule}</li>
            </Text>
          );
        })}
      </Container>
    </Container>
  ) : null;
};
