import {IconButton} from "@mui/material";
import React from "react";
import {IconCloseDrawer} from "./index";

interface ICloseDialogButton {
  onClose: () => void;
  isDrawer?: boolean;
}

export const CloseDialogButton = ({onClose, isDrawer = false}: ICloseDialogButton) => {
  return (
    <IconButton
      aria-label="close"
      size="small"
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        zIndex: 10,
      }}
    >
      <IconCloseDrawer style={{width: "28px", height: "28px"}} />
    </IconButton>
  );
};
