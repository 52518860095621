import axios from "axios";
import {get, isString} from "lodash";
import React, {FunctionComponent, useCallback, useEffect} from "react";
import {useSnackbar} from "notistack";
import {useAmplitude, useUser} from "../contexts";
import {axiosConsumerAppInstance, axiosFlexInstance, axiosSpringInstance} from "./ServerApiManager";
import {useNavigate} from "react-router-dom";
import {urls} from "../routes/urls";
import {Text} from "bounce-ui/consumer";

interface ServerApiExceptionManagerProps {}

const ServerApiExceptionManager: FunctionComponent<ServerApiExceptionManagerProps> = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {clearCredentials, loggedIn, isAgent} = useUser();
  const navigate = useNavigate();
  const {setError} = useAmplitude();
  const logoutHandler = useCallback((): void => {
    clearCredentials();
    window.location.reload();
  }, [clearCredentials]);

  const notificationHandler = useCallback((error: any) => {
    const errorData = get(error, "response.data");
    if (!isAgent) {
      const errorData = get(error, "response.data");
      if (errorData === "Already has an active payment plan") {
        navigate(urls.accounts.url);
      }
    }
    const errorMessage = errorData && isString(errorData) ? errorData : undefined;
    setError(errorMessage);
    enqueueSnackbar(<Text size={14}>{errorMessage || "Network connection is lost."}</Text>, {
      variant: "error",
    });
  }, []);

  const errorHandler = useCallback(
    (error: any) => {
      const errorStatus = get(error, "response.status");
      const errorMsg = get(error, "response.data");
      if ((errorStatus === 401 || errorStatus === 403) && errorMsg !== "Invalid role" && loggedIn) {
        logoutHandler();
        return Promise.reject(error);
      }

      const isCancel = axios.isCancel(error);
      if (isCancel) {
        return Promise.reject(error);
      }

      notificationHandler(error);
      return Promise.reject(error);
    },
    [logoutHandler, notificationHandler]
  );

  useEffect(() => {
    axiosConsumerAppInstance.interceptors.response.use(undefined, errorHandler);
    axiosFlexInstance.interceptors.response.use(undefined, errorHandler);
    axiosSpringInstance.interceptors.response.use(undefined, errorHandler);
  }, []);

  return null;
};

export default ServerApiExceptionManager;
