import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {UiSettings} from "../../../declerations/server";

type Variables = {user_id: string};

type Data = UiSettings;

export const getUiSettings = async (variables: Variables): Promise<Data> => {
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(`/user/ui/${variables.user_id}`);
  return result.data;
};

export const useGetUiSettings = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => useBaseMutation(getUiSettings, options);
