import React from "react";
import {Container, Text} from "../../../../bounce-ui/consumer";
import {InputAdornment, Typography, TextField} from "@mui/material";
import {formatCurrency} from "../../../../helpers/formatNumber";
import {Colors} from "../../../../bounce-ui/colors";

interface ICustomPaymentBox {
  isValid: boolean;
  amount: number | undefined;
  totalBalance: number;
  setAmount: (amount: number) => void;
  title?: string;
  isDialog?: boolean;
}

export const getHelperText = ({
  isValid,
  amount,
  totalBalance,
}: {
  isValid: boolean;
  amount?: number;
  totalBalance: number;
}) => {
  if (amount === 0) {
    return;
  }
  if (!isValid && amount && amount !== 0) {
    return (
      <Text size={12} variant={"h1"} sx={{color: Colors.red._900}}>
        {amount > totalBalance ? "Insert a lower amount" : "Invalid amount"}
      </Text>
    );
  }
};
const CustomPaymentBox = ({isValid, totalBalance, amount, setAmount, isDialog = false, title}: ICustomPaymentBox) => {
  return (
    <Container fullWidth>
      {title && (
        <Text fontWeight={"medium"} sx={{alignItems: "start", width: "100%"}}>
          {title}
        </Text>
      )}
      <TextField
        type={"number"}
        fullWidth
        onChange={(event) => setAmount(Number(event.target.value) * 100)}
        error={!isValid}
        helperText={getHelperText({isValid, amount, totalBalance})}
        InputProps={{
          style: {borderRadius: "8px"},
          inputProps: {
            style: {
              fontWeight: 500,
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <Typography sx={{color: "text.primary", fontWeight: 500}}>{"$"}</Typography>
            </InputAdornment>
          ),
        }}
        sx={{backgroundColor: Colors.bw.white}}
      />
      <Text
        translateId={isDialog ? "totalRemainingBalance" : "totalBalanceValue"}
        size={14}
        values={{totalBalance: formatCurrency(totalBalance)}}
        sx={{color: Colors.grey._700}}
      />
    </Container>
  );
};

export default CustomPaymentBox;
