import {Outlet} from "react-router-dom";
import {FunctionComponent} from "react";

type AgentLayoutProps = {};

const BackofficeLayout: FunctionComponent<AgentLayoutProps> = () => {
  return <Outlet />;
};

export default BackofficeLayout;
