import React, {useCallback, useMemo} from "react";
import {IActivityCard} from "./ActivityCard";
import {Container, Divider, Text} from "bounce-ui/backoffice";
import {StyledText} from "./components/StyledText";
import {Link} from "../../../../../../bounce-ui/backoffice";
import {useSnackbar} from "notistack";
import {useGetUserWithSelectedAccountQuery, useUpdateAccount} from "../../../../../../apis/requests/backoffice";
import {useTask} from "../../../../../../contexts";
import {useAccountId} from "../../../../../../contexts/useAccountId";
import {Comment} from "../../../../../../declerations/server/comment_models";
import {CommentData} from "./activity_models";
import NiceModal from "@ebay/nice-modal-react";
import AreYouSureDialog from "../../../components/dialogs/AreYouSureDialog";

export const CommentItem = ({activity}: IActivityCard) => {
  const {commend_public_id} = activity as CommentData;
  const {enqueueSnackbar} = useSnackbar();
  const updateAccount = useUpdateAccount({});
  const {userId} = useTask();
  const accountId = useAccountId();
  const {data: user} = useGetUserWithSelectedAccountQuery({userId, accountId}, {enabled: !!userId});
  const account = useMemo(() => user?.account, [user]);
  const currentAccountComments = useMemo<Comment[]>(() => account?.comments || [], [account?.comments]);
  const onRemoveComment = async () => {
    const currentComment = currentAccountComments.find((comment) => {
      return commend_public_id === comment.public_id;
    });
    try {
      if (currentComment) {
        const commentToUpdate = [
          {
            is_remove: commend_public_id === currentComment.public_id,
            ...currentComment,
          },
        ];
        await updateAccount.mutateAsync({
          userId,
          accountId,
          accountComments: commentToUpdate,
        });
        enqueueSnackbar(<Text size={14}>Changes saved successfully</Text>, {variant: "success"});
      }
    } catch {
      enqueueSnackbar(<Text size={14}>Failed to save changes</Text>, {variant: "error"});
    }
  };

  const onClickRemove = useCallback(() => {
    NiceModal.show(AreYouSureDialog, {
      onConfirm: onRemoveComment,
      contentTextId: "Are you sure you want to delete this comment?",
      confirmTextId: "Delete",
    });
  }, []);

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start"}}>
        <Container fullWidth row sx={{justifyContent: "end"}}>
          <Link onClick={onClickRemove} sx={{cursor: "pointer"}}>
            <StyledText value={"Delete comment"} bold link />
          </Link>
        </Container>{" "}
      </Container>
    </Container>
  );
};
