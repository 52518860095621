import * as Sentry from "@sentry/react";
import {FunctionComponent, PropsWithChildren, useCallback, useContext, useEffect} from "react";
import {useUser} from "./UserContext";
import {getUrlInfo} from "../routes/urls";
import React from "react";
import {createRoutesFromChildren, matchRoutes, useNavigationType, useLocation} from "react-router-dom";
import {env, sentryDsn} from "constants/defaultValues";
import {setTag} from "@sentry/react";

export interface SentryContextProps {
  setTag: typeof setTag;
}

const SentryContext = React.createContext<SentryContextProps>(undefined!);
interface IProps extends PropsWithChildren<any> {}

export const SentryProvider: FunctionComponent<IProps> = ({children}) => {
  const {userId, isAgent} = useUser();
  const {pathname, search} = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlUserId = queryParams.get("id");
  const enabled = ["production", "staging"].includes(env);

  useEffect(() => {
    if (enabled) {
      Sentry.init({
        enabled: true,
        dsn: sentryDsn,
        environment: env,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: isAgent ? 0 : 1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }, [enabled]);

  const identifyUser = useCallback((userId?: string): void => {
    if (userId) {
      Sentry.setUser({id: userId});
    } else {
      Sentry.setUser({});
    }
  }, []);

  useEffect(() => {
    if (userId) {
      identifyUser(userId);
    } else if (urlUserId) {
      identifyUser(urlUserId);
    }
  }, [userId, urlUserId]);

  useEffect(() => {
    if (!pathname) {
      return;
    }

    const urlInfo = getUrlInfo(pathname);
    if (!urlInfo) {
      return;
    }
  }, [pathname]);

  return <SentryContext.Provider value={{setTag}}>{children}</SentryContext.Provider>;
};

const useSentry = (): SentryContextProps => {
  const context = useContext(SentryContext);

  if (!context) throw new Error("SentryContext must be used inside user SentryProvider");

  return context;
};

export {useSentry};
