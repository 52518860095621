import {CreditReportDetails} from "../declerations/server/account_models";
import dayjs from "dayjs";

export function getDaysUntilCreditReport(creditReportDetails?: CreditReportDetails): number | undefined {
  if (!creditReportDetails) {
    return;
  }
  if (creditReportDetails.last_report_type === "not_reported" && creditReportDetails.next_report_date) {
    return dayjs(creditReportDetails.next_report_date).diff(dayjs(), "day");
  }
  return;
}
