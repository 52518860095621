import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {EmailCommunicationDetails, PhoneCommunicationDetails} from "../../../declerations/server";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  isAgent?: boolean;
  user_id: string;
  contact: EmailCommunicationDetails | PhoneCommunicationDetails;
  contactType: "email" | "phone";
};

export const addContactToUser = async (variables: Variables): Promise<any> => {
  let result;
  if (variables.isAgent) {
    result = await axiosFlexInstance.post<string, AxiosResponse<string>>(
      `/contacts/add_${variables.contactType}/${variables.user_id}`,
      variables.contact
    );
  } else {
    result = await axiosConsumerAppInstance.post<string, AxiosResponse<string>>(
      `/user/add_${variables.contactType}`,
      variables.contact
    );
  }

  return result.data;
};

export const useAddContactToUser = (
  options?: BaseMutationOptions<boolean, Variables>
): BaseUseMutationResult<boolean, Variables> =>
  useBaseMutation(addContactToUser, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user"],
  });
