import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  externalPaymentId: string;
};
type Data = boolean;

export const voidPayment = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.patch<boolean, AxiosResponse<boolean>>(
    `/payments/${variables.externalPaymentId}/void`
  );
  return result.data;
};

export const useVoidPayment = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(voidPayment, {
    invalidateQueriesKeyFn: (data, variables) => ["user"],
    ...options,
  });

export const refundPayment = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.patch<boolean, AxiosResponse<boolean>>(
    `/payments/${variables.externalPaymentId}/refund`
  );
  return result.data;
};

export const useRefundPayment = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(refundPayment, {
    invalidateQueriesKeyFn: (data, variables) => ["user"],
    ...options,
  });
