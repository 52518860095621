import {merge} from "lodash";
import {Theme} from "@mui/material/styles";
import Menu from "./Menu";
import Grid from "./Grid";
import Link from "./Link";
import Table from "./Table";
import Alert from "./Alert";
import Drawer from "./Drawer";
import Slider from "./Slider";
import Switch from "./Switch";
import Select from "./Select";
import SvgIcon from "./SvgIcon";
import Tooltip from "./Tooltip";
import DataGrid from "./DataGrid";
import Progress from "./Progress";
import Container from "./Container";
import Typography from "./Typography";
import ButtonGroup from "./ButtonGroup";
import Autocomplete from "./Autocomplete";
import ToggleButton from "./ToggleButton";
import ControlLabel from "./ControlLabel";
import LoadingButton from "./LoadingButton";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Menu(theme),
    Grid(theme),
    Link(theme),
    Table(theme),
    Alert(theme),
    Switch(theme),
    Select(theme),
    Slider(theme),
    Drawer(theme),
    Tooltip(theme),
    SvgIcon(theme),
    DataGrid(theme),
    Progress(theme),
    Container(theme),
    Typography(theme),
    ButtonGroup(theme),
    Autocomplete(theme),
    ControlLabel(theme),
    ToggleButton(theme),
    LoadingButton(theme)
  );
}
