import React, {useCallback} from "react";
import {Text} from "../../../../../../bounce-ui/consumer";
import {UserExternal} from "../../../../../../declerations/server/user_models";
import {useUpdateInteractionAccountId} from "../../../../../../apis/requests/backoffice/updateInteractionAccountId";
import {useSnackbar} from "notistack";

interface IAssignAccountId {
  user?: UserExternal;
  interactionId: number;
}
export const useAssignAccountId = ({user, interactionId}: IAssignAccountId) => {
  const updateInteractionAccountId = useUpdateInteractionAccountId({});
  const {enqueueSnackbar} = useSnackbar();

  const handleAssignAccountId = useCallback(async () => {
    if (user && interactionId) {
      try {
        const res = await updateInteractionAccountId.mutateAsync({
          interactionId: interactionId,
          userId: user.id,
          accountId: user.account.id,
        });
        if (res) {
          enqueueSnackbar(<Text size={14}>{`Assigned to account successfully`}</Text>, {
            variant: "success",
          });
        }
      } catch (e) {
        enqueueSnackbar(<Text size={14}>{"Failed to assign to account"}</Text>, {
          variant: "error",
        });
      }
    }
  }, [interactionId, user]);
  return {handleAssignAccountId};
};
