import React, {FunctionComponent, useCallback, useEffect} from "react";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {Container, Text, ImgAutoPay, Dialog} from "../../../../bounce-ui/consumer";
import {useAmplitude} from "../../../../contexts";
import {Pages} from "../../../../bi-events/enum";

export type EnableAutoPayDialogProps = {
  onEnableAutoPay?: () => void;
};

const EnableAutoPayDialog: FunctionComponent<EnableAutoPayDialogProps & NiceModalHocProps> = NiceModal.create(
  ({onEnableAutoPay}) => {
    const {pageViewEvent, pageClickEvent} = useAmplitude();
    const modal = useModal();

    const activateAutoPayHandler = useCallback(async (): Promise<void> => {
      pageClickEvent({page: Pages.SWITCH_TO_AUTOPAY, cta: "enable_autopay"});
      onEnableAutoPay?.();

      modal.resolve(true);
      modal.hide();
    }, [onEnableAutoPay]);

    useEffect(() => {
      pageViewEvent({page: Pages.SWITCH_TO_AUTOPAY});
    }, []);

    return (
      <Dialog
        image={<ImgAutoPay />}
        title={"Save time with AutoPay"}
        buttonText={"Enable AutoPay"}
        onClick={activateAutoPayHandler}
      >
        <Container sx={{gap: "8px"}}>
          <Text translateId={"enableAutoPayPartOne"} />
          <Text translateId={"enableAutoPayPartTwo"} />
        </Container>
      </Dialog>
    );
  }
);

export default EnableAutoPayDialog;
