import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {amplitudeToken} from "../constants/defaultValues";
import {useUser} from "./UserContext";
import {env} from "constants/defaultValues";
import {get, indexOf, isEmpty, reduce} from "lodash";
import {useLocation} from "react-router-dom";
import {sessionReplayPlugin} from "@amplitude/plugin-session-replay-browser";
import {AmplitudeLogger} from "./AmplitudeLogger";
import {useGetAccount} from "../apis/requests/user/getAccount";
import {CreditReportingType} from "../declerations/server/account_models";

type UserStep = "login" | "accounts" | "onboarding" | "app";

type GeneralEventProps = {
  user_step: string;
  url: string;
  env: string;
  UTM: Record<string, string>;
  event: string;
  error?: string;
  feature_flags?: Record<string, boolean>;
  batch?: string;
  product?: string;
  lender_id?: string;
  account_public_id?: string;
  primary_account?: boolean;
  credit_reporting_status?: CreditReportingType;
};
type PageClickEventProps = GeneralEventProps & {
  page: string;
  cta: string;
  event_type: string;
  context?: string;
  data?: Record<string, any>;
};

type PageViewEventProps = GeneralEventProps & {
  page: string;
  event_type: string;
  context?: string;
  data?: Record<string, any>;
};

export interface AmplitudeContextProps {
  pageClickEvent: ({page, cta, data}: {page: string; cta: string; data?: Record<string, any>}) => void;
  pageViewEvent: ({page, data}: {page: string; data?: Record<string, any>}) => void;
  setError: (error: string | undefined) => void;
}
const AmplitudeContext = React.createContext<AmplitudeContextProps>(undefined!);

interface IProps extends PropsWithChildren<any> {}

const AmplitudeProvider: FunctionComponent<IProps> = ({children}) => {
  const enabled = ["production"].includes(env);
  const {publicUserId, loggedIn, isAgent, featureFlags} = useUser();
  const [accountId, setAccountId] = useState<string>();
  const [userStep, setUserStep] = useState<UserStep>("login");
  const error = useRef<string | undefined>(undefined);
  const UTM = JSON.parse(sessionStorage.getItem("UTM") || "{}");
  const [amplitudeUserId, setAmplitudeUserId] = useState<string>();
  const {search, pathname} = useLocation();

  const searchParams = new URLSearchParams(search);
  const shouldNotTrack = !enabled || isAgent;
  const setError = (currError?: string) => {
    error.current = currError;
  };

  const {data: account} = useGetAccount({accountId}, {enabled: !!(loggedIn && !isAgent && accountId)});
  const activePaymentPlan = useMemo(() => {
    if (account) return account.active_payment_plan;
  }, [account]);

  useEffect(() => {
    // The AmplitudeContext is higher than the Router in the app hierarchy, so we can't use useParam / useAccountId
    const pathParts = pathname.split("/");
    const accountIndex = indexOf(pathParts, "account");
    if (accountIndex !== -1 && accountIndex < pathParts.length - 1) {
      if (!isNaN(parseInt(pathParts[accountIndex + 1]))) {
        setAccountId(pathParts[accountIndex + 1]);
      }
    }
  }, [pathname]);

  const initAmplitude = () => {
    amplitude.init(amplitudeToken, {
      minIdLength: 4,
      userId: amplitudeUserId,
      defaultTracking: {
        sessions: true,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false,
      },
      loggerProvider: new AmplitudeLogger(),
    });
    const sessionReplayTracking = sessionReplayPlugin({sampleRate: 0.5});
    amplitude.add(sessionReplayTracking);
  };

  const saveUTMToStorage = () => {
    if (isEmpty(UTM)) {
      const new_UTMs = reduce(
        Array.from(searchParams.entries()),
        (acc, [key, value]) => {
          return {...acc, [key]: value};
        },
        {}
      );
      sessionStorage.setItem("UTM", JSON.stringify(new_UTMs));
    }
  };
  const initUserStep = () => {
    if (!loggedIn) {
      setUserStep("login");
      return;
    }
    if (pathname.includes("accounts")) {
      setUserStep("accounts");
      return;
    }
    if (activePaymentPlan) {
      setUserStep("app");
      return;
    }
    setUserStep("onboarding");
  };
  const initUserId = () => {
    if (publicUserId) {
      setAmplitudeUserId(publicUserId);
    } else if (get(UTM, "id")) {
      setAmplitudeUserId(get(UTM, "id"));
    }
  };

  const generalProperties: GeneralEventProps = useMemo(() => {
    return {
      url: window.location.href,
      env,
      UTM: UTM,
      user_step: userStep,
      event: "",
      feature_flags: featureFlags,
    };
  }, [UTM, window.location.href, userStep]);

  const accountProperties = useMemo(() => {
    if (!isEmpty(account)) {
      return {
        batch: account.batch,
        product: account.product,
        lender_id: account.lender_id,
        account_public_id: account.public_id,
        primary_account: account.primary_account,
        credit_reporting_status: account?.credit_report_details.last_report_type,
      };
    }
    return {};
  }, [account]);

  const pageViewEvent = useCallback(
    ({page, data}: {page: string; data?: Record<string, any>}) => {
      if (shouldNotTrack) {
        return;
      }
      const properties: PageViewEventProps = {
        page: page,
        event_type: "page_view",
        data: data,
        ...generalProperties,
        ...accountProperties,
      };

      if (userStep === "login") {
        properties["event"] = "login_page_view";
        properties["context"] = get(UTM, "id") ? "contextualized_login" : "anonymous_login";
      }
      if (userStep === "onboarding") {
        properties["event"] = "onboarding_page_view";
      }
      if (userStep === "app") {
        properties["event"] = "app_page_view";
      }
      if (userStep === "accounts") {
        properties["event"] = "accounts_page_view";
      }
      amplitude.track(properties["event"], properties);
    },
    [userStep, UTM, generalProperties, accountProperties, shouldNotTrack]
  );

  const pageClickEvent = useCallback(
    ({page, cta, data}: {page: string; cta: string; data?: Record<string, any>}): void => {
      if (shouldNotTrack) {
        return;
      }

      const properties: PageClickEventProps = {
        page: page,
        event_type: "page_click",
        cta: cta,
        data: data,
        error: error.current || data?.error,
        ...generalProperties,
        ...accountProperties,
      };

      if (userStep === "login") {
        properties["event"] = "login_page_clicked";
        properties["context"] = get(UTM, "id") ? "contextualized_login" : "anonymous_login";
      }

      if (userStep === "onboarding") {
        properties["event"] = "onboarding_page_clicked";
      }

      if (userStep === "app") {
        properties["event"] = "app_page_clicked";
      }
      if (userStep === "accounts") {
        properties["event"] = "accounts_page_clicked";
      }
      amplitude.track(properties["event"], properties);
      setError(undefined);
    },
    [userStep, UTM, generalProperties, accountProperties, shouldNotTrack, error]
  );

  useEffect(() => {
    if (shouldNotTrack) {
      return;
    }
    saveUTMToStorage();
    initAmplitude();
  }, []);

  useEffect(() => {
    initUserId();
  }, [loggedIn, UTM]);

  useEffect(() => {
    initUserStep();
  }, [activePaymentPlan, loggedIn, accountId]);

  useEffect(() => {
    if (shouldNotTrack) {
      return;
    }
    const currentId = amplitude.getUserId();
    if (publicUserId && !currentId) {
      amplitude.setUserId(publicUserId);
    }
  }, [publicUserId, shouldNotTrack]);

  return (
    <AmplitudeContext.Provider
      value={{
        pageClickEvent,
        pageViewEvent,
        setError,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};

const useAmplitude = (): AmplitudeContextProps => {
  const context = useContext(AmplitudeContext);

  if (!context) throw new Error("AmplitudeContext must be used inside AmplitudeProvider");

  return context;
};

export {AmplitudeContext, AmplitudeProvider, useAmplitude};
