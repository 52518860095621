import {Theme} from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function LoadingButton(theme: Theme) {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.body2.fontFamily,
          fontWeight: "500",
          textTransform: "none",
          fontSize: "16px",
          "&.MuiButton-text": {
            "& .MuiLoadingButton-startIconPendingStart": {
              marginLeft: 0,
            },
            "& .MuiLoadingButton-endIconPendingEnd": {
              marginRight: 0,
            },
            borderRadius: "30px",
          },
        },
      },
    },
  };
}
