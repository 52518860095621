import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {PaymentDetails} from "../../../declerations/server";
import {IResponseData} from "../../../pages/account/payment/utils";

type Variables = PaymentDetails & {payment_plan_id?: string; account_id: string; user_id?: string; isAgent?: boolean};

type Data = IResponseData;

export const changePaymentMethod = async (variables: Variables): Promise<Data> => {
  const {account_id, user_id, isAgent, ...data} = variables;
  let result;
  if (isAgent) {
    result = await axiosFlexInstance.post<Data, AxiosResponse<Data>>(
      `/payments/payment-method/${variables.user_id}/${variables.account_id}`,
      data
    );
  } else {
    result = await axiosConsumerAppInstance.post<Data, AxiosResponse<Data>>(
      `/payments/payment-method/${account_id}`,
      data
    );
  }

  return result.data;
};

export const useChangePaymentMethod = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(changePaymentMethod, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["account", variables.account_id],
  });
};
