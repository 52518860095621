import {replace} from "lodash";
import numeral from "numeral";

export function formatCurrency(number: number): string {
  return numeral(number / 100.0).format("$0,0.00");
}

export function formatCurrencyInDollar(number: number) {
  return numeral(number).format("$0,0.00");
}

export function formatPercent(number: number) {
  return numeral(number).format("0%");
}
export function formatNumber(number: string | number) {
  return numeral(number).format();
}

export function formatShortenNumber(number: string | number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function formatData(number: string | number) {
  return numeral(number).format("0.0 b");
}
