import React, {FunctionComponent} from "react";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {NoticeDialog} from "bounce-ui/backoffice";

export type SaveChangesDialogProps = {
  onConfirm: () => void;
};

const ConfirmCommunicationDialog: FunctionComponent<SaveChangesDialogProps & NiceModalHocProps> = NiceModal.create(
  ({onConfirm}) => {
    return (
      <NoticeDialog
        title={"Confirm Communication"}
        text={"Are you sure you want to proceed?"}
        onClick={onConfirm}
        buttonText={"Continue"}
        secondaryButtonText={"Cancel"}
      />
    );
  }
);

export default ConfirmCommunicationDialog;
