import * as React from "react";
import {styled} from "@mui/material/styles";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import {Container} from "./Container";
import {Colors} from "../colors";

interface ILinearProgress {
  backgroundcolor?: string;
}

interface IProgressBar extends ILinearProgress {
  value: number;
}

const BorderLinearProgress = styled(LinearProgress)<ILinearProgress>(({backgroundcolor, theme}) => {
  return {
    height: 4,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: Colors.grey._200,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: backgroundcolor,
    },
  };
});

export const ProgressBar = ({value, backgroundcolor}: IProgressBar) => {
  return (
    <Container fullWidth>
      <BorderLinearProgress
        variant="determinate"
        value={value}
        backgroundcolor={backgroundcolor}
        sx={{width: "100%"}}
      />
    </Container>
  );
};
