import {Chip, IconButton, Link} from "@mui/material";
import React, {ReactNode} from "react";
import {Card, Colors, Container, Text} from "bounce-ui/consumer";
import {isUndefined} from "lodash";
import {prettifyCurrencyRounded} from "../../../helpers/currency";
import useIsMobile from "../../../helpers/useIsMobile";
import {formatCurrency} from "../../../helpers/formatNumber";
import {PaymentPlanOffer} from "../../../declerations/server/payment_plan_models";

interface ISummaryText {
  field: string;
  value: string;
  icon?: ReactNode;
  lineCross?: boolean;
  onClickIcon?: () => void;
}

interface IPlanSuggestion {
  index: number;
  firstAmount: number;
  duration: number;
  secondAmount?: number;
  discount?: number;
  selected: boolean;
  handleSelect: (index: number) => void;
  isSingleSuggestion?: boolean;
}

interface ITotalBalanceText {
  total: number;
  original: number;
  children?: ReactNode;
}

interface ITextLabel {
  title: string;
  subtitle: string;
  icon: ReactNode;
}

export interface ISelectPlan {
  index: number;
  paymentPlanOffer: PaymentPlanOffer | undefined;
}

const SummarySectionTitle = ({title}: {title: string}) => {
  return (
    <Text
      size={14}
      variant={"body3"}
      sx={{textTransform: "uppercase", color: Colors.grey._500, letterSpacing: "0.5px"}}
    >
      {title}
    </Text>
  );
};

const SummaryText = ({field, value, icon, lineCross = false, onClickIcon}: ISummaryText) => {
  return (
    <Container row fullWidth sx={{justifyContent: "space-between"}}>
      <Text sx={{textAlign: "start"}}>{field}:</Text>
      <Container row sx={{textAlign: "end"}}>
        <Text fontWeight={"bold"} sx={{...(lineCross && {textDecoration: "line-through"})}}>
          {value}
        </Text>
        {icon && (
          <IconButton onClick={onClickIcon} sx={{padding: "0px"}}>
            {icon}
          </IconButton>
        )}
      </Container>
    </Container>
  );
};

const BulletText = ({text}: {text: string}) => {
  return (
    <Container fullWidth row sx={{alignItems: "start", padding: "0px 10px"}}>
      &bull;
      <Text translateId={text} size={14} variant={"body1"} sx={{color: Colors.secondary._900}} />
    </Container>
  );
};

const PlanSuggestion = ({
  index,
  firstAmount,
  duration,
  secondAmount,
  discount,
  selected,
  handleSelect,
  isSingleSuggestion = false,
}: IPlanSuggestion) => {
  const isMobile = useIsMobile();

  const onClick = () => {
    handleSelect(index);
  };
  return (
    <Link onClick={onClick} sx={{cursor: "pointer", width: isMobile ? "100%" : "530px"}}>
      <Card
        fullWidth
        row
        sx={{
          border: "2px solid",
          justifyContent: isUndefined(discount) ? "center" : "space-between",
          borderColor: selected && !isSingleSuggestion ? Colors.primary._800 : Colors.bw.white,
          padding: "18px",
        }}
      >
        <Text variant={"body3"} sx={{display: "inline"}}>
          {`${prettifyCurrencyRounded(firstAmount)}/mo `}
          <Text variant={secondAmount ? "body3" : "body1"} sx={{display: "inline"}}>{`for ${duration} months`}</Text>
          {secondAmount && (
            <Text variant={"body1"} sx={{display: "inline"}}>
              {`, then ${prettifyCurrencyRounded(secondAmount)}/mo`}
            </Text>
          )}
        </Text>
        {!!discount && (
          <Chip
            label={
              <Text variant={"body3"} size={12} color={Colors.primary._800}>
                -{discount}%
              </Text>
            }
            sx={{
              height: "22px",
              borderRadius: "6px",
              gap: "4px",
              backgroundColor: Colors.primary._100,
            }}
          />
        )}
      </Card>
    </Link>
  );
};

const TotalBalanceText = ({total, original, children}: ITotalBalanceText) => {
  if (!total || !original) return <></>;
  return (
    <Text>
      Total{" "}
      <Text fontWeight={"bold"} sx={{display: "inline"}}>
        {formatCurrency(total)}
      </Text>
      {total < original && (
        <>
          {" "}
          (original <Text sx={{textDecoration: "line-through", display: "inline"}}>{formatCurrency(original)}</Text>)
        </>
      )}
      {children}
    </Text>
  );
};

const TextLabel = ({title, subtitle, icon}: ITextLabel) => {
  const isMobile = useIsMobile();

  return (
    <Container row sx={{textAlign: "start", alignItems: "start", width: isMobile ? "100%" : "320px"}}>
      <div>{icon}</div>
      <Text translateId={title} variant={"body3"} sx={{display: "inline"}}>
        <Text translateId={subtitle} variant={"body1"} sx={{display: "inline"}} />
      </Text>
    </Container>
  );
};

export {SummarySectionTitle, SummaryText, BulletText, PlanSuggestion, TotalBalanceText, TextLabel};
