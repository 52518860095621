import {Theme} from "@mui/material/styles";
import {Components} from "@mui/material/styles/components";

// ----------------------------------------------------------------------

export default function ControlLabel(theme: Theme): Components {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
        label: {
          ...theme.typography.body2,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: "-1.2rem",
          marginLeft: 0,
          marginRight: 0,
          "&.Mui-error": {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.disabled,
        },
      },
    },
  };
}
