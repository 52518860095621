import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {useUser} from "../../../contexts";

type Variables = {
  isAgent?: boolean;
};

type Data = {
  phone_number: string;
  email: string;
};

export const getContactDetail = async (variables: Variables): Promise<Data> => {
  if (variables.isAgent) {
    return {phone_number: "", email: ""};
  }
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(`/user/contact_details`);
  return result.data;
};

export const useGetContactDetailQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const {isAgent} = useUser();
  return useBaseQuery<Data, Variables>(
    ["user", "communication", "details"],
    getContactDetail,
    {isAgent, ...variables},
    options
  );
};
