import {useLocation} from "react-use";
import {springBaseUrl} from "constants/defaultValues";

const LoginPage = () => {
  const {search} = useLocation();
  const queryParams = new URLSearchParams(search);
  const from = queryParams.get("from");
  const callbackUrl = `${springBaseUrl}/cognito/callback`;

  let redirectUrl = `https://login.finbounce.com/oauth2/authorize?client_id=4bsifpcfbbkjm16e3l4u2p3ij8&response_type=code&scope=email+openid+phone+profile&redirect_uri=${encodeURIComponent(
    callbackUrl
  )}`;
  if (from) {
    redirectUrl += `&state=${encodeURIComponent(from)}`;
  }

  window.location.href = redirectUrl;

  return null;
};

export default LoginPage;
