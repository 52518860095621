import {AxiosResponse} from "axios";
import {UploadFileType} from "declerations/server/files_models";
import {axiosFlexInstance} from "../../ServerApiManager";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {forEach} from "lodash";

type Variables = {
  userId: string;
  accountId: string;
  description: string;
  type: UploadFileType;
  files: File[];
};

export const uploadUserFiles = async (variables: Variables): Promise<void> => {
  const formData = new FormData();
  formData.append("file_type", variables.type);
  if (variables.description) {
    formData.append("description", variables.description);
  }
  forEach(variables.files, (file) => formData.append("files", file));
  await axiosFlexInstance.post<string, AxiosResponse<string>>(
    `/files/${variables.userId}/${variables.accountId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const useUploadUserFiles = (
  options?: BaseMutationOptions<void, Variables>
): BaseUseMutationResult<void, Variables> =>
  useBaseMutation(uploadUserFiles, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user", "files"],
  });
