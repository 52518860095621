import React, {useMemo} from "react";
import {useGetDashboardQuery} from "../../../apis/requests/plans";
import {useUser} from "../../../contexts";
import {OneTimePaymentItem, PaymentMethodItem, SwitchToAutoPayItem} from "./menu-items";
import CancelMyPlanItem from "./menu-items/DashboardItems/CancelMyPlanItem";
import CancelAutoPayItem from "./menu-items/DashboardItems/CancelAutoPayItem";

interface IDashboardMenuItems {
  accountId: string;
  activePaymentPlanId: string;
  closeHandler: () => void;
}

const DashboardMenuItems = ({accountId, activePaymentPlanId, closeHandler}: IDashboardMenuItems) => {
  const {isAgent} = useUser();

  const userDashboard = useGetDashboardQuery(
    {
      account_id: accountId,
      isAgent,
      payment_plan_id: activePaymentPlanId,
    },
    {enabled: !!activePaymentPlanId}
  );

  const frequency = useMemo(() => {
    if (userDashboard.data) {
      return userDashboard.data.installment_frequency;
    }
  }, [userDashboard.data]);

  const planBalance = useMemo(() => {
    if (userDashboard.data) {
      return userDashboard.data.amount_unpaid;
    }
  }, [userDashboard.data]);

  const autoPayEnrolled = useMemo(() => {
    if (userDashboard.data) {
      return userDashboard.data.autopay_enrolled;
    }
  }, [userDashboard.data]);

  return (
    <>
      {planBalance && (
        <OneTimePaymentItem
          accountId={accountId}
          planBalance={planBalance}
          activePaymentPlanId={activePaymentPlanId}
          closeHandler={closeHandler}
        />
      )}
      <PaymentMethodItem accountId={accountId} activePaymentPlanId={activePaymentPlanId} closeHandler={closeHandler} />
      {frequency && (
        <CancelMyPlanItem
          accountId={accountId}
          frequency={frequency}
          activePaymentPlanId={activePaymentPlanId}
          closeHandler={closeHandler}
        />
      )}
      {!autoPayEnrolled && (
        <SwitchToAutoPayItem
          accountId={accountId}
          activePaymentPlanId={activePaymentPlanId}
          closeHandler={closeHandler}
        />
      )}
      {isAgent && autoPayEnrolled && (
        <CancelAutoPayItem accountId={accountId} paymentPlanId={activePaymentPlanId} closeHandler={closeHandler} />
      )}
    </>
  );
};

export default DashboardMenuItems;
