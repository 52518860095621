import React, {useCallback} from "react";
import {IActivityCard} from "./ActivityCard";
import {SMSData} from "./activity_models";
import {Container, Divider, Link} from "bounce-ui/backoffice";
import DisplayDownloadedContent from "./components/DisplayDownloadedContent";
import {useDeleteFutureCommunication} from "../../../../../../apis/requests/backoffice/deleteFutureCommunication";
import {StyledText} from "./components/StyledText";
import {useAssignAccountId} from "./useAssignAccountId";

export const SMSItem = ({activity, user}: IActivityCard) => {
  const {account_id, event_type, to_phone_number, sms_file_id, interaction_id, id} = activity as SMSData;
  const deleteFutureCommunication = useDeleteFutureCommunication({});
  const {handleAssignAccountId} = useAssignAccountId({user, interactionId: interaction_id});

  const handleCancelEvent = useCallback(() => {
    if (user && id) {
      deleteFutureCommunication.mutateAsync({timedInteractionId: id, userId: user.id});
    }
  }, [id, user]);

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start"}}>
        {id && <StyledText value={`ID: ${id}`} />}
        {to_phone_number && (
          <Container fullWidth sx={{alignItems: "start"}}>
            <StyledText value={`To: ${to_phone_number}`} />
          </Container>
        )}
        {sms_file_id && (
          <Container row sx={{alignItems: "start"}}>
            <StyledText value={`Content: `} />
            <DisplayDownloadedContent interaction_type="SMS" file_id={sms_file_id} />
          </Container>
        )}
        {event_type === "SCHEDULED_SMS" && (
          <Container fullWidth sx={{alignItems: "end"}}>
            <Link onClick={handleCancelEvent} sx={{cursor: "pointer"}}>
              <StyledText value={"Cancel event"} bold link />
            </Link>
          </Container>
        )}
        {!account_id && ["SMS_RECEIVED", "SMS_DELIVERED"].includes(event_type) && (
          <Container fullWidth sx={{alignItems: "end"}}>
            <Link onClick={handleAssignAccountId} sx={{cursor: "pointer"}}>
              <StyledText value={"Assign account ID"} bold link />
            </Link>
          </Container>
        )}
      </Container>
    </Container>
  );
};
