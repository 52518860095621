import dayjs, {Dayjs} from "dayjs";
import {CommunicationType} from "../../../../../declerations/server";
import {useIntl} from "react-intl";
import {ISO_FORMAT} from "./common";
import {useEffect, useState} from "react";
import {arbitraryCommunicationPolice} from "../../../../../apis/requests/backoffice/arbitraryCommunicationPolice";
import {policeRuleTypeInfoMap} from "../../../../../declerations/server/police_models";

interface IArbitraryPoliceRules {
  userId: string;
  contact: string;
  body: string;
  date: Dayjs;
  communicationType: CommunicationType;
}

export const useGetPoliceRules = ({userId, contact, body, date, communicationType}: IArbitraryPoliceRules) => {
  const intl = useIntl();
  const dateInUtcStr = dayjs(date).utc(false).format(ISO_FORMAT);
  const [policeBrokenRules, setPoliceBrokenRulesString] = useState<string[]>([]);
  useEffect(() => {
    (async function () {
      const policeResult = await arbitraryCommunicationPolice({
        user_id: userId,
        content: {subject: body, body: body, communication_type: communicationType},
        contact: contact,
        date: dateInUtcStr,
      });

      if (policeResult.broken_rules.length > 0) {
        setPoliceBrokenRulesString(
          policeResult.broken_rules.map((broken_rule) =>
            intl.formatMessage({id: policeRuleTypeInfoMap.get(broken_rule)?.labelKey})
          )
        );
      }
    })();
  }, []);

  return {policeBrokenRules};
};
