import React, {FunctionComponent, PropsWithChildren, useContext, useEffect, useState} from "react";
import {useUser} from "./UserContext";
import {weglotToken} from "../constants/defaultValues";

export interface WeglotContextProps {
  currLanguage?: string;
  setCurrLanguage: (value: string) => void;
}

const WeglotContext = React.createContext<WeglotContextProps>(undefined!);
interface IProps extends PropsWithChildren<any> {}

interface IProps {
  children: React.ReactNode;
}

const WeglotProvider: FunctionComponent<IProps> = ({children}) => {
  const {isAgent} = useUser();
  const [currLanguage, setCurrLanguage] = useState<string>();
  useEffect(() => {
    if (!isAgent) {
      const defaultLanguage = localStorage.getItem("wglang") || "en";
      window.Weglot.initialize({
        api_key: weglotToken,
        originalLanguage: "en",
        destinationLanguages: ["es"],
        defaultLanguage: defaultLanguage,
      });
      setCurrLanguage(defaultLanguage);
    }
  }, [isAgent]);

  return <WeglotContext.Provider value={{currLanguage, setCurrLanguage}}>{children}</WeglotContext.Provider>;
};

export default WeglotProvider;

const useWeglot = (): WeglotContextProps => {
  const context = useContext(WeglotContext);

  if (!context) throw new Error("WeglotContext must be used inside WeglotContextProvider");

  return context;
};

export {WeglotContext, WeglotProvider, useWeglot};
