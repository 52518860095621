import React from "react";
import {IActivityCard} from "./ActivityCard";
import {ContactUsData} from "./activity_models";
import {Container, Divider} from "bounce-ui/backoffice";
import {StyledText} from "./components/StyledText";

export const ContactUsItem = ({activity}: IActivityCard) => {
  const {email} = activity as ContactUsData;
  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      {email && (
        <Container fullWidth sx={{alignItems: "start"}}>
          <StyledText value={`From: ${email}`} />
        </Container>
      )}
    </Container>
  );
};
