import * as amplitude from "@amplitude/analytics-browser";

/*** This logger is overriding the default Amplitude logger to remove warnings and logs in the browser ***/
export class AmplitudeLogger {
  disable(): void {}

  enable(logLevel: amplitude.Types.LogLevel): void {}

  log(...args: any[]): void {}

  warn(...args: any[]): void {}

  error(...args: any[]): void {
    console.log(...args);
  }

  debug(...args: any[]): void {
    console.log(...args);
  }
}
