import {UseQueryOptions, UseQueryResult} from "@tanstack/react-query/src/types";
import {QueryKey, useQuery} from "@tanstack/react-query";
import {useMemo} from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type BaseQueryOptions<Data, Variables> = Omit<
  UseQueryOptions<Data, unknown, Data>,
  "queryKey" | "queryFn" | "initialData"
> & {
  initialData?: Data | (() => Data);
};

export type BaseUseQueryResult<Data> = UseQueryResult<Data>;

export const useBaseQuery = <Data, Variables>(
  queryKey: QueryKey,
  queryFn: (variables: Variables) => Promise<Data>,
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const queryFnInternal = useMemo<() => Promise<Data>>(() => () => queryFn(variables), [queryFn, variables]);
  return useQuery<Data, unknown, Data>({
    ...options,
    queryKey: queryKey,
    queryFn: queryFnInternal,
  });
};
