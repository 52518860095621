import React, {useCallback} from "react";
import IconMenuItem from "../../../../components/common/IconMenuItem";
import {Pages} from "../../../../bi-events/enum";
import {useAmplitude} from "../../../../contexts";
import {urls} from "../../../../routes/urls";
import {useNavigate} from "react-router-dom";
import {IconHome} from "../../../../bounce-ui/consumer";

interface IHomeItem {
  closeHandler: () => void;
}

const HomeItem = ({closeHandler}: IHomeItem) => {
  const {pageClickEvent} = useAmplitude();
  const navigate = useNavigate();

  const clickHome = useCallback((): void => {
    pageClickEvent({page: Pages.MENU, cta: "home"});
    closeHandler();
    navigate(urls.accounts.url);
  }, []);

  return <IconMenuItem label="Home" icon={<IconHome />} onClick={clickHome} />;
};

export default HomeItem;
