import {IActivityCard} from "./ActivityCard";
import {CollectionNoticeTriggerData} from "./activity_models";
import {Container, Divider} from "bounce-ui/backoffice";
import React from "react";
import {StyledText} from "./components/StyledText";
import InputUtils from "../../../../../../helpers/InputUtils";

export const CollectionNoticeTriggerItem = ({activity}: IActivityCard) => {
  const {category, name} = activity as CollectionNoticeTriggerData;
  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start"}}>
        <StyledText value={`Name: ${InputUtils.prettierSnakeCase(name)}`} />
        <StyledText value={`Category: ${InputUtils.prettierSnakeCase(category)}`} />
      </Container>
    </Container>
  );
};
