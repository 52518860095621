import {IconBankruptcy, IconCallCenter, IconDSC, IconLenderOperations, IconSearchBig} from "../../bounce-ui/assets";
import {urls} from "../../routes/urls";
import * as React from "react";
import {MenuListItem} from "../perminent-menu/types";

export const springMenuItems: MenuListItem = [
  {
    id: "search",
    type: "category-item",
    title: "search",
    icon: <IconSearchBig />,
    url: urls.searchPanelSpring.url,
  },
  {
    id: "lender-operations",
    type: "category-nest-item",
    title: "lender operations",
    icon: <IconLenderOperations />,
    subListItems: [
      {id: "import-users", title: "users", url: urls.importUsers.url},
      {id: "import-payments", title: "payments", url: urls.importPayments.url},
      {id: "washing", title: "washing", url: urls.washing.url},
      {id: "media-upload", title: "media upload", url: urls.mediaUpload.url},
      {id: "import-pricing", title: "pricing - Import", url: urls.importPricing.url},
      {id: "generate-pricing", title: "pricing - Batch", url: urls.generatePricing.url},
    ],
  },
  {
    id: "debt settlement",
    type: "category-nest-item",
    title: "debt settlement",
    icon: <IconDSC />,
    subListItems: [
      {id: "bulk-operations", title: "bulk operations", url: urls.dscBulkOperations.url},
      {id: "anonymous-communications", title: "anonymous comms", url: urls.anonymousCommunications.url},
      {id: "representation-companies", title: "companies", url: urls.representationCompanies.url},
    ],
  },
  {
    id: "bankruptcies",
    type: "category-item",
    title: "bankruptcies",
    icon: <IconBankruptcy />,
    url: urls.bankruptcies.url,
  },
  {
    id: "call-center",
    type: "category-nest-item",
    title: "call center",
    icon: <IconCallCenter />,
    subListItems: [
      {id: "rex-management", title: "REx management", url: urls.rexManagement.url},
      {id: "conversations", title: "conversations", url: urls.conversationsSpring.url},
    ],
  },
];
