import {Dispatch, SetStateAction, useState} from "react";
import {isEqual, isNil, isObject, toString} from "lodash";
import {useLocation, useSearchParams} from "react-router-dom";
import {useUpdateEffect} from "react-use";

export type UrlStateOptions<T> = {
  shouldSaveState?: (state: T) => boolean;
  stateParser?: (urlState: string) => T | null;
  stateStringify?: (state: T) => string;
  addToHistory?: boolean;
};

export const useUrlState = <T>(
  urlParam: string,
  defaultValue: T,
  options?: UrlStateOptions<T>
): [T, Dispatch<SetStateAction<T>>] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {state: locationState} = useLocation();

  const addUrlParamValue = (urlParamValue: string | null, replace: boolean): void => {
    setSearchParams(
      (prev) => {
        const currentUrlParamValue = prev.get(urlParam);
        if (!isEqual(urlParamValue, currentUrlParamValue)) {
          if (urlParamValue) {
            prev.set(urlParam, urlParamValue);
          } else {
            prev.delete(urlParam);
          }
        }
        return prev;
      },
      {replace: replace, state: locationState}
    );
  };

  const removeUrlParamValue = (replace: boolean): void => {
    addUrlParamValue(null, replace);
  };

  const getStateValue = (urlParamValue?: string | null): T => {
    if (urlParamValue && options?.stateParser) {
      return options.stateParser(urlParamValue) || defaultValue;
    }
    return defaultValue;
  };

  const getInitialState = (): T => {
    const urlParamValue = searchParams.get(urlParam);
    return getStateValue(urlParamValue);
  };

  const [state, setState] = useState<T>(getInitialState());

  const shouldSaveStateInternal = (state: T): boolean => {
    if (options?.shouldSaveState) {
      return options.shouldSaveState(state);
    }
    return !!state;
  };

  const stateStringifyInternal = (state: T): string | null => {
    if (isNil(state)) {
      return null;
    }
    if (options?.stateStringify) {
      return options.stateStringify(state);
    }
    if (isObject(state)) {
      return JSON.stringify(state);
    }
    return toString(state);
  };

  useUpdateEffect(() => {
    if (state && shouldSaveStateInternal(state)) {
      const urlParamValue = searchParams.get(urlParam);
      const stateStringValue = stateStringifyInternal(state);

      if (!isEqual(urlParamValue, stateStringValue)) {
        addUrlParamValue(stateStringValue, !options?.addToHistory);
      }
    } else {
      removeUrlParamValue(true);
    }
  }, [state]);

  useUpdateEffect(() => {
    const urlParamValue = searchParams.get(urlParam);
    const stateValue = getStateValue(urlParamValue);
    if (!isEqual(stateValue, state)) {
      setState(stateValue);
    }
  }, [searchParams]);

  return [state, setState];
};
