import React, {ReactNode, useEffect, useMemo} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useUser} from "../../contexts";
import {urls} from "../urls";
import {BounceRole} from "../../contexts/UserContext";
import {jwtDecode, JwtPayload} from "jwt-decode";

type BackofficeGuardProps = {
  children: ReactNode;
};

type JwtPayloadType = JwtPayload & {role: BounceRole};

const BackofficeGuard = ({children}: BackofficeGuardProps) => {
  const {search} = useLocation();
  const queryParams = new URLSearchParams(search);
  const agentTokenParam = queryParams.get("agentToken");

  const agentToken = useMemo<string>(() => {
    return agentTokenParam || localStorage.getItem("agentToken") || "";
  }, [localStorage]);

  const decodedToken: JwtPayloadType | undefined = useMemo(() => {
    if (agentToken) return jwtDecode(agentToken);
  }, [agentToken]);

  const agentEmail = useMemo<string>(() => {
    return localStorage.getItem("agentEmail") || (decodedToken && decodedToken.sub) || "";
  }, [localStorage, decodedToken]);

  const agentRole = useMemo<string>(() => {
    return localStorage.getItem("agentRole") || (decodedToken && decodedToken.role) || "";
  }, [localStorage, decodedToken]);

  const {setCredentials} = useUser();

  useEffect(() => {
    if (agentToken) {
      // If there is token in the local storage it means that this is an agent, we save the user_id in the TaskContext instead of the UserContext,
      localStorage.setItem("agentToken", agentToken);
    }
    if (agentEmail) {
      if (!localStorage.getItem("agentEmail")) {
        localStorage.setItem("agentEmail", agentEmail);
      }
    }
    if (agentRole) {
      if (!localStorage.getItem("agentRole")) {
        localStorage.setItem("agentRole", agentRole);
      }
    }
    setCredentials({token: agentToken, agentEmail, agentRole: agentRole as BounceRole});
  }, [agentToken, localStorage, agentEmail]);

  if (!agentToken) {
    return <Navigate to={`${urls.backofficeLogin.url}?from=${encodeURIComponent(location.href)}`} />;
  }

  return <>{children}</>;
};

export default BackofficeGuard;
