import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {sessionDataHandler} from "../sessionDataHandler";
import {addNotification, changeEngagementPhase} from "../store/actions/genericActions";
import {initSession} from "../store/actions/initActions";
import {AppState, EngagementPhase} from "../store/definitions";
import {Header} from "./Header";
import {notifications} from "../notifications";
import {NotificationBar} from "./NotificationBar";
import {useAmplitude, useUser} from "../../../contexts";
import {Pages} from "../../../bi-events/enum";

export const StartChatWithMessagePhase = () => {
  const {pageClickEvent} = useAmplitude();
  const {name, email, query} = useSelector((state: AppState) => state.session.preEngagementData) || {};
  const {token} = useUser();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    pageClickEvent({page: Pages.CHAT, cta: "start_chat", data: {chat_text: query}});
    dispatch(changeEngagementPhase({phase: EngagementPhase.Loading}));
    try {
      const data = await sessionDataHandler.fetchAndStoreNewSession({
        formData: {
          friendly_name: name,
          email,
          query,
        },
        userToken: token,
      });
      // @ts-ignore
      dispatch(initSession({token: data.token, conversationSid: data.conversation_sid}));
    } catch (err) {
      console.error("PreEngagementFormPhase", err);
      dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
      dispatch(changeEngagementPhase({phase: EngagementPhase.PreEngagementForm}));
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <Header />
      <NotificationBar />
    </>
  );
};
