import React, {ReactNode} from "react";
import {Box, Theme} from "@mui/material";
import {SxProps} from "@mui/system";
export interface IContainerProps {
  children: ReactNode;
  fullWidth?: boolean;
  row?: boolean;
  width?: number | string;
  className?: string;
  sx?: SxProps<Theme>;
}
export const Container = ({children, fullWidth, row = false, width, className = "Container", sx}: IContainerProps) => {
  return (
    <Box
      className={className}
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: row ? "row" : "column",
        width: fullWidth ? "100%" : width,
        gap: "10px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
