import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  userId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  language?: string;
  dateOfBirth?: string;
  phoneCallPreferences?: boolean;
  smsPreferences?: boolean;
  emailPreferences?: boolean;
  mailPreferences?: boolean;
};
type Data = number;

export const updateUser = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.patch<string, AxiosResponse<number>>(`/users/${variables.userId}`, {
    first_name: variables?.firstName,
    middle_name: variables?.middleName,
    last_name: variables?.lastName,
    preferred_language: variables?.language,
    date_of_birth: variables?.dateOfBirth,
    phone_call_preferences: variables?.phoneCallPreferences,
    sms_preferences: variables?.smsPreferences,
    email_preferences: variables?.emailPreferences,
    mail_preferences: variables?.mailPreferences,
  });
  return result.data;
};

export const useUpdateUser = (options?: BaseMutationOptions<Data, Variables>): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updateUser, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user"],
  });
