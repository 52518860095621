import React from "react";
import {IActivityCard} from "./ActivityCard";
import {AccountUpdateData} from "./activity_models";
import {Container, Divider} from "bounce-ui/backoffice";
import {isBoolean, isEmpty, isObject, toString, map, isNil} from "lodash";
import InputUtils from "../../../../../../helpers/InputUtils";
import {StyledText} from "./components/StyledText";

export const AccountUpdateItem = ({activity}: IActivityCard) => {
  const {diff} = activity as AccountUpdateData;
  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      {!isEmpty(diff) && (
        <Container fullWidth sx={{alignItems: "start", gap: "5px"}}>
          {map(Object.entries(diff), ([key, value]) => {
            return <StyledText key={key} value={`${InputUtils.prettierSnakeCase(key)}: ${getValue(value)}`} />;
          })}
        </Container>
      )}
    </Container>
  );
};

const getValue = (value: any) => {
  if (isObject(value)) return JSON.stringify(value);
  if (isBoolean(value)) return toString(value);
  if (isNil(value)) return "null";
  return value;
};
