import React from "react";
import {Container, IContainerProps} from "./Container";
import useIsMobile from "../../helpers/useIsMobile";
import {Colors} from "../colors";

interface ICard extends IContainerProps {
  isDialog?: boolean;
}
export const Card = ({children, row, fullWidth, isDialog = false, className = "Card", sx}: ICard) => {
  const isMobile = useIsMobile();
  return (
    <Container
      className={className}
      fullWidth={fullWidth}
      row={row}
      width={isMobile ? "100%" : "40%"}
      sx={{
        backgroundColor: Colors.bw.white,
        boxShadow: (theme) => theme.customShadows.cardV2,
        borderRadius: "10px",
        padding: isMobile ? "25px" : "25px 50px",
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};
