import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {useUser, useTask} from "../../../contexts";
import {AccountExternal} from "../../../declerations/server/account_models";

type Variables = {
  isAgent?: boolean;
  userId?: string;
  accountId?: string;
};

type Data = AccountExternal;

export const getAccount = async (variables: Variables): Promise<Data> => {
  let result;
  if (!variables?.accountId) {
    // @ts-ignore
    return {};
  }

  if (variables.isAgent) {
    result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>(`/account/${variables.userId}`, {
      params: {account_id: variables.accountId},
    });
  } else {
    result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(`/user/account`, {
      params: {account_id: variables.accountId},
    });
  }
  const {data} = result;
  data.id = String(data.id);
  const paymentPlanId = data.active_payment_plan?.payment_plan_id;
  if (paymentPlanId && data.active_payment_plan) {
    data.active_payment_plan.payment_plan_id = String(data.active_payment_plan.payment_plan_id);
  }
  return data;
};

export const useGetAccount = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const {isAgent} = useUser();
  const {getUserId} = useTask();
  const userId = getUserId();
  const queryKey = variables.accountId ? ["account", variables.accountId] : ["invalid-account"];
  return useBaseQuery<Data, Variables>(queryKey, getAccount, {isAgent, userId, ...variables}, options);
};
