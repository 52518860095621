import React, {useCallback, useMemo} from "react";
import IconMenuItem from "../../../../components/common/IconMenuItem";
import {IconDispute} from "../../../../bounce-ui/consumer";
import {generatePath, useNavigate} from "react-router-dom";
import {urls} from "../../../../routes/urls";
import {useAmplitude} from "../../../../contexts";
import {Pages} from "../../../../bi-events/enum";
import {useGetAccount} from "../../../../apis/requests/user/getAccount";

interface IManageContactItem {
  accountId: string;
  closeHandler: () => void;
}

const DisputeItem = ({accountId, closeHandler}: IManageContactItem) => {
  const navigate = useNavigate();
  const {pageClickEvent} = useAmplitude();
  const {data: account} = useGetAccount({accountId}, {enabled: !!accountId});

  const inActiveDispute = useMemo<boolean | undefined>(() => {
    return account?.active_dispute || false;
  }, [account]);

  const handleDispute = useCallback((): void => {
    pageClickEvent({page: Pages.MENU, cta: "file_dispute"});
    closeHandler();
    navigate(generatePath(urls.dispute.url, {accountId}));
  }, []);

  return (
    <IconMenuItem label="Dispute This Debt" icon={<IconDispute />} onClick={handleDispute} disabled={inActiveDispute} />
  );
};

export default DisputeItem;
