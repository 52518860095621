import React, {FunctionComponent, useMemo, useState} from "react";
import {FormControl, MenuItem, Select} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {Dialog} from "bounce-ui/backoffice";
import {useUpdatePaymentPrincipal} from "../../../../../../../apis/requests/backoffice/updatePaymentPrincipal";
import {useUpdatePaymentPlanPrincipal} from "../../../../../../../apis/requests/backoffice/updatePaymentPlanPrincipal";
import {useTask} from "../../../../../../../contexts";
import InputUtils from "../../../../../../../helpers/InputUtils";
import {InteractionType} from "../utils";
import {useGetRexEmails} from "../../../../../../../apis/requests/backoffice/rex-management/resolutionExperts";

export type AssignPrincipalDialogProps = {
  onConfirm: (event: any) => void;
  userId: string;
  accountId: string;
  defaultValue: string;
  activityType: InteractionType;
  activityId: number;
};

const getNameFromEmail = (input: string) => {
  const parts = input.split("@");
  return parts[0];
};

const AssignPrincipalDialog: FunctionComponent<AssignPrincipalDialogProps & NiceModalHocProps> = NiceModal.create(
  ({accountId, defaultValue, activityType, activityId}) => {
    const [principal, setPrincipal] = useState<string>(defaultValue);
    const {getUserId} = useTask();
    const userId = getUserId();
    const modal = useModal();
    const emailsOptions = useGetRexEmails({});
    const updatePaymentPrincipal = useUpdatePaymentPrincipal({});
    const updatePaymentPlanPrincipal = useUpdatePaymentPlanPrincipal({});
    const principalsOptions = useMemo(() => {
      if (emailsOptions.data) {
        return emailsOptions.data.concat(["bounce", "none"]);
      }
    }, [emailsOptions]);
    const onSubmit = async () => {
      if (activityType === InteractionType.PAYMENT_SUCCESS) {
        await updatePaymentPrincipal.mutateAsync({
          userId,
          accountId,
          principal,
          paymentId: activityId,
        });
      } else if (activityType === InteractionType.PAYMENT_PLAN_SELECTED) {
        await updatePaymentPlanPrincipal.mutateAsync({
          userId,
          accountId,
          principal,
          paymentPlanId: activityId,
        });
      }
      modal.resolve(true);
      modal.hide();
    };

    return (
      <Dialog title={"Assign new principal"} onClick={onSubmit} buttonText={"Submit"}>
        <FormControl style={{width: "100%"}}>
          {principalsOptions && (
            <Select value={principal} onChange={(event: any) => setPrincipal(event.target.value)}>
              {principalsOptions.map((principal) => {
                const label = getNameFromEmail(principal);
                return (
                  <MenuItem key={principal} value={principal}>
                    {InputUtils.capitalizeString(label)}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </FormControl>
      </Dialog>
    );
  }
);

export default AssignPrincipalDialog;
