import {Theme} from "@mui/material/styles";
import {Components} from "@mui/material/styles/components";

// ----------------------------------------------------------------------

export default function Progress(theme: Theme): Components {
  const isLight = theme.palette.mode === "light";

  return {
    MuiCircularProgress: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          overflow: "hidden",
        },
        bar: {
          borderRadius: 4,
        },
        colorPrimary: {
          backgroundColor: theme.palette.primary[isLight ? "lighter" : "darker"],
        },
        buffer: {
          backgroundColor: "transparent",
        },
      },
    },
  };
}
