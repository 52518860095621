import * as React from "react";
import {BounceLogo} from "../../bounce-ui/consumer";
import {Box, Drawer, List, Toolbar} from "../../bounce-ui/backoffice";
import {Colors} from "../../bounce-ui/colors";
import {CategoryListItem, CategoryNestListItem} from "./components";
import {map} from "lodash";
import {MenuListItem} from "./types";
import {FC, ReactElement, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-use";

const drawerWidth = 280;

const getCurrentPage = (pathname?: string) => {
  if (!pathname) return "";

  const pathParts = pathname?.split("/");
  if (!pathParts) return "";

  return pathParts[pathParts.length - 1];
};

type PermanentMenuProps = {menuItems: MenuListItem; logo: ReactElement; homeURL: string};

const PermanentMenu: FC<PermanentMenuProps> = ({menuItems, logo, homeURL}) => {
  const [selected, setSelected] = useState("");
  const {pathname} = useLocation();

  useEffect(() => {
    const currentPage = getCurrentPage(pathname);
    setSelected(currentPage);
  }, [pathname]);

  const navigate = useNavigate();
  return (
    <Box sx={{display: "flex"}}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: Colors.primary._1000,
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            borderRight: `1px solid ${Colors.primary._1000}`,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box onClick={() => navigate(homeURL)} sx={{cursor: "pointer"}}>
          {logo}
        </Box>
        <List sx={{flexGrow: 1, padding: "0"}}>
          {map(menuItems, (item) => {
            if (item.type === "category-item") {
              return <CategoryListItem key={item.id} item={item} selected={selected} setSelected={setSelected} />;
            }
            if (item.type === "category-nest-item") {
              return <CategoryNestListItem key={item.id} item={item} selected={selected} setSelected={setSelected} />;
            }
          })}
        </List>
        <Toolbar sx={{justifyContent: "center"}}>
          <BounceLogo style={{width: "200px", height: "40px"}} />
        </Toolbar>
      </Drawer>
    </Box>
  );
};

export default PermanentMenu;
