import React, {ReactNode} from "react";
import {TextField as MuiTextField, TextFieldProps, Theme} from "@mui/material";
import {SxProps} from "@mui/system";

export type TextFieldType = TextFieldProps & {
  children?: ReactNode;
  InputSx?: SxProps<Theme>;
};

export const TextField = ({label, InputProps, children, InputSx, sx, ...props}: TextFieldType) => {
  return (
    <MuiTextField
      label={label}
      InputProps={{
        ...InputProps,
        sx: {
          alignItems: "start",
          borderRadius: "8px",
          ...InputSx,
        },
      }}
      sx={{zIndex: 0, ...sx}}
      {...props}
    >
      {children}
    </MuiTextField>
  );
};
