import {Email} from "../declerations/server";
import {useGetUserWithSelectedAccountQuery} from "../apis/requests/backoffice";
import {filter, map} from "lodash";
import {getDefaultEmail} from "../pages/backoffice/information-panel/utils";

interface IGetEmailsForSendArbitraryEmailDialog {
  userId: string;
}
export const useGetEmailsForSendArbitraryEmailDialog = ({userId}: IGetEmailsForSendArbitraryEmailDialog) => {
  const {data: user} = useGetUserWithSelectedAccountQuery({userId}, {enabled: !!userId});
  const isRepresented = user?.represented && user.account.representation_status === "enrolled";
  const repEmail = isRepresented && user?.representation?.email ? [user?.representation.email] : [];
  const filteredEmails = filter(user?.emails, (e: Email) => e.status === "VALID" && !e.blocked && !e.unsubscribe);
  const emailsToDialog = isRepresented ? repEmail : map(filteredEmails, (e: Email) => e.email);
  const defaultEmail = isRepresented ? user?.representation.email : getDefaultEmail(filteredEmails);
  return {emailsToDialog, defaultEmail};
};
