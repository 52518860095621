import {FileRejection} from "react-dropzone";
import _ from "lodash";
import {CustomFile} from "../components/upload";

export const getFilesRejectedMessageKey = (fileRejections?: FileRejection | FileRejection[]): string => {
  if (!fileRejections) {
    return "invalidFieldUpload";
  }

  if (_.isArray(fileRejections) && _.isEmpty(fileRejections)) {
    return "invalidFieldUpload";
  }

  const fileRejection: FileRejection = _.isArray(fileRejections) ? fileRejections[0] : fileRejections;
  const {errors} = fileRejection;

  if (!!errors?.length) {
    const error = errors[0];
    const {code} = error;

    switch (code) {
      case "file-too-large":
        return "invalidFieldUploadTooLarge";
      case "file-too-small":
        return "invalidFieldUploadTooSmall";
      case "too-many-files":
        return "invalidFieldUploadTooManyFiles";
      case "file-invalid-type":
        return "invalidFieldUploadInvalidType";
      default:
        return "invalidFieldUpload";
    }
  }

  return "invalidFieldUpload";
};

export const getFileSizeString = (fileSize?: number): string => {
  return `${Math.ceil((fileSize || 0) / 1000)}KB`;
};

export default function getFileData(file: CustomFile | string, index?: number) {
  if (typeof file === "string") {
    return {
      key: index ? `${file}-${index}` : file,
      preview: file,
    };
  }

  return {
    key: index ? `${file.name}-${index}` : file.name,
    name: file.name,
    size: file.size,
    path: file.path,
    type: file.type,
    preview: file.preview,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
  };
}
