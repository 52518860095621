const STATUTE_OF_LIMITATIONS = {
  default:
    "It is our understanding that this account is past the statute of limitations.The law limits how long you can be sued for a debt. " +
    "Because of the age of this debt, we will not sue you for it. Even so, you may choose to make payments on the debt. " +
    "However, be aware: if you make a payment on the debt, admit to owing the debt, promise to pay the debt, or waive the statute of limitations on the debt, the time period in which the debt is enforceable in court may start again.",
  massachusetts:
    "  WE ARE REQUIRED BY REGULATION OF THE MASSACHUSETTS ATTORNEY GENERAL TO NOTIFY YOU OF THE FOLLOWING INFORMATION.  THIS INFORMATION IS NOT LEGAL ADVICE.  THIS DEBT MAY BE TOO OLD FOR YOU TO BE SUED ON IT IN COURT.  IF IT IS TOO OLD, YOU CANNOT BE REQUIRED TO PAY IT THROUGH A LAWSUIT.  TAKE NOTE: YOU CAN RENEW THE DEBT AND THE STATUTE OF LIMITATIONS FOR THE FILING OF A LAWSUIT AGAINST YOU IF YOU DO ANY OF THE FOLLOWING: MAKE ANY PAYMENT ON THE DEBT; SIGN A PAPER IN WHICH YOU ADMIT THAT YOU OWE THE DEBT OR IN WHICH YOU MAKE A NEW PROMISE TO PAY; SIGN A PAPER IN WHICH YOU GIVE UP OR WAVE YOUR RIGHT TO STOP THE CREDITOR FROM SUING YOU IN COURT TO COLLECT THE DEBT.  WHILE THIS DEBT MAY NOT BE ENFORCEABLE THROUGH A LAWSUIT, IT MAY STILL AFFECT YOUR ABILITY TO OBTAIN CREDIT OR AFFECT YOUR CREDIT SCORE OR RATING.",
  "new mexico":
    "We are required by New Mexico Attorney General Rule to notify you of the following information. This information is not legal advice: " +
    "This debt may be too old for you to be sued on it in court.  If it is too old, you can't be required to pay it through a lawsuit. " +
    'You can renew the debt and start the time for the filing of a lawsuit against you to collect the debt if you do any of the following: make any payment of the debt; sign a paper in which you admit that you owe the debt or in which you make a new promise to pay; sign a paper in which you give up ("waive") your right to stop the debt collector from suing you in court to collect the debt.',
  "new york":
    "WE ARE REQUIRED BY LAW TO GIVE YOU THE FOLLOWING INFORMATION ABOUT THIS DEBT.  The legal time limit (statute of limitations) for suing you to collect this debt has expired.  However, if somebody sues you anyway to try to make you pay this debt, court rules REQUIRE YOU to tell the court that the statute of limitations has expired to prevent the creditor from obtaining a judgment.  Even though the statute of limitations has expired, you may CHOOSE to make payments. However, BE AWARE, if you make a payment, the creditor's right to sue you to make you pay the entire debt may START AGAIN.",
  texas:
    "THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT, WE WILL NOT SUE YOU FOR IT, AND WE WILL NOT REPORT IT TO ANY CREDIT REPORTING AGENCY.  THIS NOTICE IS REQUIRED BY LAW.",
  "west virginia":
    "The law limits how long you can be sued on a debt.  Because of the age of your debt, we cannot sue you for it and we will not report it to any credit reporting agencies.",
};

const CALIFORNIA_DISCLOSURES =
  "CALIFORNIA RESIDENTS\n" +
  "The California Consumer Privacy Act of 2018, as amended by the California Privacy Rights Act of 2020, (collectively, the “CPRA”) provides California residents with specific rights regarding their Personal Information.\n" +
  "This section describes how you can exercise those rights and our process for handling your requests.\n" +
  "Right to Know and Access Your Information\n" +
  "You have the right to request that we disclose certain information to you about our collection and use of your Personal Information. Specifically, you have the right to request the following:\n" +
  "- the categories of Personal Information that we collected about you;\n" +
  "- the categories of sources of that Personal Information;\n" +
  "- the specific pieces of Personal Information we have collected about you;\n" +
  "- our business or commercial purpose or purposes for collecting, selling or sharing that Personal Information;\n" +
  "- the categories of third parties with whom we disclose that Personal Information;\n" +
  "- the categories of Personal Information about you which we have sold or shared and the categories of third parties to whom the Personal Information was sold or shared, by category or categories of Personal Information for each category of third party recipients;\n" +
  "- the categories of Personal Information about you that we disclosed for a business purpose and the categories of persons to whom it was disclosed for a business purpose; and\n" +
  "- any financial incentive offered by us for the collection, sale or deletion of your Personal Information.\n" +
  "Right to Request Deletion of Personal Information\n" +
  "If you are a California resident, you have the right to request that Bounce AI delete all or a part of the Personal Information that we collected from you and hold in relation to you, subject to some exceptions.\n" +
  "Upon receipt of a deletion request under the CPRA, Bounce AI will review its records to determine whether we or our vendors or agents hold any applicable Personal Information about you, and whether such information is within the scope under the CPRA. We will notify you whether your requested information has been deleted.\n" +
  "We may not agree to delete your Personal Information if it is necessary for us or our service providers to maintain the information for any of the following purposes:\n" +
  "-to complete the transaction for which the Personal Information was collected, provide a good or service requested by you, or reasonably anticipated by the consumer within the context of our ongoing business relationship with you, or otherwise perform a contract between Bounce AI and you; \n" +
  "-to help ensure security and integrity to the extent the use of your Personal Information is reasonably necessary and proportionate for those purposes; \n" +
  "-to debug Bounce AI Service and to identify and repair errors that impair existing intended functionality; \n" +
  "-to exercise free speech, ensure the right of another customer to exercise that customer's right of free speech or exercise another right provided for by law; \n" +
  "-to comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.); \n" +
  "-to engage in public or peer-reviewed scientific, historical, or statistical research that conforms or adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the ability to complete such research, if you previously provided informed consent; \n" +
  "-to enable solely internal uses that are reasonably aligned with your expectations based on your relationship with Bounce AI and compatible with the context in which you provided the information; and \n" +
  "-to comply with a legal obligation\n" +
  "\n" +
  "If we do not agree to delete your Personal Information, we will advise you of the reasons why it has not been deleted and any rights of appeal that you may have. We will generally do this within 45 calendar days of your verifiable request.\n" +
  "\n" +
  "If you have not provided sufficient information for us to verify your identity, however we have been able to reasonably identify you, we will treat your request to delete your Personal Information as a request to opt-out of the sale or sharing of your Personal Information.\n" +
  "\n" +
  "Right to Opt-Out of the Sale or Sharing of Personal Information\n" +
  "\n" +
  "Bounce AI does not currently sell Personal Information for monetary consideration. However, the collection of data through certain third-party cookies for analytics and targeting advertising purposes may be considered a “sale” and is considered “sharing” under the CPRA. While we do not sell your Personal Information for monetary consideration, we do use third party cookies for analytics and targeted advertising purposes. For example, we use Google Analytics to collect information about your usage of the Site.\n" +
  "\n" +
  "You may prevent your data from being collected and used by Google Analytics by opting out through the Google Analytics Opt-out Browser Add-on. To opt-out of having your information sold and shared with third-party website analytics and digital advertising service providers for this purpose, visit our “Do Not Sell or Share My Personal Information” web page.\n" +
  "\n" +
  "Bounce AI does not have actual knowledge that it sells or shares Personal Information about minors under the age of eighteen (18).\n" +
  "\n" +
  "Right to Correct Inaccurate Personal Information\n" +
  "You have the right to request that we correct inaccurate Personal Information about you, taking into account the nature of the Personal Information and the purposes of the processing of the Personal Information.\n" +
  "Limiting the Use and Disclosure of Sensitive Personal Information  Request Rights\n" +
  "You have the right to direct us to limit our use of your Sensitive Personal Information (1) to that use which is necessary to perform the services or provide the goods reasonably expected by an average consumer who requests those goods or services; (2) for certain business purposes; and (3) as authorized by the implementing regulations of the CPRA . We do not use or disclose your Sensitive Personal Information for purposes other than the aforementioned purposes .\n" +
  "Financial Incentives\n" +
  "Bounce AI does not offer any incentives, including payment, to customers as compensation for the collection of Personal Information, the sale of Personal Information or the deletion of Personal Information.\n" +
  "Non-Discrimination\n" +
  "California residents have the right to be free from discrimination based on their exercise for their rights pursuant to the CPRA. Bounce AI will not discriminate against you for exercising your rights under the CPRA, including by charging, or suggesting that it may charge, you a different price or provide, or suggest that it may provide, different quality of goods or services due to you exercising, having exercised or intending to exercise your rights under the CPRA.\n" +
  "Authorized Agents\n" +
  "A California resident may appoint an individual or company to act as your authorized agent to exercise any of their rights under the CPRA. For us to be able to interact with this authorized agent, we will require the appropriate authority, as detailed in this Notice. In addition to the providing the appropriate authority, your agent will need to verify your identity as outlined in this Notice.\n" +
  "Contact and More information\n" +
  "For more information about this Notice, you may contact us directly at the information provided below.\n" +
  "If you are a California resident, you may exercise your rights to request access, data portability, deletion, opt-out, and correction of Personal Information, or to request a limitation on the use and/or disclosure of your Sensitive Personal Information under the CPRA by:\n" +
  "- By Website: https://www.finbounce.com/ \n" +
  "-Emailing us at: compliance@hello.finbounce.com \n" +
  "-Calling us on: (888) 482-5060; or \n" +
  "-Writing to us at: Bounce AI Inc; 3349 Monroe Ave., #331, Pittsford, NY 14618. \n" +
  "Your verifiable request must describe your request with sufficient detail to allow us to properly understand, evaluate and respond to it.\n" +
  "To meet our obligations to protect your privacy, we will need to verify your identity before complying with your requests to know and access, delete and correct Personal Information. The information we ask you to provide to initiate a request may differ depending upon the type of request, the type, sensitivity and value of the Personal Information that is the subject of the request, and the risk of harm to you that may occur as a result of unauthorized access or deletion, among other factors. We reserve the right to deny your request if we cannot verify your identity or as permitted under the CPRA. Where we deny your request in whole or in part, we will endeavor to inform you of the denial and provide an explanation of our actions and the reasons for the denial.\n" +
  "Only you or your authorized agent may make a verifiable consumer request related to your Personal Information. You may also make a verifiable consumer request on behalf of your minor child. If you are a third party making a request on behalf of a California resident, you will be required to provide sufficient authority, executed by the individual to whom the request relates unless we already hold an effective authority on our files.\n" +
  "Bounce AI will not use the information that you provide when making a request under the CPRA for any other purpose, other than confirming your identity and processing and responding to your CPRA request. However, we may be required to provide this if compelled by law.\n" +
  "Response Timing\n" +
  "Bounce AI will generally respond to a verifiable consumer request within forty-five (45) calendar days of receipt. If we require additional time to respond to your verifiable consumer request or if we are unable to comply with your request, we will inform you in writing as to the reason and the duration of the extension period (up to ninety (90) days).\n";
const ACTIVE_DISPUTE =
  "Your dispute was successfully filed and is under investigation. We'll update you soon. If you have any questions or concerns, contact us or call at (888) 482-5060.";

const FRAUD =
  "This account is being investigated for possible fraudulent behavior.\n" +
  "Once the investigation is completed, we will respond with further information.\n" +
  "If you have any questions or concerns, please contact us at Tel. 818.503.1961, or by using the 'Contact Us' button below.";
const PAYMENT_TERMS_AND_CONDITIONS =
  "By making your payment by way of this web site, you consent to engage in transactions and enter into payment plans electronically, and receive the following communications electronically: confirmation of payment plans, payment reminder notices, and receipts of payment, sent to the e-mail address and/or cellular phone number you have previously registered with us.\n\n" +
  "Your consent remains in effect until you give us notice that you are withdrawing it. You also agree that Bounce AI does not need to provide you with an additional paper (non-electronic) copy of the communications disclosed herein.\n\n" +
  "You may request a paper copy of communications that we have previously sent electronically at no charge, provided that such request is made within a reasonable time after we first sent the communication to you. To request a paper copy, you must send an email to compliance@finbounce.com with the subject “Paper Document Request” and in the body of the email state your email address, full name, home mailing address, telephone number, as well as which previously sent communication you are requesting in paper copy. Requesting a paper copy does not withdraw your consent to receive electronic communications.\n\n" +
  "You may withdraw your consent to receive future electronic communications by sending an email to compliance@finbounce.com with the subject “Withdraw Electronic Consent” and in the body of the email state your email address, full name, home mailing address, telephone number. Your withdrawal of consent will be effective only after we’ve had a reasonable period of time to process your request.\n\n" +
  "To ensure you receive all communications in a timely manner, you agree to promptly notify us of any change in your email address, cellular phone number, or home mailing address. You can update this information online by logging into your account at app.finbounce.com or by calling us at 888-482-5060.\n\n" +
  "In order to access and retain our electronic communications, you must have hardware that can access the internet with related software capable of web browsing. You must also have an email account and/or cellular phone account with text messaging services. By accessing this webpage, you have demonstrated that you have the ability to access and read our electronic communications.\n\n" +
  "\n" +
  "Our commitment to you:\n" +
  "- Bounce AI will arrange for funds to be debited from your designated account in accordance with your agreed payment plan.\n" +
  "- If your scheduled direct debit payment date falls on a non-business day, the debit will be made on the next business day.\n" +
  "- If a direct debit is returned unpaid, we will re-attempt the direct debit and contact you seeking your instructions before canceling your payment plan.\n" +
  "- We will not disclose any of your personal information to any third party unless required to do so by law or unless the information is required in relation to disputed direct debits.\n" +
  "\n" +
  "Your commitment to us:\n" +
  "- The account details you have provided are correct and your financial institution and your designated account can accept direct debits.\n" +
  "- You will check your designated financial institution account statement to verify that the amounts debited are correct.\n" +
  "- Your designated account will have sufficient cleared funds available to be debited. Where a direct debit is returned unpaid due to insufficient funds, you agree to bear any fees or charges for the unsuccessful debit.\n" +
  "- You will give us at least three (3) business days notice if you need to defer, change, or cancel a direct debit payment.\n" +
  "- If you have any questions or concerns, you will contact us at 888-482-5060 in the first instance.\n" +
  "\n" +
  "Bounce AI collects personal information about you in order to perform services in connection with your account. This information typically includes your name, contact details, date of birth, occupation and certain financial information. This information is collected from various sources including you, our clients and credit reporting agencies. Access to your personal and account information is restricted to employees and service providers who need to know that information. We do not disclose any nonpublic personal information about consumers to anyone, except as permitted or required by law. Any personal information that is received or used for purposes of collecting a debt is subject to the Fair Debt Collection Practices Act and is communicated only in accordance with that Act. Bounce AI may share collected information with third parties if otherwise permitted by law, exclusive to your attorney or other authorized representative, your spouse, a consumer reporting agency, the creditor, and our attorney. We are committed to protecting and maintaining the privacy, accuracy and security of your personal information. We maintain physical, electronic, and procedural safeguards to protect the privacy of your personal and account information. This includes, but is not limited to, restricted access, encryption and password protection, as well as various authentication procedures. Please note that a TTY (Text Telephone) Relay Service is available to assist consumers that have a hearing or speech impairment. In order to communicate with our office through the relay service, please call the relay operator at 800-421-1220.\n";
const CREDITOR_RECALLED =
  "Your account with Bounce AI has been closed and returned to the original creditor.\n" +
  "For any further information or inquiries, please contact the original creditor:\n" +
  "800.910.8180 | 818.503.1322";

const BANKRUPTCY =
  "Our understanding is that this account is involved in active bankruptcy proceedings.\n" +
  "If you have any questions or concerns, please contact us at Tel. 818.503.1961, or by using the 'Contact Us' button below.";

const ACCOUNT_MANAGED_BY_JANUARY =
  "Your account is managed by January, please refer to their customer portal to view your options on https://borrower.january.com/login";
const getExternalCollectorDisclaimer = (externalCollector: string) =>
  `Your account is managed by ${externalCollector}, please refer to their customer portal to view your options`;

export {
  STATUTE_OF_LIMITATIONS,
  CALIFORNIA_DISCLOSURES,
  ACTIVE_DISPUTE,
  FRAUD,
  PAYMENT_TERMS_AND_CONDITIONS,
  CREDITOR_RECALLED,
  BANKRUPTCY,
  ACCOUNT_MANAGED_BY_JANUARY,
  getExternalCollectorDisclaimer,
};
