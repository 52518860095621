import React from "react";
import {Radio as MuiRadio, RadioProps} from "@mui/material";
import {Colors} from "../colors";
export {RadioGroup, FormControlLabel} from "@mui/material";

export type RadioType = RadioProps & {};

const mapCircleSizeStyles = {
  small: 20,
  medium: 24,
};

export const Radio = ({size = "small", color = "primary", sx, ...props}: RadioType) => {
  return (
    <MuiRadio
      color={color}
      sx={{
        color: Colors.grey._1000,
        svg: {
          fontSize: mapCircleSizeStyles[size],
        },
        ...sx,
      }}
      {...props}
    />
  );
};
