import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";

type Variables = {
  accountId: string;
};

type Data = string;

export const getInterimAvailable = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>(`/is-interim-available/${variables.accountId}`);
  return result.data;
};

export const useGetInterimAvailableQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> =>
  useBaseQuery<Data, Variables>(
    ["account", "interim_allowed", variables.accountId],
    getInterimAvailable,
    {...variables},
    options
  );
