import React, {FunctionComponent, useCallback, useEffect} from "react";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {Container, ImgReliefPlan, Text, Dialog} from "../../../../bounce-ui/consumer";
import {useAmplitude} from "../../../../contexts";
import CancelPaymentPlanDialog from "./CancelPaymentPlanDialog";
import {Pages} from "../../../../bi-events/enum";
import {useWebchat} from "../../../../components/webchat/components/useWebchat";

export type DelayPaymentDialogResult = "delayed" | "cancelPaymentPlan" | "none";

interface IPreCancelPlanDialog {
  accountId: string;
  activePaymentPlanId: string;
}

const PreCancelPlanDialog: FunctionComponent<IPreCancelPlanDialog & NiceModalHocProps> = NiceModal.create(
  ({accountId, activePaymentPlanId}) => {
    const {pageViewEvent, pageClickEvent} = useAmplitude();
    const {openChat} = useWebchat();

    const handleClickProceed = () => {
      pageClickEvent({page: Pages.CANCEL_PAYMENT_INTRO, cta: "talk_to_expert"});
      openChat({});
    };

    const openCancelPlanDialog = useCallback(async (): Promise<void> => {
      pageClickEvent({page: Pages.CANCEL_PAYMENT_INTRO, cta: "cancel_plan"});
      await NiceModal.show<boolean>(CancelPaymentPlanDialog, {
        accountId: accountId,
        activePaymentPlanId,
      });
    }, [accountId]);

    useEffect(() => {
      pageViewEvent({page: Pages.CANCEL_PAYMENT_INTRO});
    }, []);

    return (
      <Dialog
        image={<ImgReliefPlan />}
        title={"Need time or more affordable options?"}
        buttonText={"Talk to an expert"}
        onClick={handleClickProceed}
        secondaryButtonText={"I still want to cancel"}
        onClickSecondary={openCancelPlanDialog}
      >
        <Container fullWidth sx={{gap: "8px", alignItems: "start", overflow: "auto"}}>
          <Text translateId={"ourTemporaryRelief"} />
          <Text translateId={"notOnlyWillYouBenefit"} />
          <Text translateId={"talkToOneOfOurExpertsToday"} />
        </Container>
      </Dialog>
    );
  }
);

export default PreCancelPlanDialog;
