import _ from "lodash";
import React from "react";
const phoneNumberUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
const phoneNumberFormat = require("google-libphonenumber").PhoneNumberFormat;

const FormValidations = {
  validateTelephone: function (
    telephonePrefix: string | null | undefined,
    telephone: string | null | undefined
  ): string | undefined {
    if (!telephone) {
      return undefined;
    }
    try {
      const phone = phoneNumberUtil.parse(telephone, "US");
      if (!phoneNumberUtil.isValidNumber(phone)) {
        return "phone invalid";
      }
    } catch (e) {
      return "phone invalid";
    }
    return undefined;
  },

  convertInternationalIntoUSFormat: function (number: string | undefined): string | undefined {
    if (!number) return;
    const parsedNumber = phoneNumberUtil.parse(number, "US");
    return phoneNumberUtil.format(parsedNumber, phoneNumberFormat.NATIONAL);
  },

  validateRequired: function (value: any): string | undefined {
    if (!value) {
      return "required field";
    }
    if (_.isArray(value) && _.isEmpty(value)) {
      return "required field";
    }
    return undefined;
  },

  inputRemoveWhitespaces: function (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const value = e.target.value;
    e.target.value = value.replace(/\s/g, "");
  },

  inputRemoveNonDigits: function (e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    e.target.value = value.replace(/[^0-9]/gi, "");
  },
};

export default FormValidations;

export const EMAIL_MAX_LENGTH = 50;
export const PASSWORD_MAX_LENGTH = 50;
