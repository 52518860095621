import React, {FunctionComponent, PropsWithChildren} from "react";
import {Colors, Container, LabeledText, Text} from "../../../../bounce-ui/consumer";
import {PaymentType} from "../../../../declerations/enums";

interface OneCentAuthorizationLabelProps extends PropsWithChildren<any> {
  type: PaymentType;
}

const OneCentAuthorizationLabel: FunctionComponent<OneCentAuthorizationLabelProps> = ({
  type,
}: OneCentAuthorizationLabelProps) => {
  return (
    <LabeledText sx={{backgroundColor: Colors.grey._100}}>
      <Container sx={{alignItems: "start", padding: "16px 20px"}}>
        <Text fontWeight={"bold"}>Card Verification Required</Text>
        {type === "CreditCard" ? (
          <Text>
            Please be aware that you may see a $0.01 transaction hit your account to validate that your credit card
            information is correct. If successful, the $0.01 transaction will be voided and your payment plan will
            proceed as agreed.
          </Text>
        ) : (
          <Text>
            Please be aware that you may see a $0.01 transaction hit your account to validate that your debit card
            information is correct. If successful, the $0.01 transaction will be voided and your payment plan will
            proceed as agreed."
          </Text>
        )}
      </Container>
    </LabeledText>
  );
};
export default OneCentAuthorizationLabel;
