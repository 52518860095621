import React from "react";
import {Divider as MuiDivider, DividerProps} from "@mui/material";
import {Colors} from "../colors";

export type DividerType = DividerProps & {
  fullWidth?: boolean;
};
export const Divider = ({fullWidth, sx, ...props}: DividerType) => {
  return (
    <MuiDivider
      sx={{
        ...(fullWidth && {width: "100%"}),
        backgroundColor: Colors.grey._200,
        ...sx,
      }}
      {...props}
    />
  );
};
