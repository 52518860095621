import dayjs from "dayjs";
import {TIMESTAMP_FORMAT} from "../pages/backoffice/information-panel/components/dialogs/common";

export const getBasicDate = (timestamp: number | undefined) => {
  if (!timestamp) return "N/A";
  const date = new Date(timestamp * 1000);
  return Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};

export const dateISOString = (timestamp: number | undefined) => {
  let d = new Date();
  if (timestamp) {
    d = new Date(timestamp * 1000);
  }

  return d.toISOString();
};

export const extractDateFromString = (stringDate?: string, fullDate: boolean = false) => {
  if (!stringDate) return "N/A";
  const d = new Date(stringDate);
  const formattedDate = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
  return fullDate
    ? `${formattedDate}  ${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}:${d
        .getSeconds()
        .toString()
        .padStart(2, "0")}`
    : formattedDate;
};

export const extractUTCDateFromString = (stringDate?: string, format?: string) => {
  if (!stringDate) return "";
  const date = new Date(stringDate + "Z");
  const options = {
    timeZone: "America/New_York",
    timeZoneName: "short",
  };

  // @ts-ignore
  const estTime = date.toLocaleTimeString("en-US", options);
  // @ts-ignore
  const estDate = date.toLocaleDateString("en-US", options);
  const [hour, ampm] = estTime.split(" ");
  const [day] = estDate.split(" ");
  const dateStr = day + " " + hour + " " + ampm;
  return dayjs(dateStr).format(format || TIMESTAMP_FORMAT);
};
