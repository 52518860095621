import React, {FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {useLocalStorageState} from "../hooks/useLocalStorageState";
import {useUser} from "./UserContext";
import {UiSettings} from "../declerations/server";
import {useGetUiSettings} from "../apis/requests/user/getUiSettings";
import {useFavicon} from "react-use";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {isSpring} from "../layout/spring/helpers";

const DEFAULT_FAV = "https://bounce-web.s3.us-east-2.amazonaws.com/images/favicon-bounce.ico";
const SPRING_FAV = "https://bounce-web.s3.us-east-2.amazonaws.com/images/spring-favicon.png";
const BO_FAV = "https://bounce-web.s3.us-east-2.amazonaws.com/templates/rebranding/backoffice-favicon.png";

export interface UiContextProps {
  isLoading: boolean;
  uiSettings?: UiSettings;
  showSplashScreen: boolean;
  setShowSplashScreen: (value: boolean) => void;
  animatedConfettiJson: string | null;
  fetchAnimationJson: ({url, setAnimation}: {url: string; setAnimation: any}) => void;
}

type UserUiSettings = UiSettings & {
  userId: string;
  time: number;
};

const UiContext = React.createContext<UiContextProps>(undefined!);

interface IProps extends PropsWithChildren<any> {}
const animatedConfettiUrl = "https://bounce-web.s3.us-east-2.amazonaws.com/animations/animated-confetti.json";

const fetchAnimationJson = async ({url, setAnimation}: {url: string; setAnimation: any}) => {
  try {
    const res = await axios.get(url);
    setAnimation(res.data);
  } catch (e) {}
};

const UiProvider: FunctionComponent<IProps> = ({children}) => {
  const [uiSettings, setUiSettings] = useLocalStorageState<UserUiSettings | undefined>("uiSettings", undefined, {
    encrypted: true,
  });
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true);
  const [animatedConfettiJson, setAnimatedConfettiJson] = useState(null);

  const {userId} = useUser();

  const {search, pathname} = useLocation();
  const queryParams = new URLSearchParams(search);

  const urlUserId = queryParams.get("id") || undefined;

  const aggregatedUserId = useMemo<string | undefined>(() => userId || urlUserId, [userId, urlUserId]);

  const isLoading = useMemo<boolean>(() => !uiSettings && !!aggregatedUserId, [uiSettings, aggregatedUserId]);

  const uiSettingsState = useGetUiSettings();

  useEffect(() => {
    const now = new Date().getTime();
    const uiTime = uiSettings?.time || 0;
    const uiUserId = uiSettings?.userId;

    if (aggregatedUserId && (aggregatedUserId !== uiUserId || now - uiTime > 600_000)) {
      (async () => {
        try {
          const uiSettings = await uiSettingsState.mutateAsync({user_id: aggregatedUserId});
          setUiSettings({...uiSettings, userId: aggregatedUserId, time: now});
        } catch (e) {}
      })();
    }
  }, [aggregatedUserId]);

  useEffect(() => {
    fetchAnimationJson({url: animatedConfettiUrl, setAnimation: setAnimatedConfettiJson});
  }, []);

  const faviconURL = useMemo(() => {
    if (pathname.includes("backoffice")) {
      return BO_FAV;
    }
    if (isSpring()) {
      return SPRING_FAV;
    } else {
      return DEFAULT_FAV;
    }
  }, [pathname]);

  useFavicon(faviconURL);
  return (
    <UiContext.Provider
      value={{
        isLoading,
        uiSettings,
        showSplashScreen,
        setShowSplashScreen,
        animatedConfettiJson,
        fetchAnimationJson,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

const useUi = (): UiContextProps => {
  const context = useContext(UiContext);

  if (!context) throw new Error("UiContext must be used inside UiProvider");

  return context;
};

export {UiContext, UiProvider, useUi};
