import React from "react";
import {Colors, Container, LabeledText, Text} from "../../../../bounce-ui/consumer";

const LargePaymentSplitLabel = () => {
  return (
    <LabeledText sx={{backgroundColor: Colors.grey._100}}>
      <Container sx={{alignItems: "start", padding: "16px 20px"}}>
        <Text fontWeight={"bold"}>Payment Split Is Required</Text>
        <Text>
          Due to system limitations, payments of $10,000 or more may be split into multiple consecutive payments which
          sum to the agreed amount.
        </Text>
      </Container>
    </LabeledText>
  );
};
export default LargePaymentSplitLabel;
