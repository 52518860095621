import React, {FunctionComponent} from "react";
import {IntlProvider} from "react-intl";
import ServerApiManager, {queryClient} from "./apis/ServerApiManager";
import TitleManager from "./managers/TitleManager";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ThemeConfig from "./theme";
import RtlLayout from "./components/common/RtlLayout";
import Router from "./routes/routes";
import {BrowserRouter} from "react-router-dom";
import NotistackProvider from "./components/notifications/NotistackProvider";
import GlobalStyles from "./theme/globalStyles";
import {ProgressBarStyle} from "./components/common/LoadingScreen";
import NiceModal from "@ebay/nice-modal-react";
import {QueryClientProvider} from "@tanstack/react-query";
import {UserContext, UserProvider, TaskProvider, AmplitudeProvider, UiProvider} from "./contexts";
import ServerApiExceptionManager from "./apis/ServerApiExceptionManager";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {SentryProvider} from "./contexts";
import WebchatWidgetWrapper from "./components/webchat/components/WebchatWidgetWrapper";
import {store} from "./components/webchat/store/store";
import {Provider} from "react-redux";
import {ActivityEventProvider} from "./contexts/ActivityEventContext";
import {WeglotProvider} from "./contexts/WeglotContext";

const App: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <WeglotProvider>
            <Provider store={store}>
              <UserContext.Consumer>
                {({localeInfo}) => (
                  <>
                    <TaskProvider>
                      <ServerApiManager />
                      <SentryProvider>
                        <UiProvider>
                          <AmplitudeProvider>
                            <IntlProvider
                              locale={localeInfo.locale}
                              messages={localeInfo.messages}
                              onError={(err) => {
                                if (err.code === "MISSING_TRANSLATION") {
                                  console.warn("Missing translation", err.message);
                                  return;
                                }
                                throw err;
                              }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={localeInfo.dateLocalization}
                              >
                                <ActivityEventProvider>
                                  <ThemeConfig
                                    isRtl={localeInfo.direction === "rtl"}
                                    localization={localeInfo.materialUiLocalization}
                                  >
                                    <WebchatWidgetWrapper />
                                    <RtlLayout>
                                      <NotistackProvider>
                                        <GlobalStyles />
                                        <ProgressBarStyle />
                                        <TitleManager />
                                        <ServerApiExceptionManager />
                                        <NiceModal.Provider>
                                          <Router />
                                        </NiceModal.Provider>
                                      </NotistackProvider>
                                    </RtlLayout>
                                  </ThemeConfig>
                                </ActivityEventProvider>
                              </LocalizationProvider>
                            </IntlProvider>
                          </AmplitudeProvider>
                        </UiProvider>
                      </SentryProvider>

                      <ReactQueryDevtools initialIsOpen={false} />
                    </TaskProvider>
                  </>
                )}
              </UserContext.Consumer>
            </Provider>
          </WeglotProvider>
        </UserProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
