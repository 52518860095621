import React, {FunctionComponent, PropsWithChildren, ReactNode, useEffect, useMemo, useState} from "react";
import {get, noop, uniqueId} from "lodash";
import {useUrlState} from "../../hooks/useUrlState";
import {Card, Colors, Tab, Tabs, Text} from "../../bounce-ui/consumer";
import useIsMobile from "../../helpers/useIsMobile";
import {Box} from "@mui/material";

export interface TabInfo {
  id: string;
  label: string;
  icon?: React.ReactElement;
  lazy?: boolean;
  className?: string;
  onClick?: (s?: any) => any;
}

interface TabPanelProps extends PropsWithChildren<any> {
  tabs: TabInfo[];
  defaultTabId?: string;
  tabBadges?: {[index: string]: ReactNode};
  saveActiveTab?: boolean;
  addToHistory?: boolean;
  urlParam?: string;
  onDashboardDialog?: boolean;
}

const getPadding = (onDashboardDialog: boolean, isMobile: boolean) => {
  if (onDashboardDialog) {
    return "0px";
  }
  if (isMobile) {
    return "20px";
  }
  return "44px";
};

const TabPanel: FunctionComponent<TabPanelProps> = ({
  tabs,
  defaultTabId,
  saveActiveTab = true,
  addToHistory = true,
  urlParam,
  onDashboardDialog,
  iconPosition = "start",
  children,
}: TabPanelProps) => {
  const isMobile = useIsMobile();
  const activeTabId = useMemo<string>(() => uniqueId("activeTabId"), []);
  const activeTabParam = useMemo<string>(
    () => (saveActiveTab ? urlParam || "tab" : activeTabId),
    [saveActiveTab, urlParam]
  );

  const [activeTab, setActiveTab] = useUrlState<string>(activeTabParam, defaultTabId || tabs[0]?.id || "", {
    shouldSaveState: (state) => state !== tabs[0]?.id && saveActiveTab,
    stateParser: (state) => state,
    addToHistory: addToHistory,
  });

  const activeTabIndex = useMemo<number>(
    () =>
      Math.max(
        0,
        tabs.findIndex((tab) => tab.id === activeTab)
      ),
    [tabs, activeTab]
  );
  const [loadedTabs, setLoadedTabs] = useState<string[]>([]);

  useEffect(() => {
    if (!loadedTabs.includes(activeTab)) {
      setLoadedTabs((loadedTabs) => [...loadedTabs, activeTab]);
    }
  }, [activeTab]);

  const toggleTab = (event: React.SyntheticEvent, tabIndex: number): void => {
    const tab = tabs[tabIndex];
    if (tab && tab.id !== activeTab) {
      setActiveTab(tab.id);
    }
  };

  return (
    <Card
      fullWidth
      sx={{
        gap: "30px",
        padding: getPadding(onDashboardDialog || false, isMobile),
        boxShadow: onDashboardDialog ? "0px" : (theme) => theme.customShadows.cardV2,
      }}
    >
      <Tabs
        value={activeTabIndex}
        onChange={toggleTab}
        scrollButtons={"auto"}
        centered
        TabIndicatorProps={{sx: {height: "5px", backgroundColor: Colors.primary._800}}}
        sx={{width: "100%"}}
      >
        {tabs.map((tab) => {
          return (
            <Tab
              label={<Text fontWeight={"medium"} translateId={tab.label} sx={{textTransform: "capitalize"}} />}
              icon={tab.icon}
              iconPosition={iconPosition}
              disableRipple
              key={tab.id}
              sx={{
                borderBottom: `3px ${Colors.grey._200} solid`,
                width: "50%",
                "& .MuiTab-iconWrapper": {
                  margin: "0px",
                },
              }}
              onClick={() => (tab.onClick ? tab.onClick(setActiveTab) : noop)}
            />
          );
        })}
      </Tabs>
      {React.Children.toArray(children)
        .filter((child) => !!child)
        .map((child: any, index: number) => {
          const tab: TabInfo = get(tabs, index);
          const tabLoaded: boolean = !tab || !tab.lazy || activeTab === tab.id || loadedTabs.includes(tab.id);
          return (
            <Box sx={{display: activeTab === tab.id ? "block" : "none"}} key={index}>
              {tabLoaded && child}
            </Box>
          );
        })}
    </Card>
  );
};
export default TabPanel;
