import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {PaymentDetails} from "../../../declerations/server";
import {IResponseData} from "../../../pages/account/payment/utils";

type Variables = PaymentDetails & {
  abstract_payment_plan_name: string;
  payment_plan_id: string;
  account_id: string;
  discount?: string;
  amount?: string;
  isAgent?: boolean;
};

type Data = IResponseData;

export const payoffPayment = async (variables: Variables): Promise<Data> => {
  const {account_id, isAgent, payment_plan_id, ...data} = variables;
  let result;
  if (isAgent) {
    result = await axiosFlexInstance.post<Data, AxiosResponse<Data>>(`/payments/payoff/${payment_plan_id}`, data);
  } else {
    result = await axiosConsumerAppInstance.post<Data, AxiosResponse<Data>>(
      `/payments/payoff/${payment_plan_id}`,
      data
    );
  }
  return result.data;
};

export const usePayoffPayment = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(payoffPayment, {
    refetchType: "all",
    ...options,
    prefixKeysToInvalidate: (data, variables) => [["user"], ["account", variables.account_id]],
  });
};
