import {IActivityCard} from "./ActivityCard";
import {paymentPlanDetails, UserActivityData} from "./activity_models";
import {Container, Text} from "../../../../../../bounce-ui/backoffice";
import {filter, isEmpty, map} from "lodash";
import InputUtils from "../../../../../../helpers/InputUtils";
import {multiply, prettifyCurrency} from "../../../../../../helpers/currency";

const freqOrder = ["one_time", "weekly", "bi_weekly", "monthly"];

const customSort = (plan_a: any, plan_b: any): number => {
  const freqIndexA = freqOrder.indexOf(plan_a[0]);
  const freqIndexB = freqOrder.indexOf(plan_b[0]);
  return freqIndexA - freqIndexB;
};

const arrangePlansToDisplay = (payment_plan_offers?: Record<string, paymentPlanDetails[]>) => {
  if (isEmpty(payment_plan_offers)) return [];
  const planOffers = filter(
    Object.entries(payment_plan_offers),
    ([frequency, _]) => frequency !== "first_and_fifteenth" && frequency !== "recommended"
  );
  return planOffers.sort(customSort);
};
export const UserActivityItem = ({activity}: IActivityCard) => {
  const {event_type, payment_plan_offers} = activity as UserActivityData;
  if (["USER_VISIT_PAYMENT_PLANS", "USER_VISIT_PAYMENT_PLAN_SUMMARY"].includes(event_type)) {
    const sortedPlanOffers = arrangePlansToDisplay(payment_plan_offers);
    return (
      <Container fullWidth>
        {map(
          sortedPlanOffers,
          ([frequency, payment_plans]: [frequency: string, payment_plans: any[]], index: number) => {
            if (!isEmpty(payment_plans)) {
              return (
                <Container fullWidth sx={{alignItems: "start", gap: 0}} key={`user_activity_item_${index}`}>
                  <Text size={14} fontWeight={"bold"} sx={{textDecoration: "underline"}}>
                    {frequency === "bi_weekly" ? "Bi Weekly / 1st and 15th" : InputUtils.prettierSnakeCase(frequency)}:
                  </Text>
                  {map(payment_plans, (payment_plan, index) => (
                    <Container
                      row
                      sx={{gap: "3px"}}
                      key={`${frequency}-${payment_plan.discount_fraction}-${payment_plan.number_of_installments}`}
                    >
                      <Text size={14} fontWeight={"bold"}>
                        {`Discount: ${multiply(payment_plan.discount_fraction, 100)}%,`}
                      </Text>
                      <Text size={14}>{`${payment_plan.number_of_installments} installments of ${prettifyCurrency(
                        payment_plan.installment_size
                      )}, Total Amount ${prettifyCurrency(payment_plan.total_amount)}`}</Text>
                    </Container>
                  ))}
                </Container>
              );
            }
          }
        )}
      </Container>
    );
  }
  return null;
};
