import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {IInterimLimits} from "../../../pages/account/payment-plans/utils";

type Variables = {
  accountId: string;
};

type Data = IInterimLimits;

export const getInterimLimits = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>(`/interim-limits/${variables.accountId}`);
  return result.data;
};

export const useGetInterimLimitsQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> =>
  useBaseQuery<Data, Variables>(
    ["account", variables.accountId, "interim_limit"],
    getInterimLimits,
    {...variables},
    options
  );
