import React from "react";
import {Text, ITextProps, Colors} from "bounce-ui/backoffice";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";

interface IStyledText {
  value: string;
  size?: ITextProps["size"];
  bold?: boolean;
  link?: boolean;
  nowrap?: boolean;
  sx?: SxProps<Theme>;
}
export const StyledText = ({value, size = 14, bold = false, link = false, sx}: IStyledText) => {
  return (
    <Text size={size} fontWeight={bold ? "bold" : 500} sx={{...(link && {color: Colors.primary._800}), ...sx}}>
      {value}
    </Text>
  );
};
