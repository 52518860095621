import enMessages from "../locales/en_US";
import {LocaleInfo} from "../../declerations/internal";
import {enUS} from "@mui/material/locale";
import enLocale from "date-fns/locale/en-US";

const EnLang: LocaleInfo = {
  id: "en",
  locale: "en-US",
  messages: {
    ...enMessages,
  },
  name: "English",
  shortName: "EN",
  icon: "/static/flags/gb.svg",
  direction: "ltr",
  materialUiLocalization: enUS,
  dateLocalization: enLocale,
};
export default EnLang;
