import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

const useIsMobile = (): boolean => {
  const theme = useTheme();
  try {
    return useMediaQuery(theme.breakpoints.down("sm"));
  } catch (e) {
    console.warn("useMediaQuery in useIsMobile failed to retrieve the data from theme.breakpoints.down");
    return false;
  }
};

export default useIsMobile;
