import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useLocation, useTitle} from "react-use";
import {getUrlInfo} from "../routes/urls";
import {useIntl} from "react-intl";
import {useUi} from "../contexts";

interface TitleManagerProps {}

const TitleManager: FunctionComponent<TitleManagerProps> = () => {
  const intl = useIntl();
  const location = useLocation();
  const {uiSettings} = useUi();

  const [title, setTitle] = useState<string>("");
  useTitle(title);

  const name = useMemo<string>(() => uiSettings?.lender_name || "", [uiSettings]);

  useEffect(() => {
    const pathname = location.pathname;
    if (!pathname) {
      setTitle(name);
      return;
    }

    const urlInfo = getUrlInfo(pathname);
    if (!urlInfo) {
      setTitle(name);
      return;
    }
    if (location.pathname?.includes("backoffice")) {
      setTitle(`Backoffice | ${intl.formatMessage({id: urlInfo.titleKey})}`);
    } else {
      setTitle(`${intl.formatMessage({id: urlInfo.titleKey})} | ${name}`);
    }
  }, [location.pathname, name]);

  return null;
};
export default TitleManager;
