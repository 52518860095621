import {useLocation, useParams} from "react-router-dom";
import {useUser} from "./UserContext";

export const useAccountId = () => {
  const {isAgent} = useUser();
  const params = useParams<{accountId: string}>();
  const {search} = useLocation();
  const queryParams = new URLSearchParams(search);
  if (isAgent) {
    return queryParams.get("accountId") || "";
  } else {
    return params.accountId || "";
  }
};
