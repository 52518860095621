import {FunctionComponent} from "react";
import Box from "@mui/material/Box";
import * as React from "react";
import PermanentMenu from "../perminent-menu/PermanentMenu";
import {Outlet} from "react-router-dom";
import {springMenuItems} from "./menu-items";
import {SpringLogo} from "../../bounce-ui/assets";
import {urls} from "../../routes/urls";

const SpringLayout: FunctionComponent = () => {
  return (
    <Box sx={{display: "flex", height: "100%"}}>
      <PermanentMenu menuItems={springMenuItems} logo={<SpringLogo />} homeURL={urls.spring.url} />
      <Outlet />
    </Box>
  );
};

export default SpringLayout;
