import currency from "currency.js";

export function prettifyCurrency(number: number) {
  return currency(number).divide(100).format();
}

export function prettifyCurrencyRounded(number: number) {
  return currency(number, {precision: 0}).divide(100).format();
}

export function prettifyCurrencyForUndefined(number?: number) {
  return currency(number || 0)
    .divide(100)
    .format();
}

export function prettifyDollarCurrency(number: number) {
  return currency(number).format();
}

export function divide(amount: number, divider: number) {
  return currency(amount).divide(divider).value;
}

export function multiply(amount: number, multiplication: number) {
  return currency(amount).multiply(multiplication).value;
}

export function subtract(amount: number, subtraction: number) {
  return currency(amount).subtract(subtraction).value;
}

export function add(amount: number, addition: number) {
  return currency(amount).add(addition).value;
}
