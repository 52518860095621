import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Collapse, Colors, Container, IconArrowUp, Text} from "../../bounce-ui/consumer";
import * as React from "react";
import InputUtils from "../../helpers/InputUtils";
import List from "@mui/material/List";
import {FC, useState} from "react";
import {map} from "lodash";
import {useNavigate} from "react-router-dom";
import {ICategoryListItem, ICategoryNestListItem, ISublistItem} from "./types";

const listItemButtonStyle = (selected?: boolean) => {
  return {
    borderRadius: "6px",
    gap: "12px",
    paddingLeft: "8px",
    paddingRight: "8px",
    backgroundColor: selected ? Colors.primary._800 : null,
    "&:hover": {
      backgroundColor: Colors.primary._900,
      color: Colors.bw.white,
    },
  };
};

export type CategoryListItemProps = {
  selected?: string;
  setSelected: (x: string) => void;
  item: ICategoryListItem;
};
export const CategoryListItem: FC<CategoryListItemProps> = ({item, selected, setSelected}) => {
  const navigate = useNavigate();
  const isSelected = selected === item.id;
  return (
    <ListItem key={item.title} disablePadding>
      <ListItemButton
        sx={listItemButtonStyle(isSelected)}
        onClick={() => {
          navigate(item.url);
          setSelected(item.id);
        }}
      >
        <ListItemIcon sx={{minWidth: 0}}>{item.icon}</ListItemIcon>
        <Text variant="body3" sx={{color: Colors.bw.white}}>
          {InputUtils.capitalizeString(item.title)}
        </Text>
      </ListItemButton>
    </ListItem>
  );
};

export type CategoryNestListItemProps = {
  item: ICategoryNestListItem;
  selected?: string;
  setSelected: (x: string) => void;
};
export const CategoryNestListItem: FC<CategoryNestListItemProps> = ({item, selected, setSelected}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton onClick={handleClick} sx={{...listItemButtonStyle(), justifyContent: "space-between"}}>
        <Container row>
          <ListItemIcon sx={{minWidth: 0}}>{item.icon}</ListItemIcon>
          <Text variant="body3" sx={{color: Colors.bw.white}}>
            {InputUtils.capitalizeString(item.title)}
          </Text>
        </Container>
        {open ? <IconArrowUp /> : <IconArrowUp style={{transform: "rotate(180deg)"}} />}
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List component="div" disablePadding>
          {map(item.subListItems, (subItem: ISublistItem) => {
            const isSelected = selected === subItem.id;
            return (
              <ListItemButton
                key={subItem.title}
                onClick={() => {
                  navigate(subItem.url);
                  setSelected(subItem.id);
                }}
                sx={{
                  ...listItemButtonStyle(isSelected),
                  paddingLeft: "50px",
                }}
              >
                <Text variant="body1" sx={{color: Colors.bw.white}}>
                  {InputUtils.capitalizeString(subItem.title)}
                </Text>
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
