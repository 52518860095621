import React from "react";
import {IActivityCard} from "./ActivityCard";
import {WebchatData} from "./activity_models";
import {Container, Divider, Chat, Link} from "bounce-ui/backoffice";
import {StyledText} from "./components/StyledText";
import {useAssignAccountId} from "./useAssignAccountId";

export const WebchatItem = ({activity, user}: IActivityCard) => {
  const {event_type, chat, principal, account_id, interaction_id} = activity as WebchatData;
  const {handleAssignAccountId} = useAssignAccountId({user, interactionId: interaction_id});

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Chat chatMessages={chat} principal={principal} />;
      {!account_id && ["WEBCHAT_CHAT", "WEBCHAT_OFFLINE"].includes(event_type) && (
        <Container fullWidth sx={{alignItems: "end"}}>
          <Link onClick={handleAssignAccountId} sx={{cursor: "pointer"}}>
            <StyledText value={"Assign account ID"} bold link />
          </Link>
        </Container>
      )}
    </Container>
  );
};
