import React, {FunctionComponent, useCallback} from "react";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {useLocation} from "react-router-dom";
import {useUpdateEffect} from "react-use";
import {Colors, Container, CustomDialog, ButtonTypes, Text} from "bounce-ui/backoffice";

export type AreYouSureDialogProps = {
  onConfirm: (x: boolean) => void;
  headerTextId?: string;
  contentTextId?: string;
  confirmTextId: string;
  children?: React.ReactNode;
  confirmButtonType?: ButtonTypes;
};

const AreYouSureWithChildrenDialog: FunctionComponent<AreYouSureDialogProps & NiceModalHocProps> = NiceModal.create(
  ({onConfirm, headerTextId, contentTextId, confirmTextId, children}) => {
    const modal = useModal();
    const {pathname} = useLocation();

    const confirmHandler = useCallback((): void => {
      onConfirm(true);
      modal.resolve(true);
      modal.hide();
    }, [onConfirm, modal]);

    const cancelHandler = useCallback((): void => {
      modal.resolve(false);
      modal.hide();
    }, [modal]);

    useUpdateEffect(() => {
      cancelHandler();
    }, [pathname]);

    return (
      <CustomDialog
        title={headerTextId || "Are You Sure?"}
        buttonText={confirmTextId}
        onClose={cancelHandler}
        onClick={confirmHandler}
        secondaryButtonText={"Cancel"}
        onClickSecondary={cancelHandler}
        alignCenter
        isError
      >
        <Container fullWidth>
          {children}
          {contentTextId && (
            <Text
              sx={{
                color: Colors.grey._800,
                fontSize: "14px",
                maxWidth: "400px",
              }}
            >
              {contentTextId}
            </Text>
          )}
        </Container>
      </CustomDialog>
    );
  }
);

export default AreYouSureWithChildrenDialog;
