import {BoxStyleProps} from "@twilio-paste/core/box";

export const containerStyles: BoxStyleProps = {
  border: "none",
  display: "flex",
  height: "3.4rem",
  width: "3.4rem",
  fontSize: "fontSize50",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "borderRadiusCircle",
  color: "colorTextWeakest",
  cursor: "pointer",
  transition: "background-color 0.2s",
  outline: "0px",
  _focusVisible: {
    boxShadow: "shadowFocus",
  },
};
