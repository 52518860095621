// eslint-disable-next-line import/no-anonymous-default-export
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  proceed: "Proceed",
  loginTitle: "Hi {username}",
  hiUsername: "Hi {username},",
  forYourSecurity: "For your security, we need to make sure that it’s really you.",
  enterYourContactInfo: "Enter your contact info",
  wellSendYou: "We’ll send a you a verification code via text or email",
  termsAndConditions: "Terms & Conditions",
  verifyCode: "Verify Code",
  verifyAccount: "Verify Account",
  ok: "OK",
  contactMethodsApproval:
    "By approving, you consent to being contacted by {collectionLabel} via the selected communication channels regarding your account.",
  balance: "Balance",
  accountInformation: "Account Information",
  product: "Product",
  accountNumber: "Account number",
  referenceNumber: "Reference Number",
  referenceNumberLow: "Reference number",
  balanceDue: "Balance Due",
  dispute: "Dispute",
  payment: "Payment",
  paymentPlans: "Payment Plans",
  thisIsNotMyDebt: "This is not my debt.",
  amountIsWrong: "The amount is wrong.",
  other: "Other",
  disputeSuccess: "Dispute filed successfully",
  choosePlan: "Choose Plan",
  installmentSize: "Installment Size",
  Installments: "Installments",
  numberOfInstallments: "Number of Installments",
  oneTime: "One-Time",
  paymentSuccess: "Payment Success",
  calendarReminders: "Calendar reminders",
  checkout: "Checkout",
  creditCard: "Credit",
  debitCard: "Debit",
  achPayment: "ACH Payment",
  ach: "ACH",
  debit: "Debit",
  card: "Card",
  confirmPayment: "Confirm payment",
  accountOwnerName: "Account Owner's Name",
  bankAccountNumber: "Bank Account Number",
  bankRoutingNumber: "Bank Routing Number",
  bankAccountType: "Bank Account Type",
  paymentSummary: "Payment Summary",
  contactSupport: "Contact support",
  paymentTermsAndConditions:
    "By checking this box, I agree to the {termsAndConditions} and authorize Bounce AI to debit my account according to my plan and email me confirmations, reminders, and receipts. I understand I can update my preferences or withdraw this authorization at any time.",
  shortPaymentTermsAndConditions:
    "I accept the {termsAndConditions} and authorize {collectionLabel} to electronically debit my designated account as described above.",
  singlePaymentTermsAndConditions:
    "By checking this box, you confirm that you have read and agree to the {termsAndConditions} and authorize {collectionLabel} to electronically debit your designated account as described above.",
  payments: "Payments",
  starting: "Starting",
  ending: "Ending",
  payFirstPayment: "Pay First Payment",
  manualPaymentExplanation:
    "You have elected not to enroll in automatic payments and so you will be responsible for making each payment before the scheduled due dates.  Payments can either be made electronically using this app or via check or money order by sending them to the following address:",
  unknown: "Unknown",
  resolveYourEntireBalance: "Resolve your entire outstanding balance",
  payCustomAmount: "Pay a custom amount",
  invalidAmount: "Invalid amount",
  enterValidAmount: "Please enter a valid amount",
  retryAreYouSureDialog: "Are you sure you want to try to pay this installment again? This might incur cost",
  retryInstallmentAnyway: "Retry Anyway",
  error: "Error",
  login: "Login",
  contactUs: "Contact Us",
  crud: "CRUD",
  table: "Table",
  details: "Details",
  account: "Account",
  operators: "Operators",
  operatorDetails: "Operator Details",
  roles: "Roles",
  roleDetails: "Role Details",
  data: "Data",
  reports: "Reports",
  reportDetails: "Report Details",
  dashboard: "Dashboard",
  example: "Example",
  exampleDetails: "Example Details",
  exampleNested: "Example Nested",
  exampleNestedDetails: "Example Nested Details",
  email: "Email",
  phoneNumberTitle: "Phone Number",
  password: "Password",
  fullName: "Full Name",
  requiredField: "Required field",
  invalidValue: "Invalid value",
  emailInvalid: "Invalid email",
  phoneInvalid: "Invalid Phone",
  dateInvalid: "Date invalid",
  typeToConfirm: 'Type "{confirm}" to confirm.',
  cancel: "Cancel",
  continue: "Continue",
  lastEdit: "Last Edit",
  of: "of",
  yes: "Yes",
  no: "No",
  notAvailable: "N/A",
  remove: "Remove",
  actions: "Actions",
  preview: "Preview",
  download: "Download",
  result: "Result",
  id: "ID",
  send: "Send",
  close: "Close",
  submit: "Submit",
  open: "Open",
  create: "Create",
  delete: "Delete",
  archive: "Archive",
  share: "Share",
  update: "Update",
  custom: "Custom",
  Custom: "Custom",
  reason: "Reason",
  copyToClipboardSuccess: "Copied to clipboard successfully.",
  loading: "Loading...",
  pageNotFoundError: "Sorry, page not found!",
  pageNotFoundErrorExplanation:
    "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling or contact support.",
  import: "Import",
  search: "Search",
  select: "Select",
  dropOrSelectFile: "Drop or Select file",
  dropFilesHereOrClick: "Drop files here or click",
  next: "Next",
  back: "Back",
  admin: "Admin",
  generate: "Generate",
  invalidFieldUpload: "Cannot upload selected file.",
  invalidFieldUploadTooLarge: "Cannot upload selected file. File size too large.",
  invalidFieldUploadTooSmall: "Cannot upload selected file. File size too small.",
  invalidFieldUploadTooManyFiles: "Cannot upload selected file. Too many files selected.",
  invalidFieldUploadInvalidType: "Cannot upload selected file. Invalid file type.",
  status: "Status",
  description: "Description",
  name: "Name",
  totalAmountPaid: "Total amount paid",
  totalAmount: "Total amount",
  remainingBalance: "Remaining Balance",
  cancellationDate: "Cancellation Date",
  installment: "installment",
  hardshipPlan: "Hardship plan",
  showLess: "Show less",
  showAll: "Show all",
  regarding: "Regarding",
  installmentAmount: "Installment Amount",
  installmentDate: "Installment Date",
  amount: "Amount",
  paymentSchedule: "Payment Schedule",
  paymentMethod: "Payment Method",
  paymentNumber: "Payment No.",
  billingName: "Billing Name",
  updatedAt: "Updated At",
  dueDate: "Due Date",
  dueOn: "due on",
  paidOn: "Paid on {date}",
  paymentInProgress: "Payment in progress",
  scheduledPayment: "Scheduled Payment",
  paid: "Paid",
  late: "Late",
  payNextPayment: "Pay Next Payment",
  payNow: "Pay now",
  resolveNow: "Resolve now",
  updatePayment: "Update payment",
  managePayment: "Manage payment",
  confirmAndContribute: "Confirm and contribute",
  saveAndUpdate: "Save and update",
  updateAndResolve: "Update and resolve",
  resolveLatePayment: "Resolve late payment",
  thisReliefProgramEnds: "This relief program ends in {days} days",
  ourTemporaryRelief: "Our 3-month Temporary Relief Program is here to help.",
  notOnlyWillYouBenefit:
    "Not only will you benefit from smaller, more manageable payments, but you’ll also avoid negative reports on your credit.",
  talkToOneOfOurExpertsToday: "Talk to one of our experts today to see if you’re eligible.",
  saveChanges: "Save changes",
  activateAutoPay: "Activate AutoPay",
  makePayment: "Make a Payment",
  success: "Success!",
  yourOpinionMatters: "Your opinion matters",
  haveAMinuteToSpare: "Have a minute to spare? We’d love if you left a quick review at {link}",
  addPaymentMethod: "Add Payment Method for AutoPay",
  paymentMethodChangedSuccessfully: "Changes to your payment method were saved",
  paymentMethodChangedFailed: "Failed to change your payment method",
  updateInstallmentDateSuccessfully: "Update installment date successfully",
  enumBankAccountTypeUnknown: "Unknown",
  enumBankAccountTypePersonalChecking: "Personal Checking",
  enumBankAccountTypePersonalSavings: "Personal Savings",
  enumBankAccountTypeBusinessChecking: "Business Checking",
  searchPanel: "Search Panel",
  informationPanel: "Information Panel",
  bankruptcy: "Bankruptcy",
  activeDispute: "Active Dispute",
  disputeThisDebt: "Dispute This Debt",
  areYouSureYouWantToVoidThePayment: "Are you sure you want to void this payment?",
  areYouSureYouWantToRefundThePayment: "Are you sure you want to refund this payment?",
  areYouSureAbstractCommunication: "Are you sure you want to proceed?",
  enumPoliceRulesoutsideValidCommunicationTimes: "Communication outside business hours",
  enumPoliceRulesNotABusinessDay: "Communication date not a business day",
  enumPoliceRulesUserCommunicationPreferences: "Communication method deselected",
  enumPoliceRulesAllowToCommunicateWithUser: "User communication frozen",
  enumPoliceRulesNotInValidationPeriod: "User validation not received",
  enumPoliceRulesCheckMaxContacts: "User contacted in the last week",
  enumPoliceRulesCheckMaxContactsAttemptsInAWeek: "Over 7 in 7 communications",
  enumPoliceRulesCheckMaxContactsAttemptsInADay: "Date contains too many communication attempts",
  enumPoliceRulesPhoneNotReassigned: "Phone number invalid",
  enumPoliceRulesCheckPhoneValidity: "Phone number invalid",
  enumPoliceRulesNoEmailToNy: "Not allowed to email residents in New York",
  enumPoliceRulesNotFromUnallowedStates: "Not allowed to communicate with user's location",
  enumPoliceRulesAccountNotPurchased: "ACCOUNT NOT OWNED BY BOUNCE",
  filterByPrincipal: "Filter by principal",
  noResults: "No results",
  deleteReport: "Delete report",
  copyLink: "Copy link to account",
  copiedLinkToAccountSuccessfully: "Copied link to account successfully",

  //Payment plan
  viewDetails: "View details",
  customizeAPlan: "Customize a plan",
  customizeYourPlan: "Customize your plan",
  oneTimePayment: "One-Time Payment",
  oneTimeAmount: "One-Time Amount",
  settleInFull: "Settle in full",
  payOverTime: "Pay over time",
  wantToMake: "I want to make a ",
  partialPayment: "partial payment",
  hardshipProgram: "Hardship program",
  cantAffordTheMinimumPayment: "Can't afford the minimum payment? ",
  exploreMore: "Explore more",
  chatWithAnExpert: "Chat with an expert",
  weUnderstandLifeUncertainties:
    "We understand life's uncertainties and know how unforeseen events like sudden job loss, long-term illness or relationship breakdown can change everything.",
  aHardshipProgramCanHelpYou: "A hardship program can help you:",
  payTheMinimum: "Pay the minimum, affordable amount for the first 3 or 6 months.",
  buildAFollowUpPlan: "Build a follow-up plan that works for you.",
  avoidANegativeListingOnYourCreditReport: "Avoid a negative listing on your credit report.",
  chooseHowOftenToPay: "Choose how often to pay",
  chooseFrequencyAgent: "How often the consumer want to pay?",
  setYourAmount: "Set your {frequency} amount",
  viewPlanSummary: "View plan summary",
  reviewYourPlan: "Review your plan",
  lastInstallment: "Last installment",
  firstInstallments: "First installments",
  remainingInstallments: "Remaining installments",
  totalBalance: "Total balance",
  confirm: "Confirm",
  numberOfPayments: "Number Of Payments",
  paymentAmount: "Payment Amount",
  frequency: "Frequency",
  startDate: "Start Date",
  endDate: "End Date",
  firstPaymentDate: "First payment date",
  paymentDate: "Payment Date",
  monthly: "Monthly",
  biWeekly: "Bi-Weekly",
  weekly: "Weekly",
  firstAndFifteenth: "1st & 15th",
  asLowAs: "as low as",
  proceedToPayment: "Proceed to payment",
  setUpPlan: "Set up plan",
  payItNow: "Pay it now",
  manage: "Manage",
  resolve: "Resolve",
  adjustablePaymentDate: "Payment date",
  bounceAI: "Bounce AI",
  bounceAddress: "2850 Clover Street, Suite 3 ",
  addressCity: "Pittsford, NY 14534",
  planSummary: "Plan Summary",
  paymentInfo: "Payment Info",
  paymentsOf: " payments of ",
  paymentOf: " payment of ",
  lastPaymentOf: "last payment of ",
  paymentOn: "Payment on ",
  PlanOptions: "Plan options",
  presetPlan: "Build plan",
  customPaymentV2: "custom payment",
  congratulations: "Congratulations!",
  paymentSubmitted: "Payment Submitted",
  oneStepCloserToPutting: "You're one step closer to putting this debt behind you",
  yourPaymentWasSuccessfullySubmitted: "Your payment was successfully submitted!",
  debtIsAlmostBehind: "This debt is almost behind you! Processing may take up to 3-5 days.",
  youreOnFire: "You're on fire!",
  oneDownFewMoreToGo: "1 down, few more to go. Let’s put the next debt behind you",
  creditReportConsent:
    "I understand that late or failed payments can result in this payment plan being canceled and your debt ",
  beingReportedToCreditBureaus: "being reported to the credit bureaus.",
  viewPlanDetails: "View plan details",
  resolveNextDebt: "Resolve next debt",
  youCan: "You can ",
  viewYourCurrentPlanDetails: "view your current plan details",
  andResolveTheRestLater: " and resolve the rest later.",
  paymentTooltip: "This sets up automatic payments to make sure they are on time and according to your chosen plan.",
  continueWithAutoPay: "Continue with AutoPay ",
  instead: "instead",
  checkoutV2: "Checkout",
  manualPayment: "Manual payment",
  creditCardInvalid: "Your credit card number is invalid",
  partialAmount: "Partial amount",
  totalRemainingBalance: `(Remaining balance {totalBalance})`,
  totalBalanceValue: `(Total balance {totalBalance})`,
  interestAndFeesWaived: "Interest & Fees waived",
  tip: "Tip: ",
  mostUsersPick: "Most users pick a day they get paid",
  underTheLimit: "(under the limit)",
  exceededTheLimit: "(exceeds the limit)",
  singleInstallment: "Single Installment",
  remainderWillBeAdded: "(A remainder of under 10$ will be added to the plan's last installment)",
  typeToSearch: "Type to search",
  thisAccountHasNoFiles: "This account has no files",
  noActivitiesThisWeek: "No activities this week",
  paymentUnsuccessful: "Payment unsuccessful",
  reasonError: "Reason: {error}",
  youCanTryAgainWithADifferent: "You can try again with a different payment method or ",
  chatWithUs: " chat with us",
  toFindTheRightSolution: "  to find the right solution for you.",
  selectDateOfBirth: "Select date of birth",
  accountBalance: "Account balance",
  nonLinear: "Non linear",
  needMoreOptions: "Need more options? ",
  weCanHelp: "We can help.",
  chooseAnOption: "Choose an option",
  suitsYouBest: " that works best for you",
  preparingYourOptions: "Preparing your options...",
  hardshipIntro: "Hardship Intro",
  hardshipPlans: "Hardship Plans",
  getStartedWithFlexibleRates: "Get started with flexible rates and options to get back on track.",
  startWithSmallerPayments: "Start with smaller payments",
  for3To6MonthsThenMoreLater: ", for 3-6 months, then more later",
  noInterestOrFees: "No interest or fees",
  onAnyAndAllOfferedPlans: ",  on any and all offered plans.",
  avoidANegativeListing: "Avoid a negative listing",
  byUsOnYourCreditReport: " by us on your credit report.",
  whatIfINeedToAdjustItLater: "What if I need to adjust it later?",
  wereJustAPhoneCallOrChatAway:
    "We're just a phone call or chat away. Just let us know how we can help (please provide a 3-business-day notice).",
  youCanAlwaysResolveThisDebt: "You can always resolve this debt with a one time payment at any time.",

  // payment link generation
  generatePaymentLink: "Generate payment link",
  copyPaymentLink: "Copy payment link",
  linkWillBeValidFor: "The link will be valid for the next 72 hours.",
  pleaseNote: "Please note",
  generatingThisLinkWillCreate:
    "Generating this link will create an incomplete payment plan. For the payment plan to become active, the consumer must add payment details using the link.",

  //digest
  verificationDetails: "Verification Details",
  dateOfBirth: "Date of birth",
  address: "Address",
  when: "When",
  summary: "Summary",
  originalCreditor: "Original creditor",
  paymentPlan: "Payment Plan",
  discount: "Discount",
  totalBalanceDue: "Total balance due",
  labelSSN: "last 4 digits of SSN",

  // Account
  accounts: "Accounts",
  yourAccounts: "Your Accounts",
  viewMore: "View more",
  viewLess: "View less",
  from: "From",
  managePlan: "Manage plan",
  resolveNowForAsLittleAs: "Resolve now for as little as ",
  resolveNowFor: "Resolve now for ",
  paidTemporaryPlan: " paid (Temporary plan)",
  totalBalancePaid: "Total balance paid: ",
  atRiskOfaCreditReport: "At risk of a credit report",
  returnedToCreditor: "Returned to creditor",
  pendingPlan: "Pending plan",
  disputeFiled: "Dispute filed",
  fileNewDispute: "File a new dispute",
  closeDispute: "Close Dispute",
  MarkDisputeAsResponded: "Mark Dispute As Responded",
  selectCannedResponseTemplate: "Select canned response template",
  represented: "Represented",
  activePlan: "Active plan",
  unpaid: "Unpaid",
  accountResolved: "Account resolved",
  temporaryPlan: "Temporary plan",
  paymentIssue: "Payment issue",
  paidOutFrom: " paid out of ",
  somethingLooksOff: "Something looks off? ",
  nextDueDate: "Next due date: ",
  lateInstallmentAmount: "Late installment amount: ",
  wasDueOn: "Was due on ",
  negativeEntryOnCreditReport: "A negative entry was made on your credit report",
  onceYourPaymentPlanCompleted:
    "Once your payment plan is completed and account is resolved, we will notify the credit bureaus to remove the listing of this debt that Bounce AI, Inc has reported.",
  creditReportListingIn: "Credit report listing in {days} days",
  resolveDebtToPreventCollectionsListing: "Resolve this debt to prevent a collections listing on your credit report.",
  importantNotice: "Important notice",
  yourDebtIsInDispute: "Your debt is in dispute",
  yourDisputeSuccessfullyFiled:
    "Your dispute was successfully filed and is under investigation. We'll update you soon. If you have any questions or concerns, contact us or call at (888) 482-5060.",

  // Dispute v2
  fileDispute: "File a Dispute",
  labelEmail: "Email",
  confirmEmail: "Please confirm the email address you want to use to communicate about this dispute.",
  enterEmail: "Please enter the email address you want to use to communicate about this dispute.",
  fewThingsToNote: "Few things to note",
  disputeInfo1:
    "If you believe there is a mistake with this debt or the details are incorrect, you may file a dispute and we will investigate the account.",
  disputeInfo2: "If the account details are verified to be correct, debt collection activities may resume.",
  disputeInfo3: "Please provide as much information and documentation as possible to support your claim.",
  proceedWithDispute: "Proceed with dispute",
  wantToStop: "I want to stop and ",
  goBack: "go back",
  yourEmailIsInvalid: "Your Email is invalid",
  reasonForDispute: "Reason for dispute?",
  debtWasPreviouslyPaid: "Debt was previously paid",
  thisDebtHasAlreadyBeenPaid: "This debt has already been paid and I have receipts or bank statements to prove it.",
  incorrectDebtDetails: "Incorrect debt details",
  thereIsAnErrorWithTheAmount:
    "There is an error with the amount or other information about this debt and I have documents to prove it.",
  verificationOfDebt: "Verification of debt",
  iWouldLikeToRequestVerification: "I would like to request verification of this debt",
  identifyTheftOrFraud: "Identity theft or fraud",
  thisAccountWasOpenedFraudulently:
    "This account was opened and/or used fraudulently without my permission and I have filed a FTC fraud report and police report.",
  reviewYourAccountDetails: "Review your account details",
  reviewYourAccountDocuments: "Review your account documents",
  debtOwner: "Debt owner",
  last4digitsSSN: "last 4 digits of SSN",
  principal: "Principal",
  interest: "Interest",
  fees: "Fees",
  asOfYouOwed: "As of {itemization_date}, you owed: {total_balance_on_itemization_date}. Since then you were charged: ",
  interestYouWereCharged: "You were charged this amount in interest: ",
  feesYouWereCharged: "You were charged this amount in fees: ",
  youPaid: "You paid or were credited this amount: ",
  exit: "Exit",
  yourClaimWasSent: "Your claim was sent successfully",
  weWillReview: "We will review all of the details and get back to you within the next few days",
  yourFraudClaimHasBeen: "Your fraud claim has been submitted",
  weLookForwardToHearingFromYou: "We look forward to hearing from you",
  pleaseExplainYourDispute: "Please explain your dispute and attach supporting documentation",
  uploadFiles: "Upload files",
  upload: "Upload",
  dragAndDrop: "Drop files here or click to upload",
  dragAndDropSingleFile: "Drop your file or click to upload",
  afterReviewingTheAccountDetails:
    "After reviewing the account details and documentation that we have on file, do you still want to proceed?",
  multiAccount: "Multi account",

  //Dispute step 4 options
  continueWithTheDispute: "Continue with the dispute",
  solidCaseToShare: "I have a solid case to share",
  chatWithUsOnline: "Chat with us online or give us a call",
  viewMyPaymentOptions: "View my payment options",
  findOtherAvailableResolutions: "Find other available resolutions",
  fileFraudClaim: "File a fraud claim",
  provideFraudDocumentation: "Provide fraud documentation",
  or: "Or",
  home: "Home",

  // Dialogs
  enableAutoPayPartOne: "Have all your payments scheduled and paid automatically instead of manually paying each one.",
  enableAutoPayPartTwo:
    "Enable AutoPay for hassle-free management. It's a simple “set it and forget it” solution you can enjoy at no extra cost that helps you stay on track and avoid late payments that could impact your plan.",
  sms: "sms",
  bySMS: "By SMS",
  byEmail: "By Email",
  byPhoneCall: "By Phone Call",
  creditReportListingDays: "Credit report listing in {days} days",
  preventThisDebt:
    "Prevent this debt from being listed on your credit report with a repayment plan that works for you.",
  thisDebtIsReflectedOnYourCreditReport:
    "This debt is currently reflected on your credit report. View your options and take action now to resolve it and have your credit report updated.",

  // Validation notice
  generalInformation:
    "Bounce AI, Inc. is a debt collector. We are trying to collect a debt that you owe to {originalCreditor}. We will use any information you give us to help collect the debt. You had a {product} from {originalCreditor} with account number {externalAccountId}.",
  asYouOwed: "As of {itemizationDate}, you owed: {totalBalanceOnItemizationDate}. Between {itemizationDate} and today:",
  interestCharged: "You were charged this amount in interest: {totalInterestAccruedSinceItemizationDate}",
  feesCharged: "You were charged this amount in fees: {totalFeesAccruedSinceItemizationDate}",
  youPaidOrCredited: "You paid or were credited this amount toward the debt: -{totalPaidOnAccountSinceItemizationDate}",
  totalAmountOfDebt: "Total amount of the debt now: {totalBalance}",
  howCanYouDispute: "How can you dispute the debt?",
  disputeFirst: "Email, call or visit our convenient app by {lastDateToRespond}, to dispute all or part of the debt.",
  disputeSecond: "If you do not, we will assume that our information is correct.",
  ifYouNotifyBy: "If you notify us by {lastDateToRespond},",
  disputeThird:
    "we must stop collection activity on any account you dispute until we send you information that shows you owe the debt. You may use the form below or contact us without the form. You may use the link below to notify us and include supporting documents. We also accept disputes electronically by email at dispute@finbounce.com or by visiting our app at app.finbounce.com.",
  whatCanYouDo: "What else can you do?",
  whatCanYouDoFirst:
    "Contact us to ask for the name and address of the original creditor, if different from the current creditor.",
  whatCanYouDoSecond:
    "If you contact us by {lastDateToRespond}, we must stop collection until we send you that information. You may use the link below or we also accept such requests electronically by email at compliance@finbounce.com or by visiting our app at app.finbounce.com.",
  originalCreditorInfo: "Original Creditor Info",
  learnMoreOnYourRightsFirst: "Go to www.cfpb.gov/debt-collection to learn more about your rights under federal law.",
  learnMoreOnYourRightsSecond: "have the right to stop or limit how we contact you.",
  learnMoreOnYourRightsThird: "Contact us about your payment options.",
  bounceAiReferenceNumber: "Your Bounce AI reference number is: {accountPublicId}",
  spanishValidation: "Póngase en contacto con nosotros para solicitar una copia de este formulario en español.",
  spanishLink: "Quiero este formulario en español",
  validateAccount: "validate",
  validationRequired: "Validation Required:",
  addresses: "addresses",
  emails: "emails",
  readTheFollowingStatement: "Read the following statement:",
  rePhoneValidation:
    "I am calling today about your {product} that was opened with {originalCreditor}." +
    "\n" +
    "There is an outstanding balance of {totalBalance}. \n" +
    "\n" +
    "I must inform you that you may email, call or visit our convenient app by {lastDateToRespond}, to dispute all or part of the debt. If you do not, we will assume that our information is correct. You may also contact us by this date to ask for the name and address of the original creditor, if different from the current creditor. \n" +
    "\n" +
    "If you notify us by {lastDateToRespond} that you dispute or require further information, we will stop collection activity on your account until we send you the requested information. You may inform us of a dispute or information request over the phone by calling us at 888-482-5060, or we also accept such notices electronically by email at dispute@finbounce.com or by visiting our app at app.finbounce.com.",

  // Holiday campaign
  delayYourUpcomingEntry: "Delay your upcoming entry",
  asPartOfOurPeopleFirstCommitment:
    "As part of our people-first commitment, we want to ensure a smoother holiday season.",
  postponeTheReporting:
    "We’ll postpone the reporting of your debt to the credit bureaus and the entry on your credit report.",
  claimNow: "Claim now",
  happyHolidays: "Happy Holidays",
  yourCreditReportEntry: "Your credit report entry is successfully postponed",
  failedToPostpone: "Failed to postpone the reporting of your debt. Please contact us.",
  alreadyPostponed: "Your credit report entry has already been postponed.",
  paymentPlanIsActiveOnPostpone: "Payment plan is active. No need to postpone credit report entry",

  // Switch accounts
  accountsAll: "Accounts ({numberOfAccounts})",
  thisCustomerHasAccounts: "This customer has {numberOfAccounts} accounts on our system,",
  toViewAllAccounts: " to view all accounts",

  // Backoffice
  pricing: "Pricing",
  washing: "Washing",
  importPricing: "Import Pricing",
  importUsers: "Import Users",
  importPayments: "Import Payments",
  mediaUpload: "Media Upload",
  generatePricing: "Generate Pricing",
  history: "History",
  reportType: "Report Type",
  fileName: "File Name",
  dsc: "DSC",
  batch: "Batch",
  dscPanel: "DSC Panel",
  rexManagement: "REx Management",
  conversations: "Conversations",
  bankruptcies: "Bankruptcies",
  representationCompanies: "Representation companies",
  bulkOperations: "Bulk operations",
  anonymousCommunication: "Anonymous communications",
  areYouSureYouWantToMarkThisEmailAsRead: "Are you sure you want to mark this email as read?",
  markAsRead: "Mark as read",
  assignedCommunicationToAccountSuccessfully: "Assigned communication to account successfully",
  failedToAssignCommunicationToAccount: "Failed to assign communication to account",
  markedCommunicationAsReadSuccessfully: "Marked communication as read successfully",
  failedToMarkCommunicationAsRead: "Failed to mark communication as read",
  backToSearchPanel: "Back to search panel",
  disputeClosedSuccessfully: "Dispute closed successfully",
  disputeMarkedRespondedSuccessfully: "Dispute marked as responded successfully",
  add: "Add",
  companyName: "Company name",
  addedToRepresentationCompaniesSuccessfully: `Added "{name}" to representation companies successfully`,
  failedToAddToRepresentationCompanies: `Failed to add "{name}" to representation companies`,
  yourRequestBeingProceed: "Your request is being proceed",
  theFileIsntReadyYet: "The file isn't ready yet",
  theFileNotFound: "The file was not found",
  messageWillBeSent: "A slack message will be sent to #app-notifications upon completion.",
  fileIdToDownload: "The file id to download is:",
  jobIdToTrack: "The job id to track is:",
  attachFiles: "Attach files",
  phones: "Phones",
  responseIsScheduledSuccessfully: "Response is scheduled or sent successfully",

  //Guest link payment
  logIn: "Log in",
  expiredLinkError: "Sorry, this link has expired",
  expiredLinkErrorExplanation:
    "To continue, you can either log into your account and click" +
    ' on "Switch to Autopay" under the menu, or <link>chat with an expert</link> to get assistance.',
  successPageStatusExplanation: "To view the status of your plan you will need to log in to your account",
  generateDocument: "Generate document",
  attachmentsWarning: "Please note that the original email has attachments - please attach them manually if needed",
  guestPayment: "Guest payment",

  // Spring
  spring: "spring",
};
