import React, {FunctionComponent, useCallback, useEffect} from "react";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {Container, ImgSuccess, Text, CustomDialog} from "../../../../../bounce-ui/consumer";
import {useAmplitude} from "../../../../../contexts";
import {Pages} from "../../../../../bi-events/enum";

export type PaymentSuccessDialogProps = {
  title?: string;
  content?: string;
};

const PaymentSuccessDialog: FunctionComponent<PaymentSuccessDialogProps & NiceModalHocProps> = NiceModal.create(
  ({title = "congratulations", content = "oneStepCloserToPutting"}) => {
    const {pageViewEvent} = useAmplitude();
    const modal = useModal();

    const closeHandler = useCallback((): void => {
      modal.resolve(true);
      modal.hide();
    }, []);

    useEffect(() => {
      pageViewEvent({page: Pages.PAYMENT_SUCCESS});
      const timeoutId = setTimeout(() => {
        closeHandler();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }, []);

    return (
      <CustomDialog title={""}>
        <Container
          fullWidth
          sx={{
            height: "500px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            overflow: "auto",
          }}
        >
          <ImgSuccess />
          <Text translateId={title} size={26} fontWeight={"bold"} />
          <Text translateId={content} size={18} />
        </Container>
      </CustomDialog>
    );
  }
);

export default PaymentSuccessDialog;
