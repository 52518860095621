import React, {useCallback, useRef, useState} from "react";
import {IconButton, MenuItem} from "@mui/material";
import {MoreVertOutlined} from "@mui/icons-material";
import {StyledText} from "./StyledText";
import MenuPopover from "../../../../../../../components/common/MenuPopover";
import {Colors, IconCancelAutoPayRed} from "bounce-ui/backoffice";

interface IDashboardMenuWrapper {
  action: string;
  handleVoidPayment: () => void;
  handleRefundPayment: () => void;
}

const PaymentMenu = ({action, handleVoidPayment, handleRefundPayment}: IDashboardMenuWrapper) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const openHandler = useCallback((): void => {
    setOpen(true);
  }, [setOpen]);

  const closeHandler = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <IconButton ref={anchorRef} onClick={openHandler} sx={{padding: "0px"}}>
        <MoreVertOutlined sx={{color: Colors.primary._800}} />
      </IconButton>
      <MenuPopover open={open} onClose={closeHandler} anchorEl={anchorRef.current} sx={{width: 150}}>
        {action === "void" && (
          <MenuItem key={"void_payment"} onClick={handleVoidPayment} sx={{gap: "5px"}}>
            <IconCancelAutoPayRed style={{marginTop: "5px"}} />
            <StyledText value={"Void payment"} bold />
          </MenuItem>
        )}
        {action === "refund" && (
          <MenuItem key={"refund_payment"} onClick={handleRefundPayment}>
            <IconCancelAutoPayRed />
            <StyledText value={"Refund payment"} bold />
          </MenuItem>
        )}
      </MenuPopover>
    </>
  );
};

export default PaymentMenu;
