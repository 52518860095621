import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {Address, Email, Phone} from "declerations/server";
import {Representation} from "declerations/server/representation_models";
import {RepresentationStatus} from "../../../declerations/server/account_models";

type Variables = {
  userId: string;
  emails?: Partial<Email>[];
  phones?: Partial<Phone>[];
  addresses?: Partial<Address>[];
  representations?: Partial<Representation>[];
  emailsToRemove?: string[];
  phonesToRemove?: string[];
  addressToRemove?: string[];
  representationsToRemove?: string[];
  accountEnrollment?: {id: string; representation_status: RepresentationStatus};
};
type Data = boolean;

export const updateContacts = async (variables: Variables): Promise<Data> => {
  await axiosFlexInstance.patch<string, AxiosResponse<number>>(`/contacts/${variables.userId}`, {
    emails: variables.emails,
    phones: variables.phones,
    addresses: variables.addresses,
    representations: variables.representations,
    account_enrollment: variables.accountEnrollment,
    emails_to_remove: variables.emailsToRemove,
    phones_to_remove: variables.phonesToRemove,
    addresses_to_remove: variables.addressToRemove,
    representations_to_remove: variables.representationsToRemove,
  });
  return true;
};

export const useUpdateContacts = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updateContacts, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user"],
  });
