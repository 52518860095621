import {Theme} from "@mui/material/styles";
import {Components} from "@mui/material/styles/components";
import {Colors} from "../../bounce-ui/colors";

// ----------------------------------------------------------------------

export default function Switch(theme: Theme): Components {
  const isLight = theme.palette.mode === "light";

  return {
    MuiSwitch: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        thumb: {
          boxShadow: theme.customShadows.z1,
        },
        track: {
          opacity: 1,
          backgroundColor: Colors.grey._200,
        },
        switchBase: {
          left: 0,
          right: "auto",
          "&:not(.Mui-checked)": {
            color: isLight ? Colors.grey._300 : Colors.grey._500,
          },
          "&.Mui-checked.Mui-disabled, &.Mui-disabled": {
            color: isLight ? Colors.grey._400 : Colors.grey._600,
          },
          "&.Mui-disabled+.MuiSwitch-track": {
            opacity: 1,
            backgroundColor: `${theme.palette.action.disabledBackground} !important`,
          },
        },
      },
    },
  };
}
