import React, {FunctionComponent, PropsWithChildren, useContext, useEffect, useState} from "react";
import {env} from "../constants/defaultValues";

export interface ActivityEventContextProps {
  isUserInactive: boolean;
  setIsUserInactive: (value: boolean) => void;
  stopUserInactivityTooltip: boolean;
  setStopUserInactivityTooltip: (value: boolean) => void;
  onStopUserInactivityTooltip: () => void;
  isPlansTooltipActive: boolean;
  setIsPlansTooltipActive: (value: boolean) => void;
  enableHardshipPlanTooltip: boolean;
  setEnableHardshipPlanTooltip: (value: boolean) => void;
}

const ActivityEventContext = React.createContext<ActivityEventContextProps>(undefined!);

interface IProps extends PropsWithChildren<any> {}

const ActivityEventProvider: FunctionComponent<IProps> = ({children}) => {
  const [isUserInactive, setIsUserInactive] = useState(false);
  const [stopUserInactivityTooltip, setStopUserInactivityTooltip] = useState(false);
  const [isPlansTooltipActive, setIsPlansTooltipActive] = useState(false);
  const [enableHardshipPlanTooltip, setEnableHardshipPlanTooltip] = useState<boolean>(true);

  const onStopUserInactivityTooltip = () => {
    setIsUserInactive(false);
    setStopUserInactivityTooltip(true);
  };

  useEffect(() => {
    let inactivityTimeout: any;
    if (["production"].includes(env)) {
      const resetInactivityTimeout = () => {
        clearTimeout(inactivityTimeout);
        inactivityTimeout = setTimeout(() => {
          setIsUserInactive(true);
        }, 3000);
      };
      const handleActivity = () => {
        resetInactivityTimeout();
      };
      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("keydown", handleActivity);
      resetInactivityTimeout();
      return () => {
        window.removeEventListener("mousemove", handleActivity);
        window.removeEventListener("keydown", handleActivity);
      };
    }
  }, []);

  return (
    <ActivityEventContext.Provider
      value={{
        isUserInactive,
        setIsUserInactive,
        stopUserInactivityTooltip,
        setStopUserInactivityTooltip,
        onStopUserInactivityTooltip,
        isPlansTooltipActive,
        setIsPlansTooltipActive,
        enableHardshipPlanTooltip,
        setEnableHardshipPlanTooltip,
      }}
    >
      {children}
    </ActivityEventContext.Provider>
  );
};

const useActivityEvent = (): ActivityEventContextProps => {
  const context = useContext(ActivityEventContext);

  if (!context) throw new Error("ActivityEventContext must be used inside ActivityEventProvider");

  return context;
};

export {ActivityEventContext, ActivityEventProvider, useActivityEvent};
