import {axiosFlexInstance} from "../../ServerApiManager";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {AxiosResponse} from "axios";

type Variables = {
  accountId: string;
  paymentPlanId: string;
};
type Data = boolean;

export const cancelPaymentPlanAutoPay = async (variables: Variables): Promise<Data> => {
  await axiosFlexInstance.patch<string, AxiosResponse<number>>(`/cancel_auto_pay/${variables.paymentPlanId}`);
  return true;
};

export const useCancelPaymentPlanAutoPay = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(cancelPaymentPlanAutoPay, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["account", variables.accountId],
  });
