import React, {FunctionComponent, ReactNode, useCallback} from "react";
import {Switch} from "@mui/material";
import {ContactMethodType} from "../../../../declerations/enums";
import ContactDetail from "./ContactDetail";
import {Container} from "../../../../bounce-ui/consumer";

interface ContactMethodProps {
  type: ContactMethodType;
  title: ReactNode;
  value: string;
  checked: boolean;
  onCheckedChange: (type: ContactMethodType, checked: boolean) => void;
  onEditValue: (type: ContactMethodType, value: string) => void;
}

const ContactMethod: FunctionComponent<ContactMethodProps> = ({
  type,
  title,
  value,
  checked,
  onCheckedChange,
  onEditValue,
}: ContactMethodProps) => {
  const checkedChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onCheckedChange(type, checked);
    },
    [type, onCheckedChange]
  );

  return (
    <Container fullWidth row sx={{alignItems: "start"}}>
      <Switch checked={checked} onChange={checkedChangeHandler} />
      <ContactDetail type={type} title={title} value={value} onEditValue={onEditValue} />
    </Container>
  );
};
export default ContactMethod;
