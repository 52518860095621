import React, {FunctionComponent, ReactNode, useCallback} from "react";
import {IconButton, Link} from "@mui/material";
import {Container, IconEditGreen, Text} from "bounce-ui/consumer";

interface ContactDetailProps {
  type: string;
  title: ReactNode;
  value: string;
  isMissing?: boolean;
  onEditValue: (type: any, value: string) => void;
}

const ContactDetail: FunctionComponent<ContactDetailProps> = ({type, title, value, onEditValue, isMissing}) => {
  const editHandler = useCallback(() => {
    onEditValue(type, value);
  }, [type, value, onEditValue]);

  return (
    <Container sx={{gap: "2px", alignItems: "start", mt: "6px"}}>
      <Text fontWeight={"bold"}>{title}</Text>
      {value ? (
        <Container row>
          <Text>{value}</Text>
          <IconButton size="small" onClick={editHandler}>
            <IconEditGreen />
          </IconButton>
        </Container>
      ) : (
        <Link onClick={editHandler}>
          <Text
            translateId={"add"}
            size={14}
            fontWeight={"bold"}
            color={"secondary"}
            sx={{display: "inline", cursor: "pointer"}}
          />
        </Link>
      )}
    </Container>
  );
};

export default ContactDetail;
