import {BoxStyleProps} from "@twilio-paste/core/box";

export const notificationBarContainerStyles: BoxStyleProps = {
  position: "relative",
};

export const notificationBarStyles: BoxStyleProps = {
  position: "absolute",
  width: "100%",
  overflow: "hidden",
  zIndex: "zIndex10",
};
