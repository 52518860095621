import React, {FunctionComponent, useEffect, useState} from "react";
import {Link} from "@mui/material";
import {Container} from "../../../../../../../bounce-ui/backoffice";
import {StyledText} from "./StyledText";
import {downloadUserFiles} from "../../../../../../../apis/requests/files";

interface DisplayDownloadedContentProps {
  file_id: string;
  interaction_type: string;
}

const DisplayDownloadedContent: FunctionComponent<DisplayDownloadedContentProps> = ({file_id, interaction_type}) => {
  const [displayHtml, setDisplayHtml] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    const invokeDownloadFile = async () => {
      const html = await downloadUserFiles({fileId: file_id, htmlView: true, isAgent: true});
      setContent(html?.toString() || "");
    };

    if (displayHtml) {
      invokeDownloadFile();
    }
  }, [displayHtml]);

  const handleOnDisplayClick = () => {
    if (file_id) {
      setDisplayHtml(!displayHtml);
    }
  };

  const handleDownloadFiles = () => {
    if (file_id) {
      downloadUserFiles({fileId: file_id, isAgent: true});
    }
  };

  return (
    <div>
      <Container row>
        <Link onClick={handleOnDisplayClick} sx={{cursor: "pointer"}}>
          <StyledText value={`${displayHtml ? "Hide" : "Show"} ${interaction_type}`} bold link />
        </Link>
        <StyledText value={" | "} bold link />
        <Link onClick={handleDownloadFiles} sx={{cursor: "pointer"}}>
          <StyledText value={"Download"} bold link />
        </Link>
      </Container>
      {displayHtml && (
        <div>
          <div
            dangerouslySetInnerHTML={{__html: content}} // eslint-disable-line react/no-danger
          />
        </div>
      )}
    </div>
  );
};

export default DisplayDownloadedContent;
