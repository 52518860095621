import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosError, AxiosResponse} from "axios";
import {GuestPaymentDetailsResponse} from "../../../declerations/server/payment_plan_models";
import {useEffect, useState} from "react";

type Variables = {
  token: string;
};

type Data = GuestPaymentDetailsResponse;

export const getGuestPaymentDetails = async (variables: Variables): Promise<Data> => {
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(`/payments/guest/${variables.token}`);
  return result.data;
};

export const useGuestPaymentDetails = (variables: Variables) => {
  const [data, setData] = useState<Data | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const result = await getGuestPaymentDetails(variables);
        setData(result);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {first_name: data?.first_name, balance_amount_cents: data?.balance_amount_cents, isLoading, error};
};
