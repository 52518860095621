import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {PaymentDetails} from "../../../declerations/server";
import {IResponseData} from "../../../pages/account/payment/utils";

type Variables = PaymentDetails & {account_id: string; user_id?: string; isAgent?: boolean};

type Data = IResponseData;

export const singlePayment = async (variables: Variables): Promise<Data> => {
  const {account_id, user_id, isAgent, ...data} = variables;
  let result;
  if (isAgent) {
    result = await axiosFlexInstance.post<Data, AxiosResponse<Data>>(`/payments/single/${user_id}/${account_id}`, data);
  } else {
    result = await axiosConsumerAppInstance.post<Data, AxiosResponse<Data>>(`/payments/single/${account_id}`, data);
  }
  return result.data;
};

export const useSinglePayment = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(singlePayment, {
    refetchType: "all",
    ...options,
    prefixKeysToInvalidate: (data, variables) => [["user"], ["account", variables.account_id]],
  });
};
