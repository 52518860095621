import React from "react";
import {IActivityCard} from "./ActivityCard";
import {PaymentPlanData} from "./activity_models";
import NiceModal from "@ebay/nice-modal-react";
import AssignPrincipalDialog from "./components/AssignPrincipalDialog";
import {Container, Divider, Link} from "bounce-ui/backoffice";
import dayjs from "dayjs";
import OneTimeSummary from "../../payment-plan-tab/components/OneTimeSummary";
import InstallmentsSummary from "../../payment-plan-tab/components/InstallmentsSummary";
import {StyledText} from "./components/StyledText";
import {InteractionType} from "./utils";

export const PaymentPlanItem = ({activity, user}: IActivityCard) => {
  const {
    payment_plan_id,
    event_type,
    principal,
    total_amount,
    installment_size,
    number_of_installments,
    discount_fraction,
    start_date,
    end_date,
    installment_frequency,
    last_installment,
    last_4_digits,
    payment_method_type,
    is_hardship_plan,
  } = activity as PaymentPlanData;
  const handleClick = async () => {
    await NiceModal.show(AssignPrincipalDialog, {
      accountId: user?.account.id,
      defaultValue: principal,
      activityType: InteractionType.PAYMENT_PLAN_SELECTED,
      activityId: payment_plan_id,
    });
  };
  const isCustomPlan = installment_frequency === "non_linear";

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start", gap: "5px"}}>
        {installment_frequency === "one_time" ? (
          <Container sx={{width: "300px"}}>
            <OneTimeSummary
              payment_method_type={payment_method_type}
              last_4_digits={last_4_digits}
              installmentSize={Number(installment_size)}
              startDate={dayjs(start_date)}
            />
          </Container>
        ) : (
          <Container sx={{width: "300px"}}>
            <InstallmentsSummary
              payment_method_type={payment_method_type}
              last_4_digits={last_4_digits}
              discount={discount_fraction}
              currentEndDate={end_date}
              lastPayment={last_installment}
              numOfInstallments={number_of_installments}
              totalPlanAmount={total_amount}
              frequency={installment_frequency}
              installmentSize={isCustomPlan ? 0 : Number(installment_size)}
              startDate={dayjs(start_date)}
              isHardship={is_hardship_plan}
            />
          </Container>
        )}
        {event_type === "PAYMENT_PLAN_SELECTED" && (
          <Container fullWidth sx={{alignItems: "end"}}>
            <Link onClick={handleClick} sx={{cursor: "pointer"}}>
              <StyledText value={"Assign principal"} bold link />
            </Link>
          </Container>
        )}
      </Container>
    </Container>
  );
};
