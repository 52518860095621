import React from "react";
import {IActivityCard} from "./ActivityCard";
import {PaymentData} from "./activity_models";
import {Container, Divider, Link} from "bounce-ui/backoffice";
import NiceModal from "@ebay/nice-modal-react";
import AssignPrincipalDialog from "./components/AssignPrincipalDialog";
import {StyledText} from "./components/StyledText";
import {InteractionType} from "./utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {PaymentMethodTypes} from "../../../../../../declerations/enums";
import {useRefundPayment, useVoidPayment} from "../../../../../../apis/requests/backoffice/reversePayments";
import {useUser} from "../../../../../../contexts";
import {BounceRole} from "../../../../../../contexts/UserContext";
import PaymentMenu from "./components/PaymentMenu";
import AreYouSureDialog from "../../../components/dialogs/AreYouSureDialog";
dayjs.extend(utc);
dayjs.extend(timezone);

type ActionDisplay = "void" | "refund";
const getActionDisplay = (startTimeStr: string, autopayType?: PaymentMethodTypes): ActionDisplay | undefined => {
  const tz = "America/New_York";
  const startTime = dayjs.utc(startTimeStr);
  const tzStartTime = startTime.tz(tz);
  const tzNow = dayjs().tz(tz);
  const tzVoidThreshold = dayjs().tz(tz).hour(19).minute(0).second(0).millisecond(0);
  const sameDayAndBelowThreshold = tzStartTime.isSame(tzVoidThreshold, "day") && tzNow.isBefore(tzVoidThreshold);
  const sameDayAndAfterThreshold = tzStartTime.isSame(tzVoidThreshold, "day") && tzNow.isAfter(tzVoidThreshold);
  const cardPaymentAndAfterThreshold =
    autopayType && autopayType === "card" && (sameDayAndAfterThreshold || tzStartTime.isBefore(tzNow, "day"));
  if (sameDayAndBelowThreshold) {
    return "void";
  } else if (cardPaymentAndAfterThreshold) {
    return "refund";
  }
};
export const PaymentItem = ({activity, user}: IActivityCard) => {
  const {
    start_time,
    autopay_type,
    payment_id,
    event_type,
    principal,
    paid_to,
    payment_plan_id,
    external_id,
    original_due_date,
    new_due_date,
  } = activity as PaymentData;
  const paymentPlanLabel = payment_plan_id ? "In payment plan" : "Not in payment plan";
  const action = getActionDisplay(start_time, autopay_type);
  const voidPayment = useVoidPayment();
  const refundPayment = useRefundPayment();
  const {agentRole} = useUser();
  const isSupervisor = agentRole && [BounceRole.ADMIN, BounceRole.SUPERVISOR].includes(agentRole);

  const assignPrincipal = async () => {
    await NiceModal.show(AssignPrincipalDialog, {
      accountId: user?.account.id,
      defaultValue: principal,
      activityType: InteractionType.PAYMENT_SUCCESS,
      activityId: payment_id,
    });
  };

  const openAreYouSureVoidDialog = () => {
    NiceModal.show(AreYouSureDialog, {
      onConfirm: handleVoidPayment,
      contentTextId: "areYouSureYouWantToVoidThePayment",
      confirmTextId: "void",
    });
  };
  const openAreYouSureRefundDialog = () => {
    NiceModal.show(AreYouSureDialog, {
      onConfirm: handleRefundPayment,
      contentTextId: "areYouSureYouWantToRefundThePayment",
      confirmTextId: "refund",
    });
  };
  const handleVoidPayment = async () => {
    if (external_id) {
      voidPayment.mutateAsync({externalPaymentId: external_id});
    }
  };
  const handleRefundPayment = async () => {
    if (external_id) {
      refundPayment.mutateAsync({externalPaymentId: external_id});
    }
  };

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      {event_type === "INSTALLMENT_DUE_DATE_UPDATED" ? (
        <Container fullWidth sx={{alignItems: "start", gap: "5px"}}>
          {original_due_date && <StyledText value={`Previous Date: ${original_due_date}`} />}
          {new_due_date && <StyledText value={`New Date: ${new_due_date}`} />}
        </Container>
      ) : (
        <Container fullWidth sx={{alignItems: "start", gap: "5px"}}>
          {paid_to && <StyledText value={`Paid to: ${paid_to}`} />}
          <StyledText value={`Type: ${paymentPlanLabel}`} />
          {external_id && <StyledText value={`External Payment ID: ${external_id}`} />}
          <Container fullWidth row sx={{justifyContent: "end"}}>
            {event_type === "PAYMENT_RECEIVED" && (
              <Link onClick={assignPrincipal} sx={{cursor: "pointer"}}>
                <StyledText value={"Assign principal"} bold link />
              </Link>
            )}
            {(event_type === "PAYMENT_RECEIVED" || event_type === "PAYMENT_PENDING") && isSupervisor && action && (
              <PaymentMenu
                action={action}
                handleVoidPayment={openAreYouSureVoidDialog}
                handleRefundPayment={openAreYouSureRefundDialog}
              />
            )}
          </Container>
        </Container>
      )}
    </Container>
  );
};
