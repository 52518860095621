import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  installmentId: string;
  dueDate: Date;
  accountId: string;
};
type Data = {data: string; status: number};

export const updateInstallmentDate = async (variables: Variables): Promise<Data> => {
  const result = await axiosConsumerAppInstance.patch<string, AxiosResponse<string>>(
    `installments/${variables.installmentId}`,
    {
      due_date: variables.dueDate,
    }
  );
  return {data: result.data, status: result.status};
};

export const useUpdateInstallmentDate = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  // TODO: ["account", account_id, "dashboard"]
  useBaseMutation(updateInstallmentDate, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["account", variables.accountId],
  });
