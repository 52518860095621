import {alpha, Theme} from "@mui/material/styles";
import {Colors} from "bounce-ui/consumer";

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "10px",
          "&.Mui-selected": {
            backgroundColor: theme.palette.action.selected,
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
            },
          },
          "&:hover": {
            backgroundColor: alpha(Colors.primary._800, theme.palette.action.hoverOpacity),
          },
          "&+.MuiDivider-root": {
            margin: 0,
          },
        },
      },
    },
  };
}
