import {FileType} from "declerations/server/files_models";
import {isEmpty} from "lodash";

export const appendUrlParams = ({
  excludeFiles,
  includeFiles,
  fileIds,
}: {
  excludeFiles?: FileType[];
  includeFiles?: FileType[];
  fileIds?: string[];
}) => {
  const hasIncludedFiles = includeFiles?.length;
  const hasExcludedFiles = excludeFiles?.length;
  let appendedQueryParams = "";
  if (hasIncludedFiles) {
    appendedQueryParams += getIncludeFileQueryParam(includeFiles);
  }
  if (hasExcludedFiles) {
    appendedQueryParams += getExcludeFileQueryParam(excludeFiles);
  }
  if (fileIds) {
    appendedQueryParams += getFileIdsQueryParam(fileIds);
  }
  return isEmpty(appendedQueryParams) ? "" : `?${appendedQueryParams}`;
};

const getExcludeFileQueryParam = (excludeFiles: FileType[]): string => {
  return excludeFiles.map((fileType) => `exclude_files=${fileType}`).join("&");
};

const getIncludeFileQueryParam = (includeFiles: FileType[]): string => {
  return includeFiles.map((fileType) => `include_files=${fileType}`).join("&");
};

const getFileIdsQueryParam = (fileIds: string[]): string => {
  return fileIds.map((fileId) => `file_ids=${fileId}`).join("&");
};
