import React from "react";
import {Container} from "../Container";
import {map, orderBy} from "lodash";
import {ChatMessage} from "./ChatMessage";
import {ChatMessageData} from "../../../pages/backoffice/information-panel/tabs/user-timeline/activities/activity_models";

interface IChat {
  chatMessages: ChatMessageData[];
  principal: string;
}

export const Chat = ({chatMessages, principal}: IChat) => {
  const sortedChatMessages = orderBy(chatMessages, ["timestamp"], ["asc"]);

  return (
    <Container fullWidth sx={{gap: "26px"}}>
      {map(sortedChatMessages, (message, index) => (
        <ChatMessage key={index} chatMessage={message} principal={principal} />
      ))}
    </Container>
  );
};
