import {AppState, EngagementPhase, PreEngagementData} from "../store/definitions";
import {changeEngagementPhase, changeExpandedStatus, updatePreEngagementData} from "../store/actions/genericActions";
import {useDispatch, useSelector} from "react-redux";
import {useGetWebchatAvailableQuery} from "../../../apis/requests/user/getWebchatAvailable";
import NiceModal from "@ebay/nice-modal-react";
import ContactUsDialog from "../../../pages/contact-us/ContactUsDialog";
import {useUser} from "../../../contexts";
import {env} from "../../../constants/defaultValues";

export const useWebchat = () => {
  const dispatch = useDispatch();
  const expanded = useSelector((state: AppState) => state.session.expanded);
  const {loggedIn} = useUser();
  const isLocal = ["local"].includes(env);
  const {data: isWebchatAvailable} = useGetWebchatAvailableQuery({}, {enabled: !isLocal});
  const openChat = ({data}: {data?: Partial<PreEngagementData>}) => {
    if (isWebchatAvailable) {
      if (data) {
        dispatch(updatePreEngagementData(data));
        dispatch(changeEngagementPhase({phase: EngagementPhase.StartChatWithMessage}));
      }
      dispatch(changeExpandedStatus({expanded: !expanded}));
    } else {
      NiceModal.show(ContactUsDialog, {fetchContact: loggedIn});
    }
  };

  return {openChat};
};
