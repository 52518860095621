import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {Content} from "../../../declerations/server";
import {PoliceRule} from "declerations/server/police_models";

type Variables = {
  user_id: string;
  contact?: string;
  date: string;
  content: Content;
};

type Data = {
  result: boolean;
  broken_rules: PoliceRule[];
  pass_rules: string[];
};

export const arbitraryCommunicationPolice = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.post<string, AxiosResponse<Data>>(`/arbitrary-police/${variables.user_id}`, {
    content: variables.content,
    contact: variables.contact,
    date: variables.date,
  });
  return result.data;
};
