import {Container, IContainerProps} from "./Container";
import useIsMobile from "../../helpers/useIsMobile";

export const Page = ({children, className = "Page", sx}: IContainerProps) => {
  const isMobile = useIsMobile();

  return (
    <Container
      className={className}
      fullWidth
      sx={{
        height: "100%",
        padding: isMobile ? "6% 5% 6% 5%" : "5% 8%",
        alignItems: "center",
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};
