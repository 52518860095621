import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  timedInteractionId: string;
  userId: string;
};
type Data = string;

export const deleteFutureCommunication = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.delete<Data, AxiosResponse<Data>>(
    `/timed_interactions/${variables.timedInteractionId}`
  );
  return result.data;
};

export const useDeleteFutureCommunication = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(deleteFutureCommunication, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user", variables.userId, "timeline"],
  });
