import {IEmailForm, ILetterForm, IMessageForm, IPhoneCallForm} from "./useCommunicationForms";
import dayjs from "dayjs";
import {LetterTemplateKey} from "../../../../../declerations/server";
import NiceModal from "@ebay/nice-modal-react";
import AreYouSureWithChildrenDialog from "./AreYouSureWithChildrenDialog";
import {ArbitraryPoliceRules} from "./ArbitraryPoliceRules";
import ConfirmCommunicationDialog from "./ConfirmCommunicationDialog";

const clearPhoneCallForm = (setMessage: (form: IPhoneCallForm) => void, defaultPhone: string) => {
  setMessage({
    phone: defaultPhone,
    date: dayjs(),
    body: "",
  });
};

const getPhoneCallDefaultValues = (phoneCallForms: IPhoneCallForm, defaultPhone: string) => {
  return {
    phone: phoneCallForms.phone || defaultPhone,
    date: phoneCallForms.date || dayjs(),
    body: phoneCallForms.body || "",
  };
};

const clearLetterForm = (
  setLetter: (form: ILetterForm) => void,
  defaultAddress: string,
  templateKey: LetterTemplateKey
) => {
  setLetter({
    address: defaultAddress,
    description: "",
    files: [],
    templateKey,
  });
};

const getLetterDefaultValues = (
  letterForms: ILetterForm,
  defaultAddress: string,
  defaultTemplateKeyType: LetterTemplateKey
) => {
  return {
    address: letterForms.address || defaultAddress,
    description: letterForms.description,
    files: letterForms.files,
    templateKey: letterForms.templateKey || defaultTemplateKeyType,
  };
};

const clearEmailForm = (setEmail: (form: IEmailForm) => void, defaultEmail: string, subject: string) => {
  setEmail({
    email: defaultEmail,
    date: dayjs(),
    message: "",
    subject,
    files: [],
  });
};

const getEmailDefaultValues = (emailForms: IEmailForm, defaultEmail: string, subject: string) => {
  return {
    email: emailForms?.email || defaultEmail,
    date: emailForms?.date || dayjs(),
    message: emailForms?.message || "",
    subject: emailForms?.subject || subject,
    files: emailForms?.files,
  };
};

const clearMessageForm = (setMessage: (form: IMessageForm) => void, defaultPhone: string) => {
  setMessage({
    phone: defaultPhone,
    date: dayjs(),
    message: "",
  });
};

const getMessageDefaultValues = (messageForms: IMessageForm, defaultPhone: string) => {
  return {
    phone: messageForms.phone || defaultPhone,
    date: messageForms.date || dayjs(),
    message: messageForms.message || "",
  };
};

const openConfirmCommunicationDialog = (policeBrokenRules: string[], onSubmit: () => void) => {
  if (policeBrokenRules.length > 0) {
    NiceModal.show(AreYouSureWithChildrenDialog, {
      onConfirm: onSubmit,
      contentTextId: "Are you sure you want to proceed?",
      confirmTextId: "Communicate anyway",
      children: <ArbitraryPoliceRules policeBrokenRules={policeBrokenRules} />,
    });
  } else {
    NiceModal.show(ConfirmCommunicationDialog, {
      onConfirm: onSubmit,
      contentTextId: "Are you sure you want to proceed?",
      confirmTextId: "Communicate anyway",
    });
  }
};

export {
  clearPhoneCallForm,
  getPhoneCallDefaultValues,
  clearLetterForm,
  getLetterDefaultValues,
  clearEmailForm,
  getEmailDefaultValues,
  clearMessageForm,
  getMessageDefaultValues,
  openConfirmCommunicationDialog,
};
