import {BoxStyleProps} from "@twilio-paste/core/box";

export const buttonStyles: BoxStyleProps = {
  display: "flex",
  alignItems: "center",
  minWidth: "170px",
};

export const progressStyles: BoxStyleProps = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "space60",
  alignItems: "flex-start",
};
