import React, {useCallback} from "react";
import IconMenuItem from "../../../../../components/common/IconMenuItem";
import NiceModal from "@ebay/nice-modal-react";
import {IconAutoPay, Text} from "bounce-ui/consumer";
import {EnableAutoPayDialog, ChangePaymentMethod} from "../../dialogs";
import {useSnackbar} from "notistack";
import {useAmplitude} from "../../../../../contexts";
import {Pages} from "../../../../../bi-events/enum";

interface ISwitchToAutoPayItem {
  accountId: string;
  activePaymentPlanId: string;
  closeHandler: () => void;
}

const SwitchToAutoPayItem = ({accountId, activePaymentPlanId, closeHandler}: ISwitchToAutoPayItem) => {
  const {pageClickEvent} = useAmplitude();
  const {enqueueSnackbar} = useSnackbar();

  const autoPayCheckedHandler = useCallback(async (): Promise<void> => {
    pageClickEvent({page: Pages.MENU, cta: "switch_autopay"});
    closeHandler();
    const enable = await NiceModal.show<boolean>(EnableAutoPayDialog, {});
    if (enable) {
      try {
        const paymentSuccess = await NiceModal.show<boolean>(ChangePaymentMethod, {
          accountId,
          title: "addPaymentMethod",
          activePaymentPlanId,
        });
        if (paymentSuccess) {
          enqueueSnackbar(<Text size={14}>Auto pay activated successfully</Text>, {variant: "success"});
        }
      } catch (e) {
        enqueueSnackbar(<Text size={14}>Failed to activate autopay</Text>, {variant: "error"});
      }
    }
  }, [accountId]);

  return <IconMenuItem label="Switch to AutoPay" icon={<IconAutoPay />} onClick={autoPayCheckedHandler} />;
};

export default SwitchToAutoPayItem;
