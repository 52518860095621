export const Colors: Colors = {
  primary: {
    _100: "#ebf4f0",
    _200: "#cdddd5",
    _300: "#b0c9bd",
    _400: "#93b6a5",
    _500: "#76a28d",
    _600: "#3d7b5c",
    _700: "#206744",
    _800: "#03542c",
    _900: "#024524",
    _1000: "#01341b",
  },
  secondary: {
    _100: "#f7f4ff",
    _200: "#ede3ff",
    _300: "#e3d2ff",
    _400: "#d9c1ff",
    _500: "#cfadff",
    _600: "#b699e6",
    _700: "#9d85cc",
    _800: "#836fb3",
    _900: "#5e429e",
    _1000: "#493087",
  },
  tertiary: {
    _100: "#FDFBF8",
    _200: "#F5F2EC",
    _300: "#EBE9E4",
    _400: "#D4D1CD",
    _500: "#B7B3AB",
    _600: "#938C81",
    _700: "#706658",
    _800: "#4C402E",
    _900: "#281A04",
    _1000: "#170F02",
  },
  red: {
    _100: "#fdeded",
    _200: "#fdd9d9",
    _300: "#f6b6b6",
    _400: "#f1a2a2",
    _500: "#eb8989",
    _600: "#e46c6c",
    _700: "#e05757",
    _800: "#d53731",
    _900: "#a30000",
    _1000: "#800000",
  },
  orange: {
    _100: "#fff4e5",
    _200: "#fde3cb",
    _300: "#fed7ab",
    _400: "#feca8b",
    _500: "#febe6b",
    _600: "#ffb24c",
    _700: "#f79009",
    _800: "#dc6803",
    _900: "#b54708",
    _1000: "#93370d",
  },
  green: {
    _100: "#edf7ed",
    _200: "#d5e6d2",
    _300: "#bdd7bb",
    _400: "#a5c8a4",
    _500: "#8dba8d",
    _600: "#74ac75",
    _700: "#5a9f5c",
    _800: "#448e47",
    _900: "#2e7d32",
    _1000: "#1b5e20",
  },
  bw: {
    white: "#ffffff",
    black: "#000000",
  },
  grey: {
    _100: "#f2f2f2",
    _200: "#e5e5e5",
    _300: "#cccccc",
    _400: "#b2b2b2",
    _500: "#999999",
    _600: "#7f7f7f",
    _700: "#666666",
    _800: "#4c4c4c",
    _900: "#333333",
    _1000: "#191919",
  },
  neon: {
    _100: "#e7fcf2",
    _200: "#cff9e4",
    _300: "#b7f6d7",
    _400: "#9ff3c9",
    _500: "#6eedaf",
    _600: "#56eaa1",
    _700: "#2ee58b",
    _800: "#1bda7b",
    _900: "#0dcb6d",
    _1000: "#0a9e55",
  },
  pastel: {
    coral: "#FF9999",
    lightCoral: "#FFB3B3",
    pink: "#FFCCE5",
    lightPurple: "#E0CEF8",
    purple: "#B3B3FF",
    blue: "#A3D9FF",
    mint: "#C3F2E0",
    green: "#E6F2B3",
    yellow: "#FFF3CC",
    orange: "#FFD9B3",
  },
};
