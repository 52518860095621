import React from "react";
import {SxProps} from "@mui/system";
import {Mark} from "@mui/base";
import {SliderStyled} from "./style";

interface ISliderProps {
  marks: Mark[];
  selectedValue: number | number[] | undefined;
  onChange: (event: Event, value: number | number[], activeThumb: number) => void;
  className?: string;
  sx?: SxProps;
}

export const Slider = ({marks, className = "Slider", selectedValue, onChange, sx}: ISliderProps) => {
  if (!marks) return null;
  return (
    <SliderStyled
      className={className}
      track={false}
      value={selectedValue}
      onChange={onChange}
      step={null}
      marks={marks}
      min={0}
      max={marks?.length - 1 || 1}
      issinglemark={marks?.length === 1 ? "true" : ""}
      sx={{...sx}}
    />
  );
};
