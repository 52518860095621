import React from "react";
import {Container, IconChatIn, IconChatOut, Text} from "bounce-ui/consumer";
import dayjs from "dayjs";
import {TIMESTAMP_FORMAT} from "../../../pages/backoffice/information-panel/components/dialogs/common";
import {
  CommunicateBy,
  getNameFromEmail,
} from "../../../pages/backoffice/information-panel/tabs/user-timeline/activities/utils";
import {ChatMessageData} from "../../../pages/backoffice/information-panel/tabs/user-timeline/activities/activity_models";

interface IChatMessage {
  chatMessage: ChatMessageData;
  principal?: string;
}

const getAuthorName = (isAgent: boolean, authorName: string, principal?: string) => {
  if (!isAgent && !authorName) return CommunicateBy.SYSTEM;
  if (isAgent) {
    return principal ? getNameFromEmail(principal) : CommunicateBy.AGENT;
  } else {
    return authorName ?? CommunicateBy.USER;
  }
};

export const ChatMessage = ({chatMessage, principal}: IChatMessage) => {
  const {body, isAgent, timestamp, authorName} = chatMessage;
  const author = getAuthorName(isAgent, authorName, principal);
  const icon = isAgent || author === CommunicateBy.SYSTEM ? <IconChatOut /> : <IconChatIn />;
  return (
    <Container fullWidth row sx={{gap: "16px", justifyContent: "space-between", alignItems: "start"}}>
      <Container row sx={{alignItems: "start"}}>
        {icon}
        <Container sx={{alignItems: "start"}}>
          <Text size={11} fontWeight={"medium"} sx={{textTransform: "capitalize"}}>
            {author}
          </Text>
          <Text size={14} fontWeight={"bold"}>
            {`"${body}"`}
          </Text>
        </Container>
      </Container>
      <Text size={11} fontWeight={"medium"} sx={{whiteSpace: "nowrap"}}>
        {dayjs(timestamp).format(TIMESTAMP_FORMAT)}
      </Text>
    </Container>
  );
};
