import React, {useCallback} from "react";
import IconMenuItem from "../../../../../components/common/IconMenuItem";
import NiceModal from "@ebay/nice-modal-react";
import {IconSwitch, Text} from "bounce-ui/consumer";
import {ChangePaymentMethod} from "../../dialogs";
import {useSnackbar} from "notistack";
import {useAmplitude} from "../../../../../contexts";
import {Pages} from "../../../../../bi-events/enum";

interface IPaymentMethodItem {
  accountId: string;
  activePaymentPlanId: string;
  closeHandler: () => void;
}

const PaymentMethodItem = ({accountId, activePaymentPlanId, closeHandler}: IPaymentMethodItem) => {
  const {pageClickEvent} = useAmplitude();
  const {enqueueSnackbar} = useSnackbar();

  const changePaymentMethodHandler = useCallback(async (): Promise<void> => {
    pageClickEvent({page: Pages.MENU, cta: "payment_method"});
    closeHandler();
    const paymentSuccess = await NiceModal.show<boolean>(ChangePaymentMethod, {
      accountId: accountId,
      title: "managePayment",
      activePaymentPlanId,
    });
    if (paymentSuccess) {
      enqueueSnackbar(<Text size={14}>Changes to your payment method were saved</Text>, {variant: "success"});
    }
  }, [closeHandler, accountId]);

  return <IconMenuItem label="Payment Method" icon={<IconSwitch />} onClick={changePaymentMethodHandler} />;
};

export default PaymentMethodItem;
