import axios from "axios";
import {consumerAppBaseUrl, flexBaseUrl, springBaseUrl} from "../constants/defaultValues";
import {FunctionComponent, useEffect} from "react";
import {QueryClient} from "@tanstack/react-query";
import {useUser} from "../contexts";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      retry: false,
    },
  },
});

export const axiosConsumerAppInstance = axios.create({
  baseURL: consumerAppBaseUrl,
});
export const axiosSpringInstance = axios.create({
  baseURL: springBaseUrl,
});
export const axiosFlexInstance = axios.create({
  baseURL: flexBaseUrl,
});

interface ServerApiManagerProps {}

const ServerApiManager: FunctionComponent<ServerApiManagerProps> = () => {
  const {token} = useUser();

  useEffect(() => {
    if (token) {
      axiosConsumerAppInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axiosFlexInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axiosSpringInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axiosConsumerAppInstance.defaults.headers.common["Authorization"];
      delete axiosFlexInstance.defaults.headers.common["Authorization"];
      delete axiosSpringInstance.defaults.headers.common["Authorization"];
    }
  }, [token]);

  return null;
};

export default ServerApiManager;
