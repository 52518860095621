import React, {ReactNode} from "react";
import {Container, Card} from "../../../../../bounce-ui/consumer";
interface IPaymentFormWrapper {
  isDashboardDialog: boolean;
  children: ReactNode;
}
const PaymentFormWrapper = ({isDashboardDialog, children}: IPaymentFormWrapper) => {
  return (
    <>
      {isDashboardDialog ? (
        <Container fullWidth sx={{gap: "20px"}}>
          {children}
        </Container>
      ) : (
        <Card fullWidth sx={{gap: "20px"}}>
          {children}
        </Card>
      )}
    </>
  );
};
export default PaymentFormWrapper;
