import React from "react";
import {Button, Container, IconDocument, Text} from "../index";
import useIsMobile from "../../../helpers/useIsMobile";
import {useUser} from "../../../contexts";

interface IFilePreview extends IFilesPreview {
  file: File;
}
interface IFilesPreview {
  files: File[];
  onChange: (event: any) => void;
  isDialog?: boolean;
}
const getFileRowWidth = (isMobile: boolean, isAgent: boolean, isDialog: boolean) => {
  if (isMobile && isDialog) return "180px";
  if ((isMobile && !isDialog) || (isAgent && isDialog)) return "200px";
  return "320px";
};
const FilePreview = ({file, files, onChange, isDialog}: IFilePreview) => {
  const isMobile = useIsMobile();
  const {isAgent} = useUser();
  const handleRemove = (fileName: string) => {
    const filteredFiles = files.filter((file) => file.name !== fileName);
    onChange(filteredFiles);
  };

  return (
    <Container row sx={{justifyContent: "space-between"}}>
      <Container row width={getFileRowWidth(isMobile, isAgent, isDialog || false)}>
        <IconDocument />
        <Text
          size={14}
          fontWeight={"medium"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {file.name}
        </Text>
      </Container>
      <Button buttonType={"outlined-secondary"} size={"small"} onClick={() => handleRemove(file.name)}>
        Remove
      </Button>
    </Container>
  );
};

export const FilesPreview = ({files, onChange, isDialog}: IFilesPreview) => {
  return (
    <Container sx={{alignItems: "start", gap: "5px"}}>
      {files.map((file: File, key: number) => {
        return <FilePreview key={key} file={file} files={files} onChange={onChange} isDialog={isDialog} />;
      })}
    </Container>
  );
};
