import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import PaymentFailedDialog from "./components/PaymentFailedDialog";

export interface IResponseData {
  success: boolean;
  error_message: string | null;
}

interface PaymentContextProps {
  buttonTouched: boolean;
  setButtonTouched: (value: boolean) => void;
  isLoading?: boolean;
  showOneCentLabel?: boolean;
  showLargeSplitLabel?: boolean;
  disableButton?: boolean;
  disableACHAccountOwnerPrePopulation?: boolean;
}
export const PaymentContext = React.createContext<PaymentContextProps>(undefined!);

const openPaymentFailedDialog = (error: string) => {
  NiceModal.show(PaymentFailedDialog, {error});
};

export const formatInput = (inputValue: any) => {
  const inputValueWithoutDashes = inputValue.replace(/-/g, "");
  const formattedValue = inputValueWithoutDashes.replace(/(.{4})/g, "$1-");
  return formattedValue.replace(/-$/, "");
};

const mapFrequencyToTextId: any = {
  weekly: "weekly",
  bi_weekly: "biWeekly",
  first_and_fifteenth: "firstAndFifteenth",
  monthly: "monthly",
};

const getPlanType = (isSinglePayment: boolean, isHardship: boolean) => {
  if (isSinglePayment) {
    return "one_time_payment";
  }
  if (isHardship) {
    return "hardship_plan";
  }
  return "payment_plan";
};

export {openPaymentFailedDialog, mapFrequencyToTextId, getPlanType};
