import {EnumInfo, EnumInfoMap} from "declerations/internal";

export type PoliceRule =
  | "OUTSIDE_VALID_COMMUNICATION_TIMES"
  | "NOT_A_BUSINESS_DAY"
  | "USER_COMMUNICATION_PREFERENCES"
  | "ALLOW_TO_COMMUNICATE_WITH_USER"
  | "NOT_IN_VALIDATION_PERIOD"
  | "CHECK_MAX_CONTACTS"
  | "CHECK_MAX_CONTACTS_ATTEMPTS_IN_A_WEEK"
  | "CHECK_MAX_CONTACTS_ATTEMPTS_IN_A_DAY"
  | "PHONE_NOT_REASSIGNED"
  | "CHECK_PHONE_VALIDITY"
  | "NO_EMAIL_TO_NY"
  | "NOT_FROM_UNALLOWED_STATES"
  | "ACCOUNT_NOT_PURCHASED";

export const policeRuleTypeInfoMap: EnumInfoMap<PoliceRule> = new Map<PoliceRule, EnumInfo>([
  ["OUTSIDE_VALID_COMMUNICATION_TIMES", {labelKey: "enumPoliceRulesoutsideValidCommunicationTimes"}],
  ["NOT_A_BUSINESS_DAY", {labelKey: "enumPoliceRulesNotABusinessDay"}],
  ["USER_COMMUNICATION_PREFERENCES", {labelKey: "enumPoliceRulesUserCommunicationPreferences"}],
  ["ALLOW_TO_COMMUNICATE_WITH_USER", {labelKey: "enumPoliceRulesAllowToCommunicateWithUser"}],
  ["NOT_IN_VALIDATION_PERIOD", {labelKey: "enumPoliceRulesNotInValidationPeriod"}],
  ["CHECK_MAX_CONTACTS", {labelKey: "enumPoliceRulesCheckMaxContacts"}],
  ["CHECK_MAX_CONTACTS_ATTEMPTS_IN_A_WEEK", {labelKey: "enumPoliceRulesCheckMaxContactsAttemptsInAWeek"}],
  ["CHECK_MAX_CONTACTS_ATTEMPTS_IN_A_DAY", {labelKey: "enumPoliceRulesCheckMaxContactsAttemptsInADay"}],
  ["PHONE_NOT_REASSIGNED", {labelKey: "enumPoliceRulesPhoneNotReassigned"}],
  ["CHECK_PHONE_VALIDITY", {labelKey: "enumPoliceRulesCheckPhoneValidity"}],
  ["NO_EMAIL_TO_NY", {labelKey: "enumPoliceRulesNoEmailToNy"}],
  ["NOT_FROM_UNALLOWED_STATES", {labelKey: "enumPoliceRulesNotFromUnallowedStates"}],
  ["ACCOUNT_NOT_PURCHASED", {labelKey: "enumPoliceRulesAccountNotPurchased"}],
]);
