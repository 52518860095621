import {defaultLocale} from "../constants/defaultValues";
import locales from "../lang";

export const getNavigatorLanguage = (): string => {
  const navigator: any = window.navigator;
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || defaultLocale;
  }
};

export const getLocale = (): string => {
  let localStorageLanguage = localStorage.getItem("currentLanguage");
  if (localStorageLanguage && locales[localStorageLanguage]) {
    return localStorageLanguage;
  }

  let navigatorLanguage = getNavigatorLanguage();
  if (navigatorLanguage && navigatorLanguage.length > 2) {
    navigatorLanguage = navigatorLanguage.substring(0, 2);
  }
  if (navigatorLanguage === "iw") {
    navigatorLanguage = "he";
  }
  if (navigatorLanguage) {
    navigatorLanguage = navigatorLanguage.toLowerCase();
    if (locales[navigatorLanguage]) {
      localStorage.setItem("currentLanguage", navigatorLanguage);
      return navigatorLanguage;
    }
  }

  return defaultLocale;
};
