// ----------------------------------------------------------------------

declare module "@mui/system/createTheme/shape" {
  interface Shape {
    borderRadiusXs: number | string;
    borderRadiusSm: number | string;
    borderRadiusMd: number | string;
    borderRadiusLr: number | string;
  }
}

const shape = {
  borderRadiusXs: 4,
  borderRadiusSm: 8,
  borderRadiusMd: 12,
  borderRadiusLr: 16,
};
export default shape;
