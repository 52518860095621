import {FunctionComponent, ReactNode} from "react";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {Container, Text} from "bounce-ui/consumer";

interface IconMenuItemProps {
  label: string;
  icon?: ReactNode;
  noneCapitalize?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const IconMenuItem: FunctionComponent<IconMenuItemProps> = ({
  label,
  icon,
  noneCapitalize = true,
  disabled,
  onClick,
}) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled} divider={true} sx={{width: "100%"}}>
      <Container row>
        {icon}
        <Text fontWeight={500} sx={{...(!noneCapitalize && {textTransform: "capitalize"})}}>
          {label}
        </Text>
      </Container>
    </MenuItem>
  );
};
export default IconMenuItem;
