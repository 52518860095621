import {UserDashboardInstallment} from "../../../../declerations/server";
import {InstallmentStatus, PaymentMethodTypes} from "../../../../declerations/enums";
import {find, isEmpty} from "lodash";
import dayjs, {Dayjs} from "dayjs";
import {Colors, IconCheck, IconFailed, IconProgress} from "bounce-ui/consumer";

export type PaymentMethodType = "current" | "change" | "remove";

export type PaymentActionType = "update" | "resolve";

export interface IHighlightedInstallment extends UserDashboardInstallment {
  index: number;
}

export interface IInstallmentToPresent {
  minIndex: number;
  maxIndex: number;
}

interface ImapMethodToValue {
  currentMethodType: PaymentMethodTypes | undefined;
  lastDigitsCard: string | undefined;
  showRemoveOption: boolean;
}

const REVEAL_ALL_UNDER = 10;
const MIDDLE_INSTALLMENT_INDEX = 2;
const MAX_INSTALLMENT_INDEX = 4;

const findNextInstallmentIndex = (installments: IHighlightedInstallment[], statuses: InstallmentStatus[]): number => {
  return installments.findIndex((installment) => {
    return statuses.includes(installment.installment_status);
  });
};

const findAllInstallmentIndexes = (
  installments: IHighlightedInstallment[],
  statuses: InstallmentStatus[]
): number[] => {
  return installments.reduce<number[]>((acc, installment, index) => {
    if (statuses.includes(installment.installment_status)) {
      acc.push(index);
    }
    return acc;
  }, []);
};

const findNextUnpaidAfterDate = (
  currDueDate: Dayjs,
  installments: IHighlightedInstallment[]
): IHighlightedInstallment | undefined => {
  return find(
    installments,
    (installment) => installment.installment_status === "unpaid" && dayjs(installment.due_date).isAfter(currDueDate)
  );
};

const findNextPayment = (installments: IHighlightedInstallment[]): IHighlightedInstallment | undefined => {
  const lateInstallments = installments.filter((installment) =>
    ["late", "error"].includes(installment.installment_status)
  );
  if (lateInstallments.length > 1) return;
  const latestLateInstallment = lateInstallments[0];
  if (latestLateInstallment) {
    return findNextUnpaidAfterDate(dayjs(latestLateInstallment.due_date), installments);
  }
  const nextUnpaidInstallment = installments.find((installment) => {
    return installment.installment_status === "unpaid";
  });
  if (nextUnpaidInstallment) {
    return findNextUnpaidAfterDate(dayjs(nextUnpaidInstallment.due_date), installments);
  }
};

const findCurrentUnpaid = (installments: IHighlightedInstallment[]): string => {
  const lateInstallments = installments.filter((installment) =>
    ["late", "error"].includes(installment.installment_status)
  );
  if (lateInstallments.length > 0) return "";
  const installment = installments.find((installment) => installment.installment_status === "unpaid");
  return installment ? installment.id : "";
};

const getInstallmentToPresent = (
  revealAll: boolean,
  installments: IHighlightedInstallment[]
): IInstallmentToPresent => {
  const installmentLength = installments.length - 1;
  const showAfterMiddle = MAX_INSTALLMENT_INDEX - MIDDLE_INSTALLMENT_INDEX;
  if (revealAll || installmentLength < REVEAL_ALL_UNDER) {
    return {minIndex: 0, maxIndex: installmentLength};
  } else {
    const nextUnpaidIndex = findNextInstallmentIndex(installments, ["unpaid"]);
    if (nextUnpaidIndex < 0) return {minIndex: -1, maxIndex: -1};
    if (nextUnpaidIndex < MIDDLE_INSTALLMENT_INDEX) {
      return {minIndex: 0, maxIndex: MAX_INSTALLMENT_INDEX};
    }
    if (nextUnpaidIndex >= installmentLength - showAfterMiddle) {
      return {minIndex: installmentLength - MAX_INSTALLMENT_INDEX, maxIndex: installmentLength};
    }
    return {
      minIndex: nextUnpaidIndex - MIDDLE_INSTALLMENT_INDEX,
      maxIndex: nextUnpaidIndex + showAfterMiddle,
    };
  }
};

const getHighlightedInstallments = (installments: IHighlightedInstallment[]): number[] => {
  const highlightedInstallments: number[] = [];
  const nextLateIndexes = findAllInstallmentIndexes(installments, ["late", "error"]);
  if (!isEmpty(nextLateIndexes)) {
    nextLateIndexes.forEach((nextLateIndex) => {
      highlightedInstallments.push(nextLateIndex + 1);
    });
  } else {
    const nextUnpaidIndex = findNextInstallmentIndex(installments, ["unpaid"]);
    if (nextUnpaidIndex > 0) {
      highlightedInstallments.push(nextUnpaidIndex);
    }
    if (nextUnpaidIndex >= 0) {
      highlightedInstallments.push(nextUnpaidIndex + 1);
    }
  }
  return highlightedInstallments;
};

const getPaymentCardInstallments = (
  installments: IHighlightedInstallment[],
  action: PaymentActionType
): IHighlightedInstallment[] => {
  const highlightedInstallments: IHighlightedInstallment[] = [];
  if (action === "resolve") {
    const nextLateIndexes = findAllInstallmentIndexes(installments, ["late", "error"]);
    if (!isEmpty(nextLateIndexes)) {
      nextLateIndexes.forEach((nextLateIndex) => {
        highlightedInstallments.push(installments[nextLateIndex]);
      });
    }
  } else if (action === "update") {
    const nextUnpaidIndex = findNextInstallmentIndex(installments, ["unpaid"]);
    if (nextUnpaidIndex >= 0) {
      highlightedInstallments.push(installments[nextUnpaidIndex]);
    }
  }
  return highlightedInstallments;
};

const mapStatusToIcon: Record<
  string,
  {Icon: any; actionText: string | null; label: string | null; labelColor: string | null}
> = {
  unpaid: {Icon: null, actionText: "update", label: "(Upcoming)", labelColor: Colors.grey._500},
  paid: {Icon: IconCheck, actionText: null, label: null, labelColor: null},
  late: {Icon: IconFailed, actionText: "resolve", label: "Failed", labelColor: Colors.red._900},
  error: {Icon: IconFailed, actionText: "resolve", label: "Failed", labelColor: Colors.red._900},
  pending: {Icon: IconProgress, actionText: null, label: null, labelColor: null},
};

const mapMethodToValue = ({
  currentMethodType,
  lastDigitsCard,
  showRemoveOption = true,
}: ImapMethodToValue): Record<string, string> => {
  const currentMethodTypeString = currentMethodType === "ach" ? "ACH" : "Debit card";
  return {
    current: `${currentMethodTypeString} (${lastDigitsCard})`,
    change: "Change payment method",
    ...(showRemoveOption && {remove: "Remove payment method"}),
  };
};

const getDayFromDate = (fullDate: Dayjs): Date => {
  const year = fullDate.toDate().getFullYear();
  const month = String(fullDate.toDate().getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(fullDate.toDate().getDate()).padStart(2, "0");
  return new Date(`${year}-${month}-${day}`);
};

export {
  findNextPayment,
  findAllInstallmentIndexes,
  findCurrentUnpaid,
  getInstallmentToPresent,
  getHighlightedInstallments,
  getPaymentCardInstallments,
  mapStatusToIcon,
  mapMethodToValue,
  getDayFromDate,
};
