import {ReactNode} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useUser} from "../../contexts";
import {urls} from "routes/urls";
import {useGetAccount} from "../../apis/requests/user/getAccount";
import {useAccountId} from "../../contexts/useAccountId";

type AuthGuardProps = {
  children: ReactNode;
};

const AuthGuard = ({children}: AuthGuardProps) => {
  const {loggedIn, isAgent} = useUser();
  const {pathname, search} = useLocation();
  const queryParams = new URLSearchParams(search);
  const userId = queryParams.get("id");
  const accountId = useAccountId();
  const {data: account} = useGetAccount({accountId}, {enabled: !!(loggedIn && !isAgent && accountId)});
  const inCreditorRecalled = account?.status === "creditor_recalled";

  if (search && !loggedIn && userId) {
    return <Navigate to={urls.login.url + search} state={{origin: pathname + search}} />;
  } else if (!loggedIn) {
    return <Navigate to={urls.login.url} state={{origin: pathname}} />;
  }
  if (inCreditorRecalled) {
    return <Navigate to={urls.accounts.url + search} state={{origin: pathname + search}} />;
  }
  return <>{children}</>;
};

export default AuthGuard;
