import {axiosSpringInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {RepresentationCompany} from "../../../declerations/server/representation_models";

type Variables = {};

type Data = RepresentationCompany[];
export const getRepresentationCompanies = async (variables: Variables): Promise<Data> => {
  const result = await axiosSpringInstance.get<Data, AxiosResponse<Data>>(`/representation-companies`, {});
  return result.data;
};

export const useGetRepresentationCompanies = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(["representation-companies"], getRepresentationCompanies, variables, options);
};
