import React from "react";
import {IActivityCard} from "./ActivityCard";
import {LetterData} from "./activity_models";
import {Container, Divider} from "bounce-ui/backoffice";
import DisplayDownloadedContent from "./components/DisplayDownloadedContent";
import {getSuffixStatusLabel} from "./utils";
import {extractUTCDateFromString} from "../../../../../../helpers/formatDate";
import {StyledText} from "./components/StyledText";

export const LetterItem = ({activity}: IActivityCard) => {
  const {letter_file_id, outcome, outcome_time, recipient} = activity as LetterData;

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start"}}>
        {recipient && <StyledText value={`To: ${recipient}`} />}
        {outcome && outcome_time && (
          <StyledText
            value={`Status: ${getSuffixStatusLabel("LETTER_", outcome)} (${extractUTCDateFromString(outcome_time)})`}
          />
        )}
        {letter_file_id && (
          <Container row sx={{alignItems: "start"}}>
            <StyledText value={`Content: `} />
            <DisplayDownloadedContent interaction_type={"Letter"} file_id={letter_file_id} />
          </Container>
        )}
      </Container>
    </Container>
  );
};
