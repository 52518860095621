import React, {FunctionComponent, useCallback} from "react";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {useLocation} from "react-router-dom";
import {useUpdateEffect} from "react-use";
import {NoticeDialog, ButtonTypes} from "bounce-ui/backoffice";

export type AreYouSureDialogProps = {
  onConfirm: (x: boolean) => void;
  headerTextId?: string;
  contentTextId?: string;
  confirmTextId: string;
  confirmButtonType?: ButtonTypes;
};

const AreYouSureDialog: FunctionComponent<AreYouSureDialogProps & NiceModalHocProps> = NiceModal.create(
  ({onConfirm, headerTextId, contentTextId, confirmTextId}) => {
    const modal = useModal();
    const {pathname} = useLocation();

    const confirmHandler = useCallback((): void => {
      onConfirm(true);
      modal.resolve(true);
      modal.hide();
    }, [onConfirm, modal]);

    const cancelHandler = useCallback((): void => {
      modal.resolve(false);
      modal.hide();
    }, [modal]);

    useUpdateEffect(() => {
      cancelHandler();
    }, [pathname]);

    return (
      <NoticeDialog
        title={headerTextId || "Are You Sure?"}
        buttonText={confirmTextId}
        onClose={cancelHandler}
        onClick={confirmHandler}
        secondaryButtonText={"Cancel"}
        text={contentTextId}
        onClickSecondary={cancelHandler}
        isError
      />
    );
  }
);

export default AreYouSureDialog;
